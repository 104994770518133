import ACTION_CONSTANTS from "src/actions/actionConstants";

const initialState = {
  isMenuOpen: false,
  menuItems: []
};

const menu = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.TOGGLE_MENU:
      return { ...state, isMenuOpen: action.payload };
    case ACTION_CONSTANTS.MENU_SET_ITEMS:
      return { ...state, menuItems: action.payload }; 
    default:
      return state;
  }
};

export default menu;
