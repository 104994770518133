// Trước khi submit to server clean up references.
import _get from "lodash/get";
import {Utils} from 'formiojs';
import { getSubmissions, loadFormio, saveCustomSubmission } from "src/apiManager/services/FormServices";
import ActionServices from "src/apiManager/services/actionServices";
import { BASE_CONTEXT, TEMPLATE_FILE } from "src/config-global";
import {
    createUrlSubmission,
    createUrlSubmissionCreate,
    createUrlSubmissionEdit,
    createUrlSubmissionList,
    createUrlSubmissionPreview,
    createUrlSubmissionSheet
} from "src/utils/meta";
import { ref } from "yup";
import { CUSTOM_SUBMISSION_ENABLE, CUSTOM_SUBMISSION_URL } from "src/constants";
import { IFormio, IFormsflow, ISubmission } from "src/types/form";
import { saveSubmission } from "@formio/react";
import { IContext } from "src/types/common";
import { fetchFormById } from "src/apiManager/services/bpmFormServices";

export const getCustomButtons = (form: IFormio) => { 
    let buttons = [];  
    const components = Utils.flattenComponents(form?.components, false);
    for(var field in components) {
        const comp = components[field];
        if (comp.type == 'button' && comp.action == 'custom') {
            buttons.push(comp);
        }
    };
    return buttons;
}
// Moi reference field luu duoi dang {form: ${formId}, _id: ${submissionId}}
export const cleanupSubmissionData = (submissionData: Record<string, any>) => { 
    let result: Record<string, any> = {};
    try {
        for (let field in submissionData) {
            let fieldValue = submissionData[field];
            if (fieldValue == null || fieldValue == undefined) continue;
            if (fieldValue.form && fieldValue._id) {
                result[field] = { form: fieldValue.form, _id: fieldValue._id };
            } else if (Array.isArray(fieldValue)) {
                const lightValues = fieldValue.map((value) => {
                    return cleanupSubmissionData(value);
                })
                result[field] = lightValues;
            } else {
                result[field] = fieldValue;
            }
        }
    } catch (e) {
        console.error(e);
    }
    return result;
}
export const handleSubmit = (submission: ISubmission, form: IFormio, callback? : (err: any, data: any) => void) => { 
    const submissionPayload = { ...submission, data: cleanupSubmissionData(submission.data) };
    let components = Utils.flattenComponents(form.components, false);
    let extraParams = _get(components['submit'], "properties");
    const formId = form._id;
    if (extraParams?.customAction) {
      submissionPayload.extraParams = extraParams;
      saveCustomSubmission(
        submissionPayload,
        formId,
        callback,
        extraParams.customAction
      );
    } else {
      if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
        saveCustomSubmission(
          submissionPayload,
          formId,
          callback
        );
      } else {
        dispatch(
          saveSubmission(
            "submission",
            submissionPayload,
            formId,
            callback as (() => void)
          )
        );
      }
    }
}
export const actionHandler = async (formAction: ISubmission, context: IContext) => {
    let {
        properties, form, refForm, refFormId, refSubmission, refSubmissionId,
        submission, submissionId, submissionIds, actionId
    } = context;
    let formOpen = _get(formAction, "data.formOpen");
    let openMethod = _get(formAction, "data.openMethod");
    let apiCall = _get(formAction, "data.apiCall");
     let uiAction = _get(formAction, "data.uiAction");
    const formId = form?._id;
    const openForm = () => { 
        if (openMethod == 'modalDialog') {
            openModalDialog(formOpen, context);
        } else {
            let params = {
                refForm: refForm?._id,
                refSubmission: refSubmission?._id || refSubmissionId,
                submissionId,
                actionId
            };
            handleOpenForm(formAction, openMethod, { ...context, params });  
        }
    }
    if (apiCall && formId) {
        let payload: Record<string, any> = {
            openFormId: formOpen.formId || formOpen,
        };
        if (refSubmission && (refFormId || refForm)) {
            payload.refForm = refFormId || refForm?._id;
            payload.refSubmission = refSubmission;
        }
        if (properties) {
            payload = { ...properties, ...payload };
        }
        const callback = (err: any, data: any) => {
            if (formOpen) {
                openForm();
            } else if (typeof context.postApiCall == 'function') {
                //Show toast message
                context.postApiCall(err, data);
            }
        };
        if (apiCall == "formAction") {
            if (Array.isArray(submissionIds)) {
                payload.submissionIds = submissionIds;
            }
            ActionServices.callFormApi(formId, apiCall, payload, callback);
        } else if (submissionId) {
            ActionServices.callSubmissionApi(submissionId, formId, apiCall, payload, callback);
        }
    } else if (uiAction) { 
        if (context.handleUiAction) {
            context.handleUiAction(uiAction, submission);
        }
    } else if (formOpen) {
        openForm();
    }
}
const openModalDialog = async (formOpen: IFormsflow | string, context: IContext) => { 
    //load modalForm
    let formId;
    if ((<IFormsflow>formOpen).formId) {
        formId = (<IFormsflow>formOpen).formId;
    } else {
        formId = formOpen;
    }
    if (context.handleOpenDialog) {
        context.handleOpenDialog(formId);
    }
    // let formActions = loadFormActions(formId);
    // let form = loadFormio(formId);
    // Promise.all([form, formActions]).then(([form, formActions]) => { 
    //     context.handleOpenDialog(form, formActions.data);
    // });
}
export const handleOpenForm = async (formAction: ISubmission, openMethod: string, context: IContext) => { 
    const formOpen = _get(formAction, "data.formOpen");
    const destFormAction = _get(formAction, "data.formAction");
    //Reference field dung de filter opening form's submission
    const reference = _get(formAction, "data.reference");
    const refResource = _get(reference, "data.resource");
    let formId = formOpen.formId || formOpen;
    if (openMethod == 'openTab') {
        
    } else if (openMethod == 'openWindow') {

    } else {
        //openMethod == 'navigation'
        let params = [];
        for (let key in context?.params) {
            let value = context?.params[key];
            if (value != null && value != undefined) {
                params.push(`${key}=${encodeURIComponent(value)}`);    
            }
        }
    //     const xlsxTemplate = _get(action, "data.xlsxTemplate");
    //   if (xlsxTemplate) {
    //     let submissionIds = selectedSubmissions.map((submission) => submission._id);
    //     let url = createUrlSubmissionXlsxBinding({
    //       submissionIds,
    //       templateFormId: xlsxTemplate.form,
    //       templateSubmissionId: xlsxTemplate._id,
    //       fieldName: "templateFile",
    //       formId: form._id
    //     });
    //     navigate(url);
    //   }
        
        let openOptions = {
            tenantKey: context.tenantKey,
            submissionId: context.submissionId,
            submissionIds: context.submissionIds,
            refFormId: context.refFormId,
            refSubmissionId: context.refSubmissionId,
            actionId: context.actionId,
            formId,
            params
        }
        let url, openMethod = _get(formAction, "data.openMethod");
        switch (destFormAction) {
            case "create":
                url = createUrlSubmissionCreate(openOptions);
                break;
            case "list":
                url = createUrlSubmissionList(openOptions);
                break;
            case "sheet":
                url = createUrlSubmissionSheet(openOptions);
                break;
            case "view":
                let submissions;
                if (refResource == context.refFormId && context.refSubmission?._id) {
                    //Try load submission
                    let query = {
                        [`data.${reference.key}._id`]: context.refSubmission._id 
                    };
                    submissions = await getSubmissions(openOptions.formId, {query});
                } 
                let refSubmissionId = _get(submissions, "0._id");
                if (refSubmissionId) {
                    openOptions.submissionId = refSubmissionId;
                    url = createUrlSubmission(openOptions);
                } else {
                    url = createUrlSubmissionCreate(openOptions);
                }
                break;
            case "edit":
                url = createUrlSubmissionEdit(openOptions);
                break;
            case "preview":
                let previewTemplate = _get(formAction, "data.previewTemplate");
                let formId = _get(formAction, "data.formOpen.formId");
                let templateFormId = previewTemplate?.form;
                if (!templateFormId) {
                    let actionForm = await loadFormio(formAction.form);
                    let previewTplComp = Utils.getComponent(actionForm.components, "previewTemplate", true);
                    templateFormId = _get(previewTplComp, "data.resource");
                }
                let options = previewTemplate ? {
                    templateFormId,
                    templateSubmissionId: previewTemplate._id,
                    fieldName: TEMPLATE_FILE,
                    ...openOptions,
                    formId
                } : {
                        templateFormId,
                        fieldName: TEMPLATE_FILE,
                        ...openOptions,
                        formId
                    }
                url = createUrlSubmissionPreview(options);
                break;
        }
        // if (url && query.length > 0) {
        //     if (url.contains('?')) {
        //         url = `${url}&${query.join('&')}`
        //     } else {
        //         url = `${url}?${query.join('&')}`
        //     }
        // }

        if (url && context.navigate) {
            context.navigate(url);
        }
    } 
}

function dispatch(arg0: any) {
    throw new Error("Function not implemented.");
}
