import { createContext } from 'react';
// types
export interface IForm {
    formName: string,
    formId: string,
    _id: string
}

export type FormContextType = {
    forms: IForm[];
    isLoading: boolean;
    // saveForm: (form: IForm) => void;
    // updateForm: (id: number) => void;
};

// ----------------------------------------------------------------------

export const FormsContext = createContext<FormContextType> ({forms: [], isLoading: true});
