import { lazy } from "react";
import {createRoot} from 'react-dom/client';
import {Formio as Formiojs, Utils} from 'formiojs';
import * as XLSX from 'xlsx';
import { getComponentDefaultColumn, submissions } from "@formio/react";
// import { default as components } from "./components";
import { setFormData } from "src/redux/slices/buffers";
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isFunction from 'lodash/isFunction';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import { 
    getCustomSubmissions, 
    getSubmissions,
    uploadSubmissions, 
    postCustomSubmission, 
    deleteCustomSubmission, 
    fetchFormList,
    loadFormio,
    queryFormio
  } from "src/apiManager/services/FormServices";
import {
    MULTITENANCY_ENABLED,
    CUSTOM_SUBMISSION_URL,
    CUSTOM_SUBMISSION_ENABLE,
  } from "src/constants";
import API from "src/apiManager/endpoints";
import HelperServices from "src/services/helperServices";
import { FORM_ACCESS } from "src/config-global";
import { fetchBPMFormList } from "src/apiManager/services/bpmFormServices";
import { fetchForms } from "src/pages/admin/helper";

const EVENT_OPEN="open";
// export const renderHtmlContent = function(form, component, dispatch) {
//     const attr = component.attrs.filter((item) => item.attr == 'id')[0];
//     const container = document.getElementById(attr?.value);
//     if (container) {
//         const root = createRoot(container);
//         const Component = components[component.properties.component];
//         root.render(<Component 
//             form={form} 
//             component={component} 
//             dispatch={dispatch}
//             {...component.properties}
//         />);
//     }
// }
export const getTableColumns = (form) => {
    const ignoreFields = {};
    const columns = [];
    FormioUtils.eachComponent(form.components, (component) => {
        if (!component.tableView) {
            if (component.input && component.key) {
                ignoreFields[component.key] = true;
            }
            //Ignore all child component accept components with custom tableView is set to true
            FormioUtils.eachComponent(component.components, (component) => {
                let customTableView = component.properties?.tableView;
                if (component.input && customTableView != 'true' && component.key) {
                    ignoreFields[component.key] = true;
                }
            });
        }
    });
    FormioUtils.eachComponent(form.components, (component) => {
      let customTableView = component.properties?.tableView;
      if (component.input && component.tableView && customTableView != 'false' && component.key && ignoreFields[component.key] == undefined) {
        columns.push(getComponentDefaultColumn(component));
      }
    });

    // columns.push({
    //   key: 'operations',
    //   title: 'Operations',
    // });

    setColumnsWidth(columns);

    return columns;
};

export const parseXlsx = (base64Files) => {
    const prefix = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    if (Array.isArray(base64Files) && base64Files.length > 0) {
      const base64Url = base64Files[0].url;
      if (base64Url) {
        const content = base64Url.startsWith(prefix) ? base64Url.substring(prefix.length) : base64Url;
        var binaryString = atob(content);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        // return bytes.buffer;
        //Remove 
        // const buffer = Buffer.from(content, 'base64');
        const data = XLSX.read(bytes.buffer, {});
        return data;
      }
    }
} 
/*
 *
 * https://docs.sheetjs.com/docs/api/utilities/
 * https://docs.sheetjs.com/docs/csf/cell
 * Export excel data with template
 */
export const exportXlsx = (data) => {
    console.log(data);
    const filePrefix = "";
    let headerSheet = null;
    let detailSheets = [];
    let template = data?.template?.data;
    if (template?.xlsxData) {
        //Create headerSheet
        const headerTemplate = template.xlsxData.Sheets[template.sheet];
        headerSheet = XLSX.utils.json_to_sheet([]);
        for (let key in headerTemplate) {
            if (key.startsWith("!")) {
                headerSheet[key] = headerTemplate[key];
            } else {
                const cell = headerTemplate[key];
                XLSX.utils.sheet_add_aoa(headerSheet, [[cell.w || cell.v]], { origin: key});
            }
        }
        // Set cols width
        if (!headerTemplate["!cols"] && template?.headerColumnWidths) {
            let headerColumnsWidths = _parseWidths(template.headerColumnWidths);
            headerSheet["!cols"] = headerColumnsWidths;
        }
        //Create detailSheet
        const detailTemplate = template.xlsxData.Sheets[template.detailSheet];
        if (!detailTemplate["!cols"] && template?.detailColumnWidths) {
        }
        // if (detailColumnsWidths) {
        //     headerSheet["!cols"] = detailColumnsWidths;
        // }
        // detailSheets.push(XLSX.utils.json_to_sheet(detailTemplate));
    }
    
    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    // Workbook contains one or more worksheets
    if (headerSheet) {
        XLSX.utils.book_append_sheet(wb, headerSheet, "Header");
    }
    for (let i = 0; i < detailSheets.headerSheet; i++) {
        XLSX.utils.book_append_sheet(wb, detailSheets[i], "Detail" + (i + 1));
    }
    if (headerSheet || detailSheets.length > 0) {
        const currentDate = new Date();
        const fileName = filePrefix + '-' + currentDate.getTime() + '.xlsx';
        XLSX.writeFile(wb, fileName);
    } else {

    }
}
const _parseWidths = (widthStrings) => { 
    const parseWidth = (width) => {
        let ind = 0;
        let char = width.charAt(ind);
        while ((char >= 'a' && char <= 'z') || (char >= 'A' && char <= 'Z')) {
            ind++;
            char = width.charAt(ind);
        }
        try {
            let ch = parseFloat(width.substring(ind));
            return { wch: ch };
        } catch (e) {
            console.error(e);
        }
        return { wch: 2 };
    }
    let widths = [];
    if (widthStrings) {
        widths = widthStrings.split(",").map(parseWidth);
    }
    return widths;
}
/*
 * Export grid in the form
 * Currently support single grid only
 *
 */
const _exportGrid = (editGrid, submissions, filePrefix) => {
    let columns = [];
    Utils.eachComponent(editGrid.components, (component) => {
        //let customTableView = component.properties?.tableView;
        if (component.input && component.key) {
          columns.push(getComponentDefaultColumn(component));
        }
    });
    //Create the sheet
    const headers = columns.map(col => col.key.split('.')[1]);
    headers.unshift("No");
    const options = columns.map(col => ({wch: (col.width || 2) * 10}));
    options.unshift({wch: 6});
   
    const sheetData = submissions.map((submission, ind) => {
      const rowData = {
        ["No"]: ind + 1,
      };
      let subWrapper = {data:submission};
      columns.forEach((col, ind) => {
        let value = _isFunction(col.value)
          ? col.value(subWrapper)
          : _get(subWrapper, col.key, '');
        value = value?.content || "";  
        if (typeof value == 'string' && value.startsWith("<span>")) {
            value = value.substring(6, value.length - 7);
        }  
        rowData[col.key] = value;  
      });
      return rowData;
    });
    const sheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(sheet, [headers]);
    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(sheet, sheetData, { origin: 'A2', skipHeader: true });
    // var sheet = XLSX.utils.json_to_sheet(sheetData, {
    //   origin: "A2",skipHeader: true
    // });
    sheet["!cols"] = options;
    // Make first row bold
    for(let i = 0; i < options.length; i++) {
      const cell = sheet[XLSX.utils.encode_cell({r: 0, c: i})];
      // Create new style if cell doesnt have a style yet
      if(!cell.s) {cell.s = {};}
      if(!cell.s.font) {cell.s.font = {};}
      // Set bold
      cell.s.font.bold = true;
    }
    
    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();

    // // Add bold format
    // var bold = workbook.add_format({'bold': True});
    // add Worksheet to Workbook
    // Workbook contains one or more worksheets
    XLSX.utils.book_append_sheet(wb, sheet, filePrefix || "Sheet"); // sheetAName is name of Worksheet
    // export Excel file
    const currentDate = new Date();
    const fileName = (filePrefix || "Sheet") + '-' + currentDate.getTime() + '.xlsx';
    XLSX.writeFile(wb, fileName);
}
export const exportGrid = (components, data, filePrefix) => {
    let editGrid = null;
    let submissions = [];
    for (let field in components) {
        let comp = components[field];
        if (comp.type == 'editgrid') {
            editGrid = comp;
            submissions = data[field];
            break;
        }
    }
    if (editGrid) {
        _exportGrid(editGrid, submissions, filePrefix);    
    }
}

export const onInitialized= (form, dispatch) => {
    for(let ind in form.components) {
        let component = form.components[ind];
        if (component.type = 'htmlelement' && component.tag == 'div' && component.properties?.component) {
            renderHtmlContent(form, component, dispatch);
        }
    }
    
}
export const onFormChange = (form, event, dispatch) => {
    if (event.changed) {
        let {component, value} = event.changed;
        //Set form submission
        //dispatch(setFormData(form._id, value));
        //console.log(component, value);

    }
}
export const eventHandler = (event, context) => {
    if(event?.type && event.type.startsWith(EVENT_OPEN)) {
        const action = event.type.substring(EVENT_OPEN.length);
        if (action) {
            context.navigate(action.toLowerCase());

        }
    }
}

const getAllFormsflow = async () => { 
    return fetchFormList({
        pageNo: 1,
        limit: 1000,
        sortBy: "formName",
        sortOrder: "asc",
        formType: "form,resource"
    });
}
export const loadFormioByName = async (formName) => { 
    let formsflow = findFormsflowByName(formName);
    if (formsflow) {
        return loadFormio(formsflow.formId);
    }
}

// export const loadSubmissionsByFormName = async (formName, params = {}) => { 
//     let formId;
//     for (let ind = 0; ind < allFormsflow.length; ind++) {
//         if (allFormsflow[ind].formName == formName) {
//             formId = allFormsflow[ind].formId;
//             break;
//         }
//     }
//     if (formId) {
//         return getSubmissions(formId, params);
//     }
// }
export const accumulateAccesses = (formId, roles, formAccesses) => { 
    let result = {};
    let access = formAccesses[formId];
    if (Array.isArray(access)) {
        result = access.reduce((accumulator, item) => { 
            for (let ind = 0; ind < roles.length; ind++) {
                if (item[roles[ind]]) {
                    accumulator[item.action] = true;
                    break;
                }
            }
            return accumulator;
        }, {});
    }
    return result;
}

const getInputComponents = async (formId) => {
    let components = [];
    if (formId) {
        let form = await loadFormio(formId);
        Utils.eachComponent(form?.components, (component) => {
            if (component.input && component.inputType != 'hidden'
                && component.type != 'button' && component.key) {
                components.push(component);
            }
        });
    }
    return components;    
}
//Get custom action name (from button.properties.action)
const getCustomActions = async (formId) => { 
    let actionNames = [];  
    if (formId) {
        let form = await loadFormio(formId);
        Utils.eachComponent(form?.components, (comp) => {
            if (comp.type == 'button' && comp.action == 'custom') {
                actionNames.push(comp.properties.action);
            }
        });
    }
    return actionNames;
}

const calculateValue = (_, utils, util, user, moment, instance, self, token, config, component, row, rowIndex, data, iconClass, t, submission, form, options, value) => { 
    // Load submission
    const formId = form._id;
    const callback = (err, submissions) => { 
        instance.dataValue = data[component.key] = submissions.map(item => item.data);
         if (typeof instance.rebuild == 'function') {
            instance.rebuild();
        } else
        if (typeof instance.redraw == 'function') {
            instance.redraw();
        } else
        if (typeof instance.render == 'function') {
            instance.render();
        }
    }
    if (component.redrawOn) {
        let trigger = data[component.redrawOn];
        if (trigger._id && trigger._id != instance.triggerId) {
            instance.triggerId = trigger._id;
            if (formId) {
                let params = { query: {} };
                for (let key in component.properties) { 
                    let value = instance.interpolate(component.properties[key]);
                    params.query[key] = value;
                };
                if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) { 
                    return getCustomSubmissions(formId, params, callback);
                } else {
                    return getSubmissions(formId, params, callback);
                }
            }
        }
    }
};
export const fetchSubmissions = (form, params, callback) => {
    const formId = form._id;
    if (formId) {
        if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) { 
            return getCustomSubmissions(formId, params, callback);
        } else {
            return getSubmissions(formId, params, callback);
        }
    }
}

export const getLatestSubmission = (instance, component, form) => {
    if (form._id) {
        let formId = form._id;
        const params = {
            sort: "-created",
            limit: 1
        }
        const callback = (err, data) => {
            if (Array.isArray(data) && data.length > 0) {
                const value = _get(data[0], component.properties.latestField);
                instance.setValue(value);
            }
        };
        if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) { 
            getCustomSubmissions(formId, params, callback);
        } else {
            getSubmissions(formId, params, callback);
        }
    }
}
export const getLatestSubmissions = async (instance, component, form, data, options) => {
    if (form._id && options.filterField) {
        let formId = form._id;
        let inputPhone = _get(data, options.filterField);
        if (inputPhone) {
            const params = {
                sort: "-created",
                query: { [`data.${options.filterField}`]: inputPhone },
                limit: 1
            }
            const callback = (err, data) => {
                // if (Array.isArray(data) && data.length > 0) {
                //     console.log(data);
                //     console.log(component);
                //     instance.setItems(data);
                // }
            };
            if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) { 
                return getCustomSubmissions(formId, params, callback);
            } else {
                return getSubmissions(formId, params, callback);
            }
        }
    }
}
/*
* use in EditGrid for load detail list
*/
const fetchSubmissionList = (_, utils, util, user, moment, instance, self, token, config, component, row, rowIndex, data, iconClass, t, submission, form, options, value) => { 
    // Load submission
    const formId = _get(component, "properties.formId", form._id);
    const limit = _get(component, "properties.limit", 100);
    const refField = _get(component, "properties.refField", 100);
    const callback = (err, submissions) => { 
        instance.dataValue = data[component.key] = submissions.map(item => item.data);
        if (typeof instance.rebuild == 'function') {
            instance.rebuild();
        } else
        if (typeof instance.redraw == 'function') {
            instance.redraw();
        } else
        if (typeof instance.render == 'function') {
            instance.render();
        }
    }
    if (component.redrawOn) {
        let trigger = data[component.redrawOn];
        if (trigger._id && trigger._id != instance.triggerId) {
            instance.triggerId = trigger._id;
        }
    }
    if (formId) {
        let params = { limit, query: {} };
        for (let key in component.properties) { 
            if (key == 'formId') continue;
            let value;
            if (key == 'refField') {
                value = `data.${refField}._id`;
            } else {
                value = instance.interpolate(component.properties[key]);
            }
            if (value) {
                params.query[key] = value;
            }
        };
        let makeQuery = true;
        if (refField) {
            if (submission?._id) {
                params.query['refId'] = submission?._id;
            } else {
                makeQuery = false;
            }
        } 
        const serializedParams = JSON.stringify(params);
        if (makeQuery && serializedParams != instance.params) {
            instance.params = serializedParams;
            if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) { 
                return getCustomSubmissions(formId, params, callback);
            } else {
                return getSubmissions(formId, params, callback);
            }
        }
    }
    
};

export const FormioHelper = {
    getAllFormsflow,
    getInputComponents,
    getCustomActions,
    calculateValue,
    customDefaultValue: calculateValue,
    parseXlsx,
    exportXlsx,
    exportGrid,
    fetchSubmissions,
    getLatestSubmission,
    getLatestSubmissions,
    fetchSubmissionList,
    // renderHtmlContent,
    eventHandler,
    onInitialized,
    onFormChange
}
  
window.FormioHelper = FormioHelper;