
// import { renderHtmlContent } from "src/components/Formio/utils";
import { setFormData } from "src/redux/slices/buffers";
const EVENT_OPEN="open";

export const onInitialized= (form, dispatch) => {
    for(let ind in form.components) {
        let component = form.components[ind];
        if (component.type = 'htmlelement' && component.tag == 'div' && component.properties?.component) {
            // renderHtmlContent(form, component, dispatch);
        }
    }
    
}
export const onFormChange = (form, event, dispatch) => {
    if (event.changed) {
        let {component, value} = event.changed;
        //Set form submission
        //dispatch(setFormData(form._id, value));
        //console.log(component, value);

    }
}
export const eventHandler = (event, context) => {
    if(event?.type && event.type.startsWith(EVENT_OPEN)) {
        const action = event.type.substring(EVENT_OPEN.length);
        if (action) {
            context.navigate(action.toLowerCase());

        }
    }
}
