import React, { ReactNode, useEffect } from "react";
import { Panel, PanelFooterTemplateOptions, PanelHeaderTemplateOptions, PanelProps } from "primereact/panel";
import { useState } from "react";
import QueryBuilder, {
    Field,
    ActionElement,
    ActionProps,
    ActionWithRulesProps,
    FieldSelectorProps,
    formatQuery,
    OperatorSelectorProps,
    RuleType,
    ValueEditor,
    ValueEditorProps,
    RuleGroupType,
    ValueSelector,
    CombinatorSelectorProps,
    RuleGroupArray,
    isRuleGroupType
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import { IComponent, IFormio } from "src/types/form";
import { Utils } from "formiojs";
import _clone from "lodash/clone";
import _get from "lodash/get";
import Handlebars from "handlebars";
import { loadForm, loadFormio } from "src/apiManager/services/FormServices";
import { Button } from "primereact/button";
import { t } from "i18next";

interface ISmartQueryBuilderProps {
    header: ReactNode | undefined,
    form: IFormio,
    collapsed: boolean,
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>,
    queryType: "sql" | "json" | "mongodb" | "cel" | "spel",
    //queryType: "sql" | "parameterized" | "parameterized_named" | "mongodb" | "cel" | "spel" | "jsonlogic" | "elasticsearch",
    handleFilter: (base64: string|undefined) => void,
} 
//type ValueEditorType = 'text" | "select" | "checkbox" | "radio" | "textarea" | "switch" | "multiselect' | null;
//queryType: `sql`, `parameterized`, `parameterized_named`, `mongodb`, `cel`, `spel`, `jsonlogic`, `elasticsearch`  
//https://react-querybuilder.js.org/docs/tips/common-mistakes
const actionElement = (props: ActionProps) => { 
    return <ActionElement {...props}/>
}
const addGroupAction = () => null;
const removeGroupAction = () => null;
const cloneGroupAction = () => null;
const addRuleAction = (props: ActionWithRulesProps) => {
    if (props.level == 0) {
        return null;
    }
    //return <ActionElement {...props} />;
};
const removeRuleAction = (props: ActionProps) => {
    return null;
    //return <ActionElement {...props} />;
};
//AND|OR rules
const combinatorSelector = (props: CombinatorSelectorProps) => { 
    return <ValueSelector {...props}/>;
};

const fieldSelector = (props: FieldSelectorProps) => {
    return <ValueSelector {...props} />;
};

const operatorSelector = (props: OperatorSelectorProps) => {
    return null;
    //return <ValueSelector {...props} />;
};

const valueEditor = (props: ValueEditorProps) => {
    return <ValueEditor {...props} />;
};
const SmartQueryBuilder = React.memo((props: ISmartQueryBuilderProps) => {
    let { header, form, queryType, handleFilter, collapsed, setCollapsed } = props;
    //const [collapsed, setCollapsed] = useState<boolean>(true);
    //React query builder
    const [initialQuery, setInitialQuery] = useState<RuleGroupType>({ combinator: 'and', rules: [] });
    const [query, setQuery] = useState(initialQuery);
    const [filterColumn, setFilterColumns] = useState<IComponent[]>()
    const [fields, setFields] = useState<Field[]>();
    const isEnableFilter = (comp: IComponent) => {
        //Chi filter nhung field duoc luu trong db
        let filterable = comp.persistent;
        switch (comp.type) {
        case "select":
            let data: any = _get(comp, "component.data");
            if (data?.resource) {
                filterable = true;
            } else if(Array.isArray(data?.values)){
                filterable = data.values.length > 0;
            } 
            break;
        case "file":
            filterable = false;
            break;
        }
        return filterable;
    }
    const collectFilterFields = (form: IFormio) => { 
        const hiddenComponents = new Set();
        const filterFields: IComponent[] = [];
        Utils.eachComponent(form.components, (component: IComponent) => {
            if (component.hidden == true) {
                Utils.eachComponent([component], (child: IComponent) => {
                    if (child.input) {
                        hiddenComponents.add(child.id);
                    }
                })
            }
        });
        Utils.eachComponent(form.components, (component: IComponent) => {
            if (component.input && component.properties?.smartQuery == "true" && component.key) {
                if (isEnableFilter(component)) {
                    filterFields.push(component);
                }
            }
        });         
        return filterFields;
    }
    //Only add 1 reference resource level
    const createRuleGroup = async (filterFields: IComponent[], level: number) => {
        let rules: RuleGroupArray = [];
        for (let fieldComp of filterFields) {
            let resource;
            if (fieldComp.type == "select" && (resource = _get(fieldComp, "data.resource"))) {
                if (level < 1) {
                    let refForm = await loadFormio(resource);
                    let refFilters = collectFilterFields(refForm);
                    let refRuleGroup: RuleGroupType = await createRuleGroup(refFilters, level + 1);
                    rules.push(refRuleGroup);
                }
            } else {
                let rule: RuleType = {
                    field: `data.${fieldComp.key}`,
                    operator: "=",
                    value: ""
                }
                switch (fieldComp.type) {
                    case "datetime":
                        rule.operator = "between";
                        break;
                    case "number":
                        rule.operator = "="
                        break;
                    case "select":
                        let values = _get(fieldComp, "data.values", []) as any[];
                        // if (values.length > 0) {
                        //     rule.value = values[0].value;
                        // }
                        break;
                    case "textfield":
                    case "textarea":
                        rule.operator = "contains";
                        break;
                    default:
                        
                }
                rules.push(rule);
            }
        }
        return { combinator: "and", rules }
    }

    const createFilterElements = async (filterFields: IComponent[], level: number) => {
        let elements: Field[] = [];
        for (let fieldComp of filterFields) { 
            //fieldComp is reference resource - create a group filter
            let resource;
            if (fieldComp.type == "select" && (resource = _get(fieldComp, "data.resource"))) {
                let refForm = await loadFormio(resource);
                if (level < 1) {
                    let refFilters = collectFilterFields(refForm);
                    let refFields: Field[] = await createFilterElements(refFilters, level + 1);
                    elements = elements.concat(refFields);
                }
            } else {
                let field: Field = {
                    name: `data.${fieldComp.key}`,
                    label: fieldComp.label,
                    disabled: true,
                    validator: (r: RuleType) => !!r.value
                };
                switch (fieldComp.type) {
                    case "datetime":
                        field.inputType = "date";
                        break;
                    case "number":
                        field.inputType = "number";
                        break;
                    case "select":
                        let values = _get(fieldComp, "data.values", []) as any[]; 
                        let custom = _get(fieldComp, "data.custom", ""); 
                        if (custom) {
                            field.valueEditorType = "select";
                            if (fieldComp.template) {
                                const template = /<span>(?<content>.+)<\/span>/;
                                const tplContent = fieldComp.template.match(template);
                                if (tplContent?.groups?.content) {
                                    var compiledTpl = Handlebars.compile(tplContent?.groups?.content);
                                    let arrValues: any[] = [];
                                    let context = { };
                                    let selectValues = Utils.evaluate(custom, context, "values");
                                    if (selectValues instanceof Promise) {
                                        arrValues = await selectValues;
                                    } else if (Array.isArray(selectValues)) {
                                        arrValues = selectValues;
                                    }
                                    field.values = arrValues.map((elm) => {
                                        try {
                                            let value = compiledTpl({ item: elm });
                                            return {name:value, label:value};
                                        } catch (e) {
                                            console.error(e);
                                        }
                                    });
                                    field.values.unshift({ name: "", label: "Please choose an option" });
                                }
                            }                            
                            //field.values = values.map((item, ind) => ({ name: item.value, label: item.label }));
                            //field.values.unshift({ name: "", label: "Please choose an option" });
                        } else if (values.length > 0) {
                            field.valueEditorType = "select";
                            field.values = values.map((item, ind) => ({ name: item.value, label: item.label }));
                            field.values.unshift({ name: "", label: "Please choose an option" });
                        } 
                        break;
                    default:
                   
                }
                elements.push(field);
            }
        };
        return elements;
    }

    // const getFilterFields = async (form: IFormio) => { 
    //     const hiddenComponents = new Set();
    //     let columns: IComponent[] = [];
    //     let fields: Field[] = [];
    //     let rules: RuleType[] = [];
    //     Utils.eachComponent(form.components, (component: IComponent) => {
    //         if (component.tableView == false || component.hidden == true || component.properties?.mainTableView == 'false') {
    //             Utils.eachComponent([component], (child: IComponent) => {
    //                 if (child.input && child.properties?.mainTableView != 'true' && child.key) {
    //                     hiddenComponents.add(child.id);
    //                 }
    //             })
    //         }
    //         let mainTableView = component.properties?.mainTableView;
            
    //         if (component.input && component.tableView && !hiddenComponents.has(component.id) && component.key) {
    //             columns.push(component);
    //             if (isEnableFilter(component)) {
    //                 let field: Field | RuleGroupType = {
    //                     name: component.key,
    //                     label: component.label,
    //                     validator: (r: RuleType) => !!r.value
    //                 };
    //                 let operator = "=";
    //                 switch (component.type) {
    //                     case "datetime":
    //                         field.inputType = "date";
    //                         operator = "between";
    //                         break;
    //                     case "number":
    //                         field.inputType = "number";
    //                         break;
    //                     case "select":
    //                         let values = _get(component, "component.data.values");
    //                         let resource = _get(component, "component.data.resource");
    //                         if (resource) {
    //                             //loadFormio();
    //                         } else if (Array.isArray(values)) {
    //                             field.valueEditorType = "select";
    //                             field.values = values
    //                         }
    //                         break;
    //                     default:
                            
    //                 }
    //                 fields.push(field);
    //                 rules.push({
    //                     field: component.key,
    //                     operator,
    //                     value: ""
    //                 })
    //             }
    //         }
    //     });
    //     setQuery({ combinator: "and", rules });
    //     setFields(fields);
    //     setFilterColumns(columns);
    // }
    const initBuiler = async (form: IFormio) => { 
        let filterFields = collectFilterFields(props.form);
        setFilterColumns(filterFields);
        let ruleGroup = await createRuleGroup(filterFields, 0);
        setQuery(_clone(ruleGroup));
        setInitialQuery(ruleGroup);
        
        let filterElements = await createFilterElements(filterFields, 0);
        setFields(filterElements);
    }
    useEffect(() => { 
        if (props.form) {
            initBuiler(props.form);
        }
    }, [props.form])
    
    //Remove all rule with undefined value
    const removeUndefinedValueRuleGroup = (group: RuleGroupType) : RuleGroupType | undefined => {
        let rules = [];
        for (let ind = 0; ind < group.rules.length; ind++) {
        let rule = group.rules[ind], cleanedRule;
        if (isRuleGroupType(rule)) {
            cleanedRule = removeUndefinedValueRuleGroup(rule as RuleGroupType);
        } else {
            let ruleType = rule as RuleType;
            if (ruleType.value !== undefined && ruleType.value !== "") {
            cleanedRule = ruleType;
            }
        }
        if (cleanedRule) {
            rules.push(cleanedRule);
        }
        }
        if (rules.length > 0) {
        return {
            combinator: group.combinator,
            rules
        }
        }
    }
    const onClickFilter = () => { 
        //Clean undefined value
        let cleanQuery = removeUndefinedValueRuleGroup(query);
        if (cleanQuery) {
        let mongodbquery = formatQuery(cleanQuery, queryType);
            handleFilter(btoa(mongodbquery));
        } else {
            handleFilter(undefined);
        }
    }
    const onClearFilter = () => { 
        // if (props.form) {
        //     initBuiler(props.form);
        // }
        //setQuery(_clone(initialQuery));
    }
    const footerTemplate = (options: PanelFooterTemplateOptions) => {
        const className = `${options.className} flex flex-wrap align-items-center justify-content-between gap-3`;
        if (collapsed) {
            return null;
        }
        return (
            <div className={className}>
                <div className="flex align-items-center gap-2" >
                    <Button icon={collapsed ? "pi pi-plus" : "pi pi-minus"} size="small" text raised
                        onClick={() => setCollapsed(!collapsed)} />
                    <Button label={t("button.search")} icon="pi pi-search" severity="secondary" text
                        onClick={onClickFilter} />
                    {/* 
                    Issue: After clearFilter, the editor is lost focus after each keystroke
                    <Button label={t("button.reset")} icon="pi pi-replay" severity="secondary" text
                        onClick={onClearFilter} /> */}
                    
                </div>
                
            </div>
        );
    };
    return (
        <Panel header={props.header} footerTemplate={footerTemplate} toggleable collapsed={collapsed}
        onToggle={ () => setCollapsed(!collapsed)}>
            <QueryBuilder
                fields={fields}
                query={query}
                onQueryChange={setQuery}
                controlElements={{
                    actionElement,
                    addRuleAction,
                    addGroupAction,
                    cloneGroupAction,
                    removeGroupAction,
                    operatorSelector,
                    removeRuleAction,
                    fieldSelector,
                    valueEditor
                }}
            />
        </Panel>);
})

export default SmartQueryBuilder;

/*
[
    {
        "name": "=",
        "value": "=",
        "label": "="
    },
    {
        "name": "!=",
        "value": "!=",
        "label": "!="
    },
    {
        "name": "<",
        "value": "<",
        "label": "<"
    },
    {
        "name": ">",
        "value": ">",
        "label": ">"
    },
    {
        "name": "<=",
        "value": "<=",
        "label": "<="
    },
    {
        "name": ">=",
        "value": ">=",
        "label": ">="
    },
    {
        "name": "contains",
        "value": "contains",
        "label": "contains"
    },
    {
        "name": "beginsWith",
        "value": "beginsWith",
        "label": "begins with"
    },
    {
        "name": "endsWith",
        "value": "endsWith",
        "label": "ends with"
    },
    {
        "name": "doesNotContain",
        "value": "doesNotContain",
        "label": "does not contain"
    },
    {
        "name": "doesNotBeginWith",
        "value": "doesNotBeginWith",
        "label": "does not begin with"
    },
    {
        "name": "doesNotEndWith",
        "value": "doesNotEndWith",
        "label": "does not end with"
    },
    {
        "name": "null",
        "value": "null",
        "label": "is null"
    },
    {
        "name": "notNull",
        "value": "notNull",
        "label": "is not null"
    },
    {
        "name": "in",
        "value": "in",
        "label": "in"
    },
    {
        "name": "notIn",
        "value": "notIn",
        "label": "not in"
    },
    {
        "name": "between",
        "value": "between",
        "label": "between"
    },
    {
        "name": "notBetween",
        "value": "notBetween",
        "label": "not between"
    }
]
*/