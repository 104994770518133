import Iconify from 'src/components/iconify';
import { useLocales } from "src/locales";
import SvgColor from 'src/components/svg-color';
import { BASE_CONTEXT } from 'src/config-global';
import { buildUrlMenu } from './meta';
import { ICONS } from './icons';
export function getFormTypeIcon(type) {
    return (<Iconify icon="solar:file-bold-duotone"/>);
}
export function getFormIcon(formName) {
    //return <SvgColor src={`assets/icons/navbar/${formName}.svg`} sx={{ width: 1, height: 1 }} />;
    return <Iconify icon="solar:file-bold-duotone"/>;
}

export function createFormMenus(forms, type) {
    const { t } = useLocales();
    const createMenuItem = (item, type) => ({
          //title: t(`${type}.${item.name}`),
          title: item.formName,
          path: `${item.formName}/list`
        });
    return {
        title: t("menu." + type),
        icon: getFormTypeIcon(type),
        path: type,
        children: [{
            subheader: t("menu." + type),
            items: forms.filter(form => form.type == type).map(child => createMenuItem(child, type))
        }]
    };
}


export function createNavMenus(forms, type) {
    const { t } = useLocales();
    const createMenuItem = (form, type) => {
        const name = form.formName.toLowerCase();
        const root = `${BASE_CONTEXT}/form/${form.formId}`;
        return {
          //title: t(`${type}.${name}`),
          title: name,
          path: root,
          icon: getFormIcon(name),
          children: [
            { title: t('list'), path: `${root}/submission/list` },
            { title: t('create'), path: `${root}/submission/create` }
          ]
        }
    };
    return {
        subheader: t("menu." + type),
        items: forms.filter(form => form.formType == type).map(child => createMenuItem(child, type))
    };
}


export function menuBuilder(menuTree) {
    const createMenuItem = (menu, root = false) => {
      let children = menu.children?.map((child) => createMenuItem(child));
      return root ? {
        subheader: menu.data.title,
        path: buildUrlMenu(menu),
        items: children,
        open: menu.data.expanded || false
      } : 
       ((children && children.length > 0) ? {
          title: menu.data.title,
          path: buildUrlMenu(menu),
          icon: ICONS.menuItem,
          children
        } : {
          title: menu.data.title,
          path: buildUrlMenu(menu),
          icon: ICONS.menuItem,
        })
    }
    const menuRoutes = menuTree ? menuTree.map(menu => createMenuItem(menu, true)) : [];
    return menuRoutes;
  }