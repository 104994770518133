import React, { useEffect, lazy, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { buildPageUrl, buildUrlFormSubmission } from "src/utils/meta";
import _get from "lodash/get"
import Loading from "src/components/Loading"; 
import { useSelector } from "react-redux";
import { FORM_MENU, FORM_PAGE } from "src/config-global";
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const MenuEntry = React.memo((props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { menuId } = useParams();
    const mapMenus = useSelector((state) => state.meta.mapSubmissions[FORM_MENU]);
    const mapPages = useSelector((state) => state.meta.mapSubmissions[FORM_PAGE]);
    const [isLoading, setLoading] = useState(true);

    const initMenu = () => {
        let menu = mapMenus[menuId];
        let pageId = _get(menu, "data.page");
        let formId = _get(menu, "data.form");
        let page;
        if (pageId && (page = mapPages[pageId])) {
            let url = buildPageUrl(page);
            if (url) {
                navigate(url);
            }
        } else if (typeof menu?.data?.form == 'string') {
            let url = buildUrlFormSubmission(menu.data);
            navigate(url);
        } else {

        }
        setLoading(false);
    }
    useEffect(() => {
        initMenu();
    },[menuId])
    return (
        isLoading ? <Loading/> : <ComingSoonPage/>
    )
})
export default MenuEntry;