import { Route, Routes, Navigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { Formio, getForm } from "@formio/react";
import { useDispatch, useSelector } from "react-redux";
import {
  STAFF_DESIGNER,
  STAFF_REVIEWER,
  CLIENT,
  BASE_ROUTE,
  MULTITENANCY_ENABLED,
} from "src/constants";
import View from "./View";
import Submission from "./Submission/index";
import { checkIsObjectId } from "src/apiManager/services/formatterService";
import { fetchFormByAlias } from "src/apiManager/services/bpmFormServices";
import {
  setFormRequestData,
  setFormSuccessData,
  resetFormData,
  clearSubmissionError,
  setFormAuthVerifyLoading,
} from "src/actions/formActions";

import Draft from "../../Draft";
import {LoadingScreen as Loading }  from "src/components/loading-screen";
import { getClientList } from "src/apiManager/services/authorizationService";
import NotFound from "../../NotFound";
import { setApiCallError } from "src/actions/ErrorHandling";

const Item = React.memo(() => {
  const { formId } = useParams();
  const userRoles = useSelector((state) => state.user.roles || []);
  const tenantKey = useSelector((state) => state?.tenants?.tenantId);
  const redirectUrl = MULTITENANCY_ENABLED ? `/tenant/${tenantKey}/` : "/";
  const formAuthVerifyLoading = useSelector((state) => state.process?.formAuthVerifyLoading);
  const apiCallError = useSelector((state)=>state.errors?.apiCallError);
  const dispatch = useDispatch();

  const formAuthVerify = (formId,successCallBack)=>{
      getClientList(formId).then(successCallBack).catch((err)=>{
        const {response} = err;
        dispatch(setApiCallError({message:response?.data?.message || 
          response.statusText,status:response.status}));
      }).finally(()=>{
        dispatch(setFormAuthVerifyLoading(false));
      });
  };
  
  useEffect(() => {
    dispatch(setApiCallError(null));
    dispatch(setFormAuthVerifyLoading(true));
    dispatch(resetFormData("form", formId));
    dispatch(clearSubmissionError("submission"));
    if (checkIsObjectId(formId)) {
      dispatch(getForm("form", formId,(err,res)=>{
        if(err){
          dispatch(setFormAuthVerifyLoading(false));
        }else{    
          formAuthVerify(res.parentFormId || res._id);
        }
      }));
    } else {
      dispatch(
        fetchFormByAlias(formId, async (err, formObj) => {
          if (!err) {
       
            formAuthVerify(formObj.parentFormId || formObj._id,()=>{
              const form_id = formObj._id;
              dispatch(
                setFormRequestData(
                  "form",
                  form_id,
                  `${Formio.getProjectUrl()}/form/${form_id}`
                )
              );
              dispatch(setFormSuccessData("form", formObj));
            });
          
          } else {
            dispatch(setFormAuthVerifyLoading(false));
            const { response } = err;
            dispatch(
              setApiCallError({
                message:
                  response?.data?.message ||
                  response?.statusText ||
                  err.message,
                status: response?.status ,
              })
            );
          }
        })
      );
    }

  }, [formId, dispatch]);
  /**
   * Protected route to form submissions
   */

  if(formAuthVerifyLoading){
    return <Loading/>;
  }

  if(apiCallError && !formAuthVerifyLoading){
    return <NotFound
    errorMessage={apiCallError?.message}
    errorCode={apiCallError?.status}
  />;
  }

  const createSubmissionRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      Component={(props) =>
        userRoles.includes(STAFF_DESIGNER) || userRoles.includes(STAFF_REVIEWER) || userRoles.includes(CLIENT) ? (
          <Component {...props} />
        ) : (
          <Navigate to={`${redirectUrl}`} />
        )
      }
    />
  );

  /**
   * Protected route for form deletion
   */
  const root = `${BASE_ROUTE}form/:formId`;
  return (
    <div>
      <Routes>
        <Route exact path={""} component={View} />
        { createSubmissionRoute({
          path: `submission/*`,
          component: Submission
        })}
        {createSubmissionRoute({
          path: `draft/*`,
          component: Draft
        })}
       {/* <Navigate to="/404" /> */}

      </Routes>
    </div>
  );
});

export default Item;
