import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import App from './App';
import { Formio as Formiojs } from 'formiojs';
import { Formio, Components } from "@formio/react";
import formioComponents from 'src/components/Formio';
import { FormioHelper } from 'src/components/Formio';
import { SystemUtil } from 'src/utils/systemUtil';
import { AppConfig } from "./config-global";
// Import primereact theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import "src/assets/styles/primereact.css";
import "src/assets/styles/user-styles.css";
// ----------------------------------------------------------------------

Formio.setProjectUrl(AppConfig.projectUrl);
Formio.setBaseUrl(AppConfig.apiUrl);
(window as any).SystemUtil = SystemUtil;

const formioPlugin = {
  components: formioComponents
}
Formiojs.use(formioPlugin);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <HashRouter>
      <Suspense>
        <App />
      </Suspense>
    </HashRouter>
  </HelmetProvider>
);
