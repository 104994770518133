import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import { ISubmission } from "src/types/form";
import { Avatar } from "primereact/avatar";
import { FormSubmissionParams, fetchSubmission } from "src/apiManager/services/FormServices";

interface IFormImageProps {
    size: "normal" | "large" | "xlarge" | undefined,
    shape: "square" | "circle" | undefined,
    submission?: ISubmission,
    fieldName: string,
}

const FormImage = React.memo((props: IFormImageProps) => {
    const {
        size,
        shape,
        submission,
        fieldName
    } = props;
    const [errors, setErrors] = useState([]);
    const [imageUrl, setImageUrl] = useState();
    const loadSubmission = async (submission: ISubmission) => { 
        let params: FormSubmissionParams = {
            formId: submission.form,
            submissionId: submission._id
        }
        let res = await fetchSubmission(params);
        let files = _get(res.data, fieldName, []);
        let file = files[0];
        if (file.originalName && file.url) {
            setImageUrl(file.url);
        }
    }
    useEffect(() => {
        if (submission) {
            loadSubmission(submission);
        }
     }, [
        submission
    ])
    return (
        <Avatar image={imageUrl} size={size} shape={shape} />
    );
});
export default FormImage;