import { lazy, useContext, Suspense } from "react";
import { Outlet } from 'react-router-dom';
import ApplicationLayout from 'src/layouts/application';
import MainLayout from 'src/layouts/main';

import { SplashScreen } from 'src/components/loading-screen';
// config
import { BASE_CONTEXT } from 'src/config-global';
const Form = lazy(()=>import("src/components/Form"));
const FormListPage = lazy(() => import("src/pages/formio/list"));
const FormBuilderPage = lazy(() => import("src/pages/formio/builder"));
const ModelerPage = lazy(() => import("src/pages/modeler"));
export const designerRoutes = [
    {
        element: (
            <ApplicationLayout>
              <Suspense fallback={<SplashScreen />}>
                  <Outlet />
              </Suspense>
            </ApplicationLayout>
        ),
        path: `${BASE_CONTEXT}/designer`,
        children: [
            { path: 'form/*', element: <Form/>},
            { path: 'forms', element: <FormListPage /> },
            { path: 'builder', element: <FormBuilderPage /> },
            { path: 'modeler', element: <ModelerPage /> }
        ]
    }
]