import React, { useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useDispatch } from 'src/redux/store';
import {
  selectRoot,
  resetSubmissions,
  saveSubmission,
  Form,
  selectError,
  Errors,
} from "@formio/react";
import cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "src/components/containers/Loading";
import { setFormSubmissionLoading } from "src/actions/formActions";
import { useTranslation } from "react-i18next";
import { default as formio_resourceBundles } from "src/locales/formio";
import {
  CUSTOM_SUBMISSION_URL,
  CUSTOM_SUBMISSION_ENABLE,
  MULTITENANCY_ENABLED,
} from "src/constants";
import { saveCustomSubmission, loadForm, loadFormio, deleteSubmission } from "src/apiManager/services/FormServices";
import DownloadPDFButton from "src/components/Form/ExportAsPdf/downloadPdfButton";
import SubmissionActions from "../../../../smartform/SubmissionActions";
import { Dialog } from "primereact/dialog";
import FormContainer from "src/components/smartform/FormContainer";
import { actionHandler, cleanupSubmissionData, handleOpenForm } from "src/components/Formio/components/helper";
import { fetchFormByAlias } from "src/apiManager/services/bpmFormServices";
import { loadFormActions } from "src/helper/helper";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { createUrlSubmissionEdit, createUrlSubmissionList } from "src/utils/meta";
const View = React.memo((props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formId, submissionId } = useParams();
  const {
    hideComponents,
    onSubmit,
    options,
    errors,
    form: { form, isActive: isFormActive },
    formActions,
    submission: { submission, isActive: isSubActive, url },
    onCustomEvent,
    onInitialized = (form) => {},
    showPrintButton,
  } = props;
  const tenantKey = useSelector((state) => state.tenants?.tenantId);
  const tenantUrl = MULTITENANCY_ENABLED ? `/tenant/${tenantKey}` : "";
  const formActionSubmissions = useSelector((state) => state.meta.submissions.formActions);
  const [showModal, setShowModal] = useState(false);
  const [formModal, setFormModal] = useState();
  const [formModalActions, setFormModalActions] = useState();
  const [modalSubmission, setModalSubmission] = useState({});
  const isFormSubmissionLoading = useSelector(
    (state) => state.formDelete.isFormSubmissionLoading
  );

  const customSubmission = useSelector(
    (state) => state.customSubmission?.submission || {}
  );

  const selectedSubmission = useMemo(()=>{
    if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
      return cloneDeep(customSubmission);
    } else {
      return cloneDeep(submission);
    }
  },[customSubmission,submission]);


  const handleModalSubmit = (payload, formAction, context) => { 
    let modalSubmission = cleanupSubmissionData(payload);
    setModalSubmission(modalSubmission);
    const mainContext = { ...actionContext() }; 
    mainContext.properties = context.properties || {};
    if (modalSubmission?.data) {
      mainContext.properties = { ...mainContext.properties, ...modalSubmission.data };
    }
    if (formAction) {
      return actionHandler(formAction, mainContext);
    }
  }
  const openEdit = () => { 
    let url = createUrlSubmissionEdit({ tenantKey, formId: form._id, submissionId: selectedSubmission._id});
    navigate(url);
  }
  const deleteAccept = () => { 
    const callback = (err) => { 
      if (!err) {
        const submissionDetails = {
          modalOpen: false,
          submissionId: "",
          formId: "",
        };
        dispatch(setFormSubmissionDeleteStatus(submissionDetails));
        let url = createUrlSubmissionList({ tenantKey, formId: form._id});
        navigate(url);
      }
    }
    dispatch(deleteSubmission(form._id, selectedSubmission._id, callback));
  }
  const openDeleteConfirm = () => { 
    confirmDialog({
        message: 'Do you want to delete this record?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: deleteAccept
    });
  }
  if (isFormActive || (isSubActive && !isFormSubmissionLoading) || !selectedSubmission?.data) {
    return <Loading />;
  }
  const handleOpenDialog = async (formModalId) => { 
    let formModal = await loadFormio(formModalId);
    let formActions = formActionSubmissions?.filter((action) => _get(action, "data.sourceForm.formId") == formModalId);
    setFormModal(formModal);
    setFormModalActions(formActions);
    setShowModal(true);
  }
  const actionContext = () => ({
    form,
    submissionId: selectedSubmission?._id,
    navigate,
    dispatch,
    handleOpenDialog
    // handleOpenDialog: (formModal, formActions) => { 
    //   setFormModal(formModal);
    //   setFormModalActions(formActions);
    //   setShowModal(true);
    // }
  });
  
  return (
    <div className="container">
      <ConfirmDialog />
      {/* <div className="main-header" style={{ "height": "45px" }}>
        {showPrintButton && form?._id ? (
          <div className="btn-right d-flex flex-row">
            <DownloadPDFButton
              form_id={form._id}
              submission_id={selectedSubmission._id}
              title={form.title}
            />
          </div>
        ) : null}
      </div> */}
      <Dialog header={ formModal?.title} visible={showModal} maximizable style={{ width: '50vw' }} onHide={() => setShowModal(false)}>
        <FormContainer
          form={formModal}
          formActions={formModalActions}
          //actionHandler={modalActionHandler}
          handleSubmit={handleModalSubmit}
          submission={modalSubmission}
        />
      </Dialog>
      <SubmissionActions page="view" form={form} onEdit={openEdit} onDelete={openDeleteConfirm}
        formActions={formActions}
        handleOpenDialog = {handleOpenDialog}
        actionHandler={(action, properties) => actionHandler(action, { ...actionContext(), ...properties })}
        submission = {selectedSubmission}
        submissionId={selectedSubmission._id} />
      {/* <Errors errors={errors} />       */}
      <div className="sub-container">
        <Form
          form={form}
          submission={selectedSubmission}
          url={url}
          hideComponents={hideComponents}
          onSubmit={(submission) => onSubmit(formId, submission)}
          options={{ ...options, i18n: formio_resourceBundles }}
          onInitialized={() => onInitialized(form)}
          onCustomEvent={onCustomEvent}
        />
      </div>
    </div>
  );
});

View.defaultProps = {
  showPrintButton: true,
};

const mapStateToProps = (state, props) => {
  const isDraftView = props.page === "draft-detail" ? true : false;
  const form = selectRoot("form", state);
  const submission = isDraftView
  ? selectRoot("draft", state)
  : selectRoot("submission", state);
  return {
    form: form ? cloneDeep(form) : {},
    submission: submission ? cloneDeep(submission) : {},
    options: {
      readOnly: true,   //Render fields as readonly
      viewAsHtml: true, //Do not render button
      language: state.user.lang,
    },
    errors: [selectError("submission", state), selectError("form", state)],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (formId, submission) => {
      dispatch(setFormSubmissionLoading(true));
      const callBack = (err, submission) => {
        if (!err) {
          dispatch(resetSubmissions("submission"));
          dispatch(setFormSubmissionLoading(false));
          navigate(
            `/form/${formId}/submission/${submission._id}`
          );
        } else {
          dispatch(setFormSubmissionLoading(false));
        }
      };
      if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
        saveCustomSubmission(
          submission,
          formId,
          callBack
        );
      } else {
        dispatch(
          saveSubmission(
            "submission",
            submission,
            formId,
            callBack
          )
        );
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
