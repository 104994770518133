import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
/*
 * This slice store working data on the form. 
 * This data is use for manipulation related forms or components
 */
const initialState: Record<string, any> = {
  
};

const slice = createSlice({
  name: 'buffer',
  initialState,
  reducers: {
    setFormData(state, action) {
      const {formId, formData } = action.payload;
      if (formId) {
        state[formId] = formData;
      }
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setFormData,
} = slice.actions;
