import Calendar from '@fullcalendar/react'; // => request placed at the top
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
//
import { useState, useEffect, useCallback } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
// utils
import { fTimestamp } from 'src/utils/format-time';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
import { CALENDAR_COLOR_OPTIONS } from 'src/_mock/_calendar';
// api
import { useGetEvents, updateEvent } from 'src/api/calendar';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
// types
import { ICalendarFilters, ICalendarFilterValue, ICalendarEvent } from 'src/types/calendar';
//
import { useCalendar, useEvent } from 'src/sections/calendar/hooks';
import { StyledCalendar } from 'src/sections/calendar/styles';
import CalendarForm from 'src/sections/calendar/calendar-form';
import CalendarToolbar from 'src/sections/calendar/calendar-toolbar';
import CalendarFilters from 'src/sections/calendar/calendar-filters';
import CalendarFiltersResult from 'src/sections/calendar/calendar-filters-result';
import { PageSizes } from 'src/constants';
import { IFieldMapper, IFormio, IPageParameter, ISubmission } from 'src/types/form';
import {
  getSubmissions
} from 'src/apiManager/services/FormServices';
import { ColumnSortMetaData } from 'primereact/column';
import {
  Form,
  Utils,
} from "@formio/react";
import _get from "lodash/get";
import moment from 'moment';
// ----------------------------------------------------------------------
interface IPageData {
  period?: string,
  //For calendar event
  eventDescription?: string,
  startPeriod?: string,
  endPeriod?: string,
  eventTitle?: string,
  start?: string,
  end?: string,
  weekDay?: string,
  /***** End for page data *****/
  mappers?: IFieldMapper[],
  parameters?: IPageParameter[]
}
interface ICalendarProps {
  pageData?:IPageData,
  mapper?: Record<string, IFieldMapper>
  form: IFormio,
  formActions?: ISubmission[],
  formId?: string,
  onSubmit: Function
}
const defaultFilters: ICalendarFilters = {
  colors: [],
  startDate: null,
  endDate: null,
};

// ----------------------------------------------------------------------
const defaultSort = "-created";
export default function CalendarView(props : ICalendarProps) {
  const theme = useTheme();
  const settings = useSettingsContext();
  const smUp = useResponsive('up', 'sm');
  const openFilters = useBoolean();
  const [sortMeta, setSortMeta] = useState<ColumnSortMetaData[]>([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [rows, setRows] = useState(PageSizes[0]);
  const [events, setEvents] = useState<ICalendarEvent[]>([]);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [submission, setSubmission] = useState<ISubmission>();
  const [parameters, setParameters] = useState<Record<string, any>>();
  const [mappers, setMappers] = useState<Record<string,any>>();

  // const { events, eventsLoading } = useGetEvents();
  const CALENDAR_COLOR_OPTIONS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.info.main,
    theme.palette.info.darker,
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.error.main,
    theme.palette.error.darker,
  ];

  const { form, pageData, onSubmit } = props;
  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.getTime() > filters.endDate.getTime()
      : false;

  const {
    calendarRef,
    //
    view,
    date,
    //
    onDatePrev,
    onDateNext,
    onDateToday,
    onDropEvent,
    onChangeView,
    onSelectRange,
    onClickEvent,
    onResizeEvent,
    onInitialView,
    //
    openForm,
    onOpenForm,
    onCloseForm,
    //
    selectEventId,
    selectedRange,
    //
    onClickEventInFilters,
  } = useCalendar();

  const currentEvent = useEvent(events, selectEventId, selectedRange, openForm);

  const createQueryParams = () => { 
    let sorts = sortMeta.map(item => item.order == 1 ? item.field : "-" + item.field);
    sorts.push(defaultSort);
    let queryParams = {
      limit: rows,
      page: 1,
      skip: 0,
      query: {
        sort: sorts.join(",")
      }
    };
    // if (refFormAction && refSubmission) {
    //   let parameters = _get(refFormAction, "data.parameters", []);
    //   for (let param of parameters) {
    //     if (param.name == 'refField' && param.value) {
    //       queryParams.query[`data.${param.value}._id`] = refSubmission._id;
    //     }
    //   }
    // }
    return queryParams;
  }
  const createEvents = (submission: ISubmission) => {
    const events: ICalendarEvent[] = [];
    const context = {
      _get,
      moment,
      data: submission.data,
      currentDate: date,
    };
    let startPeriod = Utils.evaluate(pageData?.startPeriod, context, "value", false);
    startPeriod = startPeriod ? moment(startPeriod) : null;
    let endPeriod = Utils.evaluate(pageData?.endPeriod, context, "value", false);
    endPeriod = endPeriod ? moment(endPeriod) : null;
    let weekday = Utils.evaluate(pageData?.weekDay, context, "value", false);
    let dayPeriod = 1;
    if (pageData?.period == 'weekly') {
      dayPeriod = 7;
    }
    let startMoment = startPeriod;
    if (weekday && startPeriod) {
      startMoment = startMoment.day(weekday);
    }
    if (startMoment && endPeriod) {
      let id = 0;
      const colorInd = Math.floor(Math.random() * CALENDAR_COLOR_OPTIONS.length);
      while (startMoment.isSameOrBefore(endPeriod)) {
        context.currentDate = startMoment.toDate(); 
        const event: ICalendarEvent = {
          id: `${submission._id}#${id}`,
          color: CALENDAR_COLOR_OPTIONS[colorInd],
          title: Utils.evaluate(pageData?.eventTitle, context, "value", false) || "",
          allDay: false,
          description: Utils.evaluate(pageData?.eventDescription, context, "value", false) || "",
          start: Utils.evaluate(pageData?.start, context, "value", false),
          end: Utils.evaluate(pageData?.end, context, "value", false)
        };
        if (moment.isMoment(event.start)) {
          //event.start = event.start.unix();
          event.start = event.start.valueOf();
        }
        if (moment.isMoment(event.end)) {
          event.end = event.end.valueOf();
        }
        events.push(event);
        id++;
        startMoment.add(dayPeriod, "days") //or startMoment.add(dayPeriod, "d");
      }
    }
    return events;
  }
  /*
   * Create calendar events from submission list
   */
  const processSubmissions = (err: any, submissions: ISubmission[]) => { 
    let calendarEvents: ICalendarEvent[] = [];
    submissions.forEach((submission) => {
      let events = createEvents(submission);
      calendarEvents.push(...events);
    });
    setEvents(calendarEvents);
    setEventsLoading(false);
  }
  
  useEffect(() => {
    if (props.form) {
      const queryParams = createQueryParams();
      getSubmissions(props.form._id, queryParams, processSubmissions);
    }
  }, [props.form])
  useEffect(() => { 
    const pageParameters = pageData?.parameters?.reduce((total: Record<string, any>, param: IPageParameter) => {
        let scope = param.scope || 'view';
        total[scope] = total[scope] || {};
        total[scope][param.name] = param.value;
        return total;
    }, {});
    setParameters(pageParameters);
      const fieldMappers = pageData?.mappers?.reduce((total: Record<string, IFieldMapper>, mapper: IFieldMapper) => {
      total[mapper.fieldName] = mapper;
      return total;
      }, {});
    setMappers(fieldMappers);
  }, [props.pageData]);

  useEffect(() => {
    onInitialView();
  }, [onInitialView]);

  const handleFilters = useCallback((name: string, value: ICalendarFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  // const onSubmit = (submission: ISubmission) => { 
  //     //Find action associated with the submit button.
  //     const components = FormioUtils.flattenComponents(form?.components as any[], false);
  //     let actionName = null;
  //     let context: IContext = {};
  //     for (var field in components) {
  //         const comp = components[field];
  //         if (comp.type == 'button' && comp.action == 'submit') {
  //             actionName = comp.properties.action;
  //             context.properties = comp.properties;
  //             break;
  //         }
  //     }
      
  //     let submitAction = null;
  //     if (actionName && formActions) {
  //         for (let ind = 0; ind < formActions.length; ind++) {
  //             const formAction = formActions[ind];
  //             if (actionName == _get(formAction, "data.actionName")) {
  //                 submitAction = formAction;
  //                 break;
  //             }
  //         }
  //     } 
  //     handleSubmit(submission, submitAction, context);
  // }

  const canReset = !!filters.colors.length || (!!filters.startDate && !!filters.endDate);

  const dataFiltered = applyFilter({
    inputData: events,
    filters,
    dateError,
  });

  const renderResults = (
    <CalendarFiltersResult
      filters={filters}
      onFilters={handleFilters}
      //
      canReset={canReset}
      onResetFilters={handleResetFilters}
      //
      results={dataFiltered.length}
      sx={{ mb: { xs: 3, md: 5 } }}
    />
  );

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          <Typography variant="h4">Calendar</Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={onOpenForm}
          >
            New Event
          </Button>
        </Stack>

        {canReset && renderResults}

        <Card>
          <StyledCalendar>
            <CalendarToolbar
              date={date}
              view={view}
              loading={eventsLoading}
              onNextDate={onDateNext}
              onPrevDate={onDatePrev}
              onToday={onDateToday}
              onChangeView={onChangeView}
              onOpenFilters={openFilters.onTrue}
            />

            <Calendar
              weekends
              editable
              droppable
              selectable
              rerenderDelay={10}
              allDayMaintainDuration
              eventResizableFromStart
              ref={calendarRef}
              initialDate={date}
              initialView={view}
              dayMaxEventRows={3}
              eventDisplay="block"
              events={dataFiltered}
              headerToolbar={false}
              select={onSelectRange}
              eventClick={onClickEvent}
              height={smUp ? 720 : 'auto'}
              eventDrop={(arg) => {
                onDropEvent(arg, updateEvent);
              }}
              eventResize={(arg) => {
                onResizeEvent(arg, updateEvent);
              }}
              plugins={[
                listPlugin,
                dayGridPlugin,
                timelinePlugin,
                timeGridPlugin,
                interactionPlugin,
              ]}
            />
          </StyledCalendar>
        </Card>
      </Container>

      <Dialog
        fullWidth
        open={openForm}
        onClose={onCloseForm}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ minHeight: 76 }}>
          {openForm && <> {currentEvent?.id ? 'Edit Event' : 'Add Event'}</>}
        </DialogTitle>
        <div className='m-2'>
          <Form
              form={form}
              submission={submission}
              onSubmit={onSubmit}
            />
        </div>
        {/* <CalendarForm
          currentEvent={currentEvent}
          colorOptions={CALENDAR_COLOR_OPTIONS}
          onClose={onCloseForm}
        /> */}
      </Dialog>

      <CalendarFilters
        open={openFilters.value}
        onClose={openFilters.onFalse}
        //
        filters={filters}
        onFilters={handleFilters}
        //
        canReset={canReset}
        onResetFilters={handleResetFilters}
        //
        dateError={dateError}
        //
        events={events}
        colorOptions={CALENDAR_COLOR_OPTIONS}
        onClickEvent={onClickEventInFilters}
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  filters,
  dateError,
}: {
  inputData: ICalendarEvent[];
  filters: ICalendarFilters;
  dateError: boolean;
}) {
  const { colors, startDate, endDate } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  inputData = stabilizedThis.map((el) => el[0]);

  if (colors.length) {
    inputData = inputData.filter((event) => colors.includes(event.color as string));
  }

  if (!dateError) {
    if (startDate && endDate) {
      inputData = inputData.filter(
        (event) =>
          fTimestamp(event.start) >= fTimestamp(startDate) &&
          fTimestamp(event.end) <= fTimestamp(endDate)
      );
    }
  }

  return inputData;
}
