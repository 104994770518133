import { getSubmission } from '@formio/react';
import {
  getCustomSubmissions,
  getSubmissions 
} from 'src/apiManager/services/FormServices';
import {
  CUSTOM_SUBMISSION_URL,
  CUSTOM_SUBMISSION_ENABLE,
  ACCESS_FULL,
  ACTION_READ_ALL,
  ACTION_CREATE_ALL,
  ACTION_READ_OWN,
  ACTION_CREATE_OWN,
  STAFF_DESIGNER
} from "src/constants";
import { buildTree } from 'src/utils/tree';
import _get from "lodash/get";
import _set from "lodash/set";
import _cloneDeep from "lodash/cloneDeep";
import _camelCase from "lodash/camelCase";
import { setCustomSubmission } from 'src/actions/checkListActions';
import { replaceUrl } from 'src/helper/helper';
import API from 'src/apiManager/endpoints';
import { accumulateAccesses } from 'src/components/Formio/utils';
import { fetchBPMFormList } from 'src/apiManager/services/bpmFormServices';

export const OPTION_ACTIONS = [
  {
    value: "submission/list",
    label: "List"
  },
  {
    value: "submission/create",
    label: "Create"
  }
]
export const OPTION_OPEN_METHODS = [
  {
     value: "navigation",
     label: "Navigation"
  },
  {
     value: "newTab",
     label: "New tab"
  },
  {
     value: "newWindow",
     label: "New window"
  },
  {
     value: "modalDialog",
     label: "Modal dialog"
  },
  {
     value: "nonmodalDialog",
     label: "Nonmodal dialog"
  }
]
export const OPTION_APIS = [
  {
    value: "submissionAction",
    label: "Submission action"
  },
  {
    value: "submissionCreate",
    label: "Create Submission"
  },
  {
    value: "",
    label: "No action"
  }
]
const mapFields = {
    id: "_id",
    label: "data.title",
    data: "data",
    name: "data.name",
    priority: "data.priority",
    level: "data.level",
    parent: "data.parent"
  };
// reducer from react-formio code
export const reducer = (form, { type, value }) => {
  const formCopy = _cloneDeep(form);
  switch (type) {
    case "formChange":
      for (let prop in value) {
        if (Object.prototype.hasOwnProperty.call(value, prop)) {
          form[prop] = value[prop];
        }
      }
      return form;
    case "formReset": 
      for (let prop in form) {
        if (Object.prototype.hasOwnProperty.call(value, prop)) {
          form[prop] = value[prop];
        } else {
          form[prop] = null;
        }
      }
      return form;
    // case "title":
    //   if (type === "title" && !form._id) {
    //     formCopy.name = _camelCase(value);
    //   }
    //   break;
    default:
      break;
  }
  _set(formCopy, type, value);
  return formCopy;
};  
export const fetchForms = (dispatch, callback = (err, forms) => { }) => { 
  let filters = [1, 1000, "formName", "asc", "", "form,resource"];
  dispatch(fetchBPMFormList(...filters, callback));
}
export const fetchMenus = async (menuFormId, callback = (err, menus) => {}) => {
  const params = {page:1, limit: 9999, sort: "priority"};
  if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) { 
    getCustomSubmissions(menuFormId, params, callback);
  } else {
    getSubmissions(menuFormId, params, callback)
    }
}
const checkPermission = (menu, roles, formAccess) => { 
  let formId = _get(menu, "data.form");
  if (!formId) {
    //Node without formaccess
    return true;
  }
  let action = _get(menu, "data.action");
  let access = accumulateAccesses(formId, roles, formAccess);
  let parts = action ? action.split('/') : [];
  if (parts.length == 2) {
    if (parts[1] == 'list') {
      return access[ACTION_READ_ALL] || access[ACTION_READ_OWN]
    } else if (parts[1] == 'create') {
      return access[ACTION_CREATE_ALL] || access[ACTION_CREATE_OWN]
    }
  }
}
//Todo: check phan quyen tu phia server
export const  buildMenus = (submissions, userRoles, formAccess, callback = (err, menuTree, filteredMenus) => { }) => {
  /*
    * Order by parent and priority
    * Todo: Order in server
    */
  let filteredMenus = (userRoles.includes(STAFF_DESIGNER) || userRoles.includes(ACCESS_FULL)) ? submissions
    : submissions.filter((menu) => checkPermission(menu, userRoles, formAccess));
  filteredMenus.sort(function(a, b) {
    let pa = _get(a, "data.priority") || 1000;
    let pb = _get(b, "data.priority") || 1000;
    return pa - pb;
  });
  let menuTreeUpdate = buildTree(filteredMenus, mapFields)
  callback(null, menuTreeUpdate, filteredMenus);
}


export const getCustomSubmission = (submissionId, formId, ...rest) => {
  const done = rest.length ? rest[0] : () => {};
  const submissionUrl = replaceUrl(API.CUSTOM_SUBMISSION, "<form_id>", formId);
  let authHeaders = createAuthHeader();
  return (dispatch) => {
    RequestService.httpGETRequest(`${submissionUrl}/${submissionId}`, {}, "", false, authHeaders)
      .then((res) => {
        if (res.data) {
          dispatch(setCustomSubmission(res.data));
        } else {
          dispatch(setCustomSubmission({}));
        }
      })
      .catch((err) => {
        done(err, null);
      });
  };
};