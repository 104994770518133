import { BASE_CONTEXT } from 'src/config-global';
import _get from "lodash/get";

export const createUrlForm = (options = {}) => { 
  let url = BASE_CONTEXT;
  const tenantUrl = options.tenantKey ? `tenant/${options.tenantKey}` : "";
  const formUrl = options.formId ? `form/${options.formId}` : "";
  if (tenantUrl) {
    url = `${url}/${tenantUrl}`;
  }
  if (formUrl) {
    url = `${url}/${formUrl}`;
  }
  return url;
}
export const createUrlSubmissionCreate = (options = {}) => { 
  const formUrl = createUrlForm(options);
  let url = `${formUrl}/submission/create`;
  let params = options.params || [];
  if (options.refFormId) {
    params.push(`refFormId=${options.refFormId}`);
  }
   if (options.refSubmissionId) {
    params.push(`refSubmissionId=${options.refSubmissionId}`);
  }
  if (params.length > 0) {
    url = `${url}?${params.join('&')}`;
  } 
  return url;
}
export const createUrlSubmissionList = (options = {}) => { 
  const formUrl = createUrlForm(options);
  let url = `${formUrl}/submission/list`;
  if (options.params?.length > 0) {
    url = `${url}?${options.params.join('&')}`;
  } 
  return url;
}
export const createUrlSubmission = (options = {}) => { 
  const formUrl = createUrlForm(options);
  const submissionUrl = options.formId ? `submission/${options.submissionId}` : "";
  return submissionUrl ? `${formUrl}/${submissionUrl}` : formUrl;
}
export const createUrlSubmissionPreview = (options = {}) => { 
  let formUrl = createUrlForm(options);
  const submissionUrl = options.submissionId ? `submission/${options.submissionId}` : "";
  let url = submissionUrl ? `${formUrl}/${submissionUrl}/preview` : `${formUrl}/submission/preview`;
  let params = options.params || [];
  if (options.submissionIds) {
    params.push(`submissionIds=${options.submissionIds.join(',')}`);
  }
  if (options.templateFormId) {
    params.push(`templateFormId=${options.templateFormId}`);
  }
  if (options.templateSubmissionId) {
    params.push(`templateSubmissionId=${options.templateSubmissionId}`);
  }
  if (options.fieldName) {
    params.push(`fieldName=${options.fieldName}`);
  }
  if (params.length > 0) {
    url = `${url}?${params.join('&')}`;
  } 
  return url;
}

export const createUrlSubmissionXlsxBinding = (options = {}) => { 
  const formUrl = createUrlForm(options);
  let url = `${formUrl}/submission`;
  if (options.submissionId) { 
    url = `${url}/${options.submissionId}`;
  }
  url = `${url}/xlsxbinding`;
  let params = [];
  if (options.submissionIds) {
    params.push(`submissionIds=${options.submissionIds.join(',')}`);
  }
  if (options.templateFormId) {
    params.push(`templateFormId=${options.templateFormId}`);
  }
  if (options.templateSubmissionId) {
    params.push(`templateSubmissionId=${options.templateSubmissionId}`);
  }
  if (options.fieldName) {
    params.push(`fieldName=${options.fieldName}`);
  }
  if (params.length > 0) {
    url = `${url}?${params.join('&')}`;
  } 
  return url;
}

export const createUrlSubmissionSheet = (options = {}) => { 
  const formUrl = createUrlForm(options);
  return `${formUrl}/submission/sheet`;
}
export const createUrlSubmissionEdit = (options = {}) => { 
  const submissionUrl = createUrlSubmission(options);
  return `${submissionUrl}/edit`;
}
export const buildUrlMenu = (menu) => {
    return `${BASE_CONTEXT}/menu/${menu.id}`;
}
export const buildPageUrl = (page) => {
    const formId = _get(page, "data.form.formId");
    const type = _get(page, "data.type", "list");
    let url = `${BASE_CONTEXT}/form/${formId}/submission/${type}?pageId=${page._id}`;
    return url;
}
export const buildUrlFormSubmission = (menu) => {
  const formId = menu?.form;
  const action = menu?.action || 'submission/list';
  let url = `${BASE_CONTEXT}/form/${formId}/${action}`;
  if (menu.initialParam) {
    url = `${url}?${menu.initialParam}`;
  }
  return url;
}
