import axios from "axios";
import UserService from "./UserService";
import StorageService from "./storageService";

export const createAuthHeader = () => {
  const headers = {};
  let formioToken = UserService.getFormioToken();
  if (formioToken) {
    headers["x-jwt-token"] = formioToken;
  };
  let authToken = StorageService.get(StorageService.User.AUTH_TOKEN);
  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }
  return headers;            
}

class HelperServices {
  static getLocalDateAndTime(date) {
      return "";
  }
  static async postRequest(url, data, headers = null) {
      const authHeaders = createAuthHeader();
      return axios.post(url, data, {
          headers:  headers ? { ...authHeaders, ...headers } : authHeaders
      });
  }
  static async getRequest(url, headers = null) {
      const authHeaders = createAuthHeader();
      return axios.get(url, {
          headers:  headers ? { ...authHeaders, ...headers } : authHeaders
      });
  }
}

export default HelperServices;