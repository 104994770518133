import Components from 'formiojs/components/Components';
import ReactWrapperEditData from './editForm/ReactWrapper.edit.data';
import ReactWrapperEditDisplay from './editForm/ReactWrapper.edit.display';

const getReactComponents = () => {
    const components = [];
    for (let compName in Formio.Components.components) {
        let comp = Formio.Components.components[compName];
        //Support external react component only
        if (comp.renderer == 'react') {
            components.push({ value: compName, label: comp.ComponentName || compName });
        }
    }
    return components;
}
export default function (...extend) {
    let components = getReactComponents();
    const displayForm = [{
        key: 'innerComponent',
        data: {
            values: components,
        }
    }, ...ReactWrapperEditDisplay];
    const editForm = Components.baseEditForm([
        {
            key: 'display',
            components: displayForm,
        },
        {
            key: 'data',
            components: ReactWrapperEditData,
        },
        // {
        //     label: 'Templates',
        //     key: 'templates',
        //     weight: 5,
        //     components: EditGridEditTemplates
        // },
        // {
        //     key: 'validation',
        //     components: EditGridEditValidation
        // },
    ], ...extend);
    return editForm;
}
