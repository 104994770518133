/*
https://handsontable.com/docs/react-data-grid/events-and-hooks/#definition-for-source-argument
*/
import React, { useEffect, useRef, useState } from "react";
import { HotTable, HotColumn, HotColumnProps } from "@handsontable/react";
import { addClassesToRows, alignHeaders } from "./handsonhooks";
import { CellChange, ChangeSource, RowObject } from "handsontable/common";
import "handsontable/dist/handsontable.min.css";
import "pikaday/css/pikaday.css";
export interface IHandsonColumn extends HotColumnProps {
  header?: string,
  colName?: string,
  colIndex?: number,
  width?: number,
  
}
interface IHandsonTableProps {
  columns?: IHandsonColumn[],
  data?: RowObject[] | any[][],
  height?: number,
  afterChange?: (changes: CellChange[] | null, source: ChangeSource) => void,
  // formId?: string,
  // onSubmit: Function
}
const createHotcolumnProp = (column: IHandsonColumn, index?: number) => {
  let colProp: HotColumnProps = {
    data: column.colName || column.colIndex || index,
    readOnly: column.readOnly,
  };
  return colProp;
}
export default function HandsonTable(props: IHandsonTableProps) {
  const {
    columns,
    data,
    height,
    afterChange = (changes: CellChange[] | null, source: ChangeSource) => { }
  } = props;
  const renderColumn = (columnProps: IHandsonColumn, index: number) => {
    return (<HotColumn key={index}
      {...columnProps}
    />);
  }
  const headers = columns?.map((column) => column.header || "");
  const widths = columns?.map((column) => column.width);
  return (
    <HotTable
      data={data}
      height={height}
      colWidths={widths}
      colHeaders={headers}
      dropdownMenu={true}
      hiddenColumns={{
        indicators: true,
      }}
      contextMenu={true}
      multiColumnSorting={true}
      filters={true}
      rowHeaders={true}
      autoWrapCol={true}
      autoWrapRow={true}
      afterGetColHeader={alignHeaders}
      beforeRenderer={addClassesToRows}
      afterChange={afterChange}
      manualRowMove={true}
      licenseKey="non-commercial-and-evaluation"
    >
      { columns?.map(renderColumn) }
      {/* <HotColumn data={1} />
      <HotColumn data={2} />
      <HotColumn data={3} readOnly={true} />
      <HotColumn data={4} type="date" allowInvalid={false} />
      <HotColumn data={5} type="dropdown" source={["yes","no"]} />
      <HotColumn data={6} type="checkbox" className="htCenter" />
      <HotColumn data={7} type="numeric" /> */}
    </HotTable>
    )
}
