import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import bufferReducer from './slices/buffers';
import checkoutReducer from './slices/checkout';
import sessionReducer from './slices/session';
import metaReducer from './slices/meta';
// producers
import combinedReducers from './producers';
// ----------------------------------------------------------------------

const checkoutPersistConfig = {
  key: 'checkout',
  storage,
  keyPrefix: 'redux-',
};
const sessionPersistConfig = {
  key: 'session',
  storage,
  keyPrefix: 'redux-',
};
const bufferPersistConfig = {
  key: 'buffer',
  storage,
  keyPrefix: 'redux-',
};
const metaPersistConfig = {
  key: 'meta',
  storage,
  keyPrefix: 'redux-',
};
export const rootReducer = combineReducers({
  buffer: persistReducer(bufferPersistConfig, bufferReducer),
  checkout: persistReducer(checkoutPersistConfig, checkoutReducer),
  session: persistReducer(sessionPersistConfig, sessionReducer),
  meta: persistReducer(metaPersistConfig, metaReducer),
  ...combinedReducers
});
