import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
// sections
import { UserListView } from 'src/sections/user/view';
import { ACCESS_FULL, CUSTOM_SUBMISSION_ENABLE, CUSTOM_SUBMISSION_URL, DEFAULT_ACTIONS, Keycloak_Client, PageSizes } from 'src/constants';
import { useNavigate } from 'react-router';
import { FilterMatchMode } from 'primereact/api';
import { kcAdminClient } from 'src/services/KeycloakService';
import { fetchFormList, getSubmissions, loadFormio, queryFormio, saveCustomSubmission } from 'src/apiManager/services/FormServices';
import RoleRepresentation from '@keycloak/keycloak-admin-client/lib/defs/roleRepresentation';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { getCustomButtons } from 'src/components/Formio/components/helper';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { metadata, FORM_ACCESS } from 'src/config-global';
import { useDispatch } from 'react-redux';
import { saveSubmission } from '@formio/react';
import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep"
import cloneDeep from 'lodash/cloneDeep';
// ----------------------------------------------------------------------
const defaultActions = [...DEFAULT_ACTIONS];
export default function AccessPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const [allForms, setAllForms] = useState([]);
  const [roles, setRoles] = useState<RoleRepresentation[]>([]);
  const [selectedForm, setSelectedForm] = useState<Record<any,any>>();
  const [formio, setFormio] = useState();
  const [formAccessId, setFormAccessId] = useState();
  const [permSubmissionId, setPermSubmissionId] = useState();
  const [entities, setEntities] = useState<Record<any,any>[]>(_cloneDeep(defaultActions));
  const deleteConfirm = (record: Record<string, any>) => {
    confirmDialog({
        message: t('form.message.delete_confirm', record.data) as string,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () => onDeleteRecord(record)
    });
  };
  const onDeleteRecord = (record: Record<string, any>) => {
  }
  const onEditMenu = (rowData: Record<string, any>) => {
    navigate(`../${rowData.id}/edit`);
  }
  const createEntities = (actions: string[]) => { 
    let entities: Record<any, any>[] = cloneDeep(defaultActions);
    for (let ind = 0; ind < actions.length; ind++) {
      entities.push({ action: actions[ind] });
    }
    setEntities(entities);
  }
  const onChangeForm = async (event: DropdownChangeEvent) => {
    let formsflowForm = event.value;
    setSelectedForm(formsflowForm);
    if (formAccessId && formsflowForm?.formId) {
      let [submissions, form] = await Promise.all([
        getSubmissions(formAccessId, { query: { "data.form": formsflowForm.formId } }),
        loadFormio(formsflowForm.formId)
      ]).catch((err) => { console.error(err); return [] });
      if (form) {
        setFormio(form);
        let customButtons = getCustomButtons(form);
        let actionNames = customButtons.map(button => button.properties.action || button.key);
        let access = _get(submissions, "0.data.access");
        if (access) {
          try {
            setPermSubmissionId(_get(submissions, "0._id"));
            let storedEntities = JSON.parse(access);
            let setActions = new Set();
            storedEntities.forEach((entity: Record<any, any>) => setActions.add(entity.action) );
            let entities : Record<any, any>[] = [];
            defaultActions.forEach((item: Record<any, any>) => {
              if (!setActions.has(item.action)) {
                entities.push(cloneDeep(item));
              }
            });
            storedEntities.forEach((entity: Record<any, any>) => entities.push(entity));
            setEntities(entities);
          } catch (e) {
            console.error(e);
          }
        } else {
          createEntities(actionNames);
        }
      };
    } else {
      setPermSubmissionId(undefined);
      setEntities([...defaultActions]);
    }
    
  }
  const onChangePermission = (row: Record<any, any>, role: RoleRepresentation, checked?: boolean) => { 
    let newEntities = entities.map((entity) => {
      if (entity.action == row.action) {
        entity[role.name!] = checked;
      }
      return entity;
    });
    setEntities(newEntities);
  }
  const savePermission = async () => {
    let access = JSON.stringify(entities);
    const submission = {
      data: {
      form: selectedForm?.formId,
      access
      },
      metadata,
      state: "submitted",
      _id: permSubmissionId,
      _vnote: ""
    };
    const submitCallback = () => {
      if (toast.current) {
        toast.current.show({ severity: 'success', summary: 'Success', detail: t('form.message.form_access_create_successfully'), life: 3000 });
      }
    }
    if (formAccessId) {
      if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
        saveCustomSubmission(
          submission,
          formAccessId,
          submitCallback
        );
      } else {
        saveSubmission(
          "submission",
          submission,
          formAccessId,
          submitCallback
        )(dispatch);
      }
    } else if (toast.current) {
      toast.current.show({
        severity:'error', 
        summary: 'Error', detail:t('form.error.missing_form_access'), life: 3000});
    }
  }
  
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Dropdown id="forms" 
          showClear filter
          value={selectedForm}                           
          onChange={onChangeForm} 
          options={allForms} optionLabel="formName" 
          placeholder={t('menu.select_form')} 
        />
        <Button type="button" icon="pi pi-save" label={t("button.save")} onClick={savePermission} />
          {/* <div>
            <Button type="button" icon="pi pi-plus" label={t("button.create")} outlined onClick={openCreateForm} />
          </div>
          <div>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={searchTextInput} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </span>
          </div> */}
        </div>
    );
  };
  const setAllActions = (role: RoleRepresentation, bChecked?: boolean) => { 
    let name = role.name;
    if (name) {
      let newEntities: Record<any, any>[] = entities.map((entity) => ({...entity, [name!]: bChecked || false}));
      setEntities(newEntities);
    }
  }
  const renderRoleColumns = () => { 
    const createHeader = (role: RoleRepresentation) => { 
      let checked = true;
      let roleName = role.name;
      if (roleName) {
        for (let ind = 0; ind < entities.length; ind++) {
          if (_get(entities, [ind, roleName]) != true) {
            checked = false;
            break;
          }
        }
        return (<>
          <Checkbox checked={checked} onChange={(event) => setAllActions(role, event.checked)}/> {roleName}
        </>
      );
      }
    }
    let columns: ReactNode[] = [];
    for (let ind = 0; ind < roles.length; ind++) {
      const role = roles[ind];
      if (role.name?.startsWith("formsflow-") || role.name == ACCESS_FULL) {
        continue;
      }
      columns.push(<Column key={role.name} field={role.name} header={createHeader(role)} headerClassName="text-rotated"
          body={(action) => <Checkbox checked={action[role.name!]} onChange={(event) => onChangePermission(action, role, event.checked)}/>} />)
    }
    return columns;
  }
  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text />;
  const initiate = async () => { 
    let promises = [
      fetchFormList({ sortBy: "formName", sortOrder: "asc" }),
      kcAdminClient.clients.findOne({id: Keycloak_Client})
    ];
    const [forms, clients] = await Promise.all(promises);
    let roles = clients.length > 0 ? await kcAdminClient.clients.listRoles({
      id: clients[0].id
    }) : [];
    let formAccesses = forms.filter((form: Record<any, any>) => form.formName == FORM_ACCESS);
    if (formAccesses.length == 1) {
      setFormAccessId(formAccesses[0].formId);
    }
    setAllForms(forms);
    roles.sort((first, second) => {
      let firstName = first.name || '';
      let secondName = second.name || ''
      return firstName == secondName ? 0 : (firstName > secondName ? 1 : -1); 
    });
    setRoles(roles);
  }
  useEffect(() => { 
      initiate()
    },[]
  );
  return (
    <>
      <Helmet>
        <title> Admin: User permission</title>
      </Helmet>
      <Toast ref={toast} position="center"/>
      <ConfirmDialog />
      <div>
        Quản lý quyền truy cập
      </div>
      <DataTable header={renderHeader()} size="small"
          showGridlines={true}
          value={entities}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          paginatorLeft={paginatorLeft} 
          paginatorRight={paginatorRight} 
          tableStyle={{ minWidth: '50rem' }}>
        <Column field="action" header={t('common.action')} style={{ width: '10rem' }} align="left"></Column>
        { renderRoleColumns() }
      </DataTable>
    </>
  );
}
