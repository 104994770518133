import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TokenIcon from '@mui/icons-material/Token';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { FormsContext, IForm } from "src/contexts/forms";
import { buildMenus } from 'src/pages/admin/helper';
import { createNavMenus, menuBuilder } from "src/utils/formio";
import { ICONS } from 'src/utils/icons';
import { cloneDeep } from 'lodash';
import _get from 'lodash/get';
import { StorageService } from 'src/services';
import { ACCESS_FULL, STAFF_DESIGNER } from 'src/constants';
import { buildTree } from 'src/utils/tree';
import user from 'src/redux/producers/userDetailReducer';
// import { setMenuTree } from 'src/redux/slices/meta';

// ----------------------------------------------------------------------

const demo_dashboard = function(t: (arg: string) => string) {
  return {
    subheader: t('overview'),
    items: [
      { title: t('app'), path: paths.dashboard.root, icon: ICONS.dashboard },
      { title: t('ecommerce'), path: paths.dashboard.general.ecommerce, icon: ICONS.ecommerce },
      { title: t('analytics'), path: paths.dashboard.general.analytics, icon: ICONS.analytics },
      { title: t('banking'), path: paths.dashboard.general.banking, icon: ICONS.banking },
      { title: t('booking'), path: paths.dashboard.general.booking, icon: ICONS.booking },
      { title: t('file'), path: paths.dashboard.general.file, icon: ICONS.file },
    ],
  };
}

const demo_management = function(t: (arg: string) => string) {
  return {
    subheader: t('management'),
    items: [
      // USER
      {
        title: t('user'),
        path: paths.dashboard.user.root,
        icon: ICONS.user,
        children: [
          { title: t('profile'), path: paths.dashboard.user.root },
          { title: t('cards'), path: paths.dashboard.user.cards },
          { title: t('list'), path: paths.dashboard.user.list },
          { title: t('create'), path: paths.dashboard.user.new },
          { title: t('edit'), path: paths.dashboard.user.demo.edit },
          { title: t('account'), path: paths.dashboard.user.account },
        ],
      },

      // PRODUCT
      {
        title: t('product'),
        path: paths.dashboard.product.root,
        icon: ICONS.product,
        children: [
          { title: t('list'), path: paths.dashboard.product.root },
          { title: t('details'), path: paths.dashboard.product.demo.details },
          { title: t('create'), path: paths.dashboard.product.new },
          { title: t('edit'), path: paths.dashboard.product.demo.edit },
        ],
      },

      // ORDER
      {
        title: t('order'),
        path: paths.dashboard.order.root,
        icon: ICONS.order,
        children: [
          { title: t('list'), path: paths.dashboard.order.root },
          { title: t('details'), path: paths.dashboard.order.demo.details },
        ],
      },

      // INVOICE
      {
        title: t('invoice'),
        path: paths.dashboard.invoice.root,
        icon: ICONS.invoice,
        children: [
          { title: t('list'), path: paths.dashboard.invoice.root },
          { title: t('details'), path: paths.dashboard.invoice.demo.details },
          { title: t('create'), path: paths.dashboard.invoice.new },
          { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
        ],
      },

      // BLOG
      {
        title: t('blog'),
        path: paths.dashboard.post.root,
        icon: ICONS.blog,
        children: [
          { title: t('list'), path: paths.dashboard.post.root },
          { title: t('details'), path: paths.dashboard.post.demo.details },
          { title: t('create'), path: paths.dashboard.post.new },
          { title: t('edit'), path: paths.dashboard.post.demo.edit },
        ],
      },

      // JOB
      {
        title: t('job'),
        path: paths.dashboard.job.root,
        icon: ICONS.job,
        children: [
          { title: t('list'), path: paths.dashboard.job.root },
          { title: t('details'), path: paths.dashboard.job.demo.details },
          { title: t('create'), path: paths.dashboard.job.new },
          { title: t('edit'), path: paths.dashboard.job.demo.edit },
        ],
      },

      // TOUR
      {
        title: t('tour'),
        path: paths.dashboard.tour.root,
        icon: ICONS.tour,
        children: [
          { title: t('list'), path: paths.dashboard.tour.root },
          { title: t('details'), path: paths.dashboard.tour.demo.details },
          { title: t('create'), path: paths.dashboard.tour.new },
          { title: t('edit'), path: paths.dashboard.tour.demo.edit },
        ],
      },

      // FILE MANAGER
      {
        title: t('file_manager'),
        path: paths.dashboard.fileManager,
        icon: ICONS.folder,
      },

      // MAIL
      {
        title: t('mail'),
        path: paths.dashboard.mail,
        icon: ICONS.mail,
        info: <Label color="error">+32</Label>,
      },

      // CHAT
      {
        title: t('chat'),
        path: paths.dashboard.chat,
        icon: ICONS.chat,
      },

      // CALENDAR
      {
        title: t('calendar'),
        path: paths.dashboard.calendar,
        icon: ICONS.calendar,
      },

      // KANBAN
      {
        title: t('kanban'),
        path: paths.dashboard.kanban,
        icon: ICONS.kanban,
      },
    ],
  };
}

const demo_others = function(t: (arg: string) => string) {
  return {
    subheader: t(t('other_cases')),
    items: [
      {
        // default roles : All roles can see this entry.
        // roles: ['user'] Only users can see this item.
        // roles: ['admin'] Only admin can see this item.
        // roles: ['admin', 'manager'] Only admin/manager can see this item.
        // Reference from 'src/guards/RoleBasedGuard'.
        title: t('item_by_roles'),
        path: paths.dashboard.permission,
        icon: ICONS.lock,
        roles: ['admin', 'manager'],
        caption: t('only_admin_can_see_this_item'),
      },
      {
        title: t('menu_level'),
        path: '#/dashboard/menu_level',
        icon: ICONS.menuItem,
        children: [
          {
            title: t('menu_level_1a'),
            path: '#/dashboard/menu_level/menu_level_1a',
          },
          {
            title: t('menu_level_1b'),
            path: '#/dashboard/menu_level/menu_level_1b',
            children: [
              {
                title: t('menu_level_2a'),
                path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
              },
              {
                title: t('menu_level_2b'),
                path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
                children: [
                  {
                    title: t('menu_level_3a'),
                    path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
                  },
                  {
                    title: t('menu_level_3b'),
                    path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: t('item_disabled'),
        path: '#disabled',
        icon: ICONS.disabled,
        disabled: true,
      },
      {
        title: t('item_label'),
        path: '#label',
        icon: ICONS.label,
        info: (
          <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
            NEW
          </Label>
        ),
      },
      {
        title: t('item_caption'),
        path: '#caption',
        icon: ICONS.menuItem,
        caption:
          'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
      },
      {
        title: t('item_external_link'),
        path: 'https://www.google.com/',
        icon: ICONS.external,
      },
      {
        title: t('blank'),
        path: paths.dashboard.blank,
        icon: ICONS.blank,
      },
    ],
  };
}
const designer = function(t: (arg: string) => string) {
  return {
    subheader: t('menu.designer'),
    items: [
      //Form
      {
        title: t('menu.form'),
        path: paths.designer.formRoot,
        icon: ICONS.kanban,
        children: [
          { title: t('list'), path: paths.designer.formRoot },
          { title: t('create'), path: paths.designer.formCreate }
        ],
      },
    ]
  };
}
const administrator = function(t: (arg: string) => string) {
  return {
    subheader: t('Administrator'),
    items: [
      {
        title: t('menu.permission'),
        path: paths.permission.root,
        icon: ICONS.user,
        children: [
          { title: t('menu.group'), path: paths.permission.group },
          { title: t('menu.role'), path: paths.permission.role },
          { title: t('menu.access'), path: paths.permission.access },
        ]
      },
      // User
      {
        title: t('menu.user'),
        path: paths.dashboard.user.root,
        icon: ICONS.user,
        children: [
          { title: t('profile'), path: paths.user.root },
          { title: t('cards'), path: paths.user.cards },
          { title: t('list'), path: paths.user.list },
          { title: t('create'), path: paths.user.new },
          { title: t('account'), path: paths.user.account },
        ],
      },
      // {
      //   title: t('menu.group'),
      //   path: paths.group.root,
      //   icon: ICONS.folder,
      //   children: [
      //     { title: t('list'), path: paths.group.list },
      //   ],
      // },
      {
        title: t('menu.menu'),
        path: paths.menu.root,
        icon: ICONS.menuItem,
        children: [
          { title: t('list'), path: paths.menu.list },
          { title: t('create'), path: paths.menu.create },
        ],
      },
      // {
      //   title: t('menu.formactions'),
      //   path: paths.formactions.root,
      //   icon: ICONS.menuItem,
      //   children: [
      //     { title: t('list'), path: paths.formactions.list },
      //     { title: t('create'), path: paths.formactions.create },
      //   ],
      // },
    ]
  };
}

// const menuBuilder = function(menuTree: any[] | undefined) {
//   const createMenuItem = (menu: any, root: boolean = false) => {
//     let children = menu.children?.map((child: any) => createMenuItem(child));
//     return root ? {
//       subheader: menu.data.title,
//       path: buildUrlMenu(menu),
//       items: children
//     } : 
//      ((children && children.length > 0) ? {
//         title: menu.data.title,
//         path: buildUrlMenu(menu),
//         children
//       } : {
//         title: menu.data.title,
//         path: buildUrlMenu(menu)
//       })
//   }
//   const menuRoutes = menuTree ? menuTree.map(menu => createMenuItem(menu, true)) : [];
//   return menuRoutes;
// }
export function useNavData() {
  const { t } = useLocales();  
  const dispatch = useDispatch();
  const menus = useSelector((state: Record<string, any>) => state.meta.submissions.menu);
  const userRoles = useSelector((state: Record<string, any>) => state.user.roles);
  const formAccesses = useSelector((state: Record<string, any>) => state.meta.formAccesses);
  const [menuTree, setMenuTree] = useState<Record<string, any>[]>();
  //const menuTree = useSelector((state: Record<string, any>) => state.meta.menuTree);
  
  const buildMenuCallback = (err: any, menuTree: Record<string,any>[]) => {
    //Clean empty menu
    const cleanNodes = (nodes: Record<string,any>[]) => {
      if (nodes.length == 0) return nodes;
      return nodes.filter((node: Record<string, any>) => {
        let page = _get(node, "data.page");
        let form = _get(node, "data.form");
        let action = _get(node, "data.action");
        if (page || (form && action)) {
            //Leaf
            return true;
        }
        let children = cleanNodes(node.children);
        if (children.length > 0) {
            node.children = children;
            return true;
        }
        return false;
      });
    }
    const cleanMenuTree = cleanNodes(menuTree);
    setMenuTree(cleanMenuTree);
  }
  useEffect(() => {
    if (menus?.length > 0 && userRoles?.length > 0) {
      buildMenus(cloneDeep(menus), cloneDeep(userRoles), cloneDeep(formAccesses), buildMenuCallback)
    }
  }, [menus, userRoles, formAccesses, dispatch]);
  
  const data = useMemo(
    () => {
      const dynamicMenus = menuBuilder(menuTree);
      const adminMenus = administrator(t);
      let dynamicAdmin = null;
      for (let i = 0; i < dynamicMenus.length; i++) {
        if (dynamicMenus[i].subheader == adminMenus.subheader) {
          dynamicAdmin = dynamicMenus[i];
          break;
        }
      }
      if (userRoles.includes(STAFF_DESIGNER) || userRoles.includes(ACCESS_FULL)) {
        if (dynamicAdmin) {
          let mapAdminMenus = dynamicAdmin.items.reduce((map: Record<string, any>, currentValue: any) => {
            map[currentValue.title] = currentValue;
            return map;
          }, {});
          for (let i = 0; i < adminMenus.items.length; i++) {
            let item = adminMenus.items[i];
            if (!mapAdminMenus[item.title]) {
              dynamicAdmin.items.push(item);
            }
          }
        } else {
          dynamicMenus.unshift(adminMenus);
        }
        if (userRoles.includes(STAFF_DESIGNER)) {
          return [
            designer(t),
            ...dynamicMenus
          ];
        } else {
          return dynamicMenus;
        }
      } else {
        return dynamicMenus;
      }
    },
    [t, menuTree]
  );
  return data;
}
