import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import _get from "lodash/get";
import { Utils } from "@formio/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { createUrlSubmissionList } from "src/utils/meta";
import { actionHandler } from "src/components/Formio/components/helper";
import { IFormio, ISubmission, IComponent, IFormAction, IButtonOption } from "src/types/form";
import { IContext } from "src/types/common";
import { useSelector } from "react-redux";
import {
  OPERATIONS,
  CLIENT,
  CUSTOM_SUBMISSION_URL,
  CUSTOM_SUBMISSION_ENABLE,
  STAFF_DESIGNER,
  STAFF_REVIEWER,
  MULTITENANCY_ENABLED,
  ACTION_READ_ALL,
  ACTION_READ_OWN,
  ACTION_UPDATE_ALL,
  ACTION_UPDATE_OWN,
  ACTION_DELETE_ALL,
  ACTION_DELETE_OWN,
  ACCESS_FULL,
  ACTION_PREVIEW_FORM,
  ACTION_XLSX_BINDING
} from "src/constants";
const createActionContext = (formAction: ISubmission,
  selectedSubmissions: ISubmission[] = [],
  button: IComponent,
  context: IContext) => { 
  const multiplicity = _get(formAction, "data.multiplicity");
  let properties = _get(formAction, "data.parameters", []).reduce((total: Record<string, any>, param: Record<string, any>) => {
    total[param.name] = param.value;
    return total;
  }, {})
  if (button?.properties) {
    properties = { ...properties, ...button.properties };
  }
  const actionContext = {
    actionId: formAction._id,
    navigate: context.navigate,
    handleOpenDialog: context.handleOpenDialog,
    form: context.form,
    refForm: context.refForm || context.form,
    refFormId: context.refFormId || context.form?._id,
    refSubmission: context.refSubmission,
    properties,
    submission: context.submission,
    submissionIds: selectedSubmissions.map((item)=> item._id)
  }
  return (multiplicity == 'single' && selectedSubmissions.length == 1) ?
    { ...actionContext, submissionId: selectedSubmissions[0]._id, refSubmissionId: selectedSubmissions[0]._id}
    : actionContext;
}
export const createButton = (options: IButtonOption) => (<Button type="button"
  key={options.key}
  size="small"
  icon={typeof options.icon == 'function' ? options.icon() : options.icon}
  label={options.label}
  text raised
  rounded={options.rounded}
  className='mr-2'
  severity={options.severity}
  onClick={options.onClick || (() => { }) } />);

export const handleCustomAction = (event: React.MouseEvent, formAction: ISubmission, button: IComponent, context: IContext) => {
    const multiplicity = _get(formAction, "data.multiplicity", "multiple");
    const actionLabel = button?.label || context.t?(_get(formAction, "data.i18nKey")) : _get(formAction, "data.label");
    const apiCall = _get(formAction, "data.apiCall");
    const submissions = context.submissions ? context.submissions : (context.submission ? [context.submission] : []);
    if (submissions.length == 0) {
      // context.toast.current.show({ severity: 'info', summary: 'Message', detail: 'Please select some records end try again!', life: 3000 });
      const detailLabel = context.t ? context.t("message.no_selection", { action: actionLabel }) : `Please select some record to perform action ${actionLabel}`;
      context.toast?.current?.show({ severity: 'info', summary: 'Message', detail: detailLabel, life: 3000 });
    } else if (multiplicity == 'single') { 
      if (submissions.length > 1) {
        const detailLabel = context.t ? context.t("message.require_single_selection", { action: actionLabel }) : `Please select only one record to preform the action ${actionLabel}.`;
        context.toast?.current?.show({ severity: 'info', summary: 'Message', detail: detailLabel, life: 3000 });
      } else {
        const actionContext = createActionContext(formAction, submissions, button, context);
        if (apiCall) {
          confirmPopup({
            target: event.target as HTMLElement,
            message: `Are you sure you want to proceed action ${button.label} for ${submissions.length} selected records?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => actionHandler(formAction, actionContext)
          });
        } else {
          actionHandler(formAction, actionContext)
        }
      }
    } else {
      const actionContext = createActionContext(formAction, submissions, button, context);
      let label = button?.label || context.t?(_get(formAction, "data.i18nKey")) : _get(formAction, "data.label");
      if (apiCall) {
        confirmPopup({
          target: event.target as HTMLElement,
          message: `Are you sure you want to proceed action ${button.label} for ${submissions.length} selected records?`,
          icon: 'pi pi-exclamation-triangle',
          accept: () => actionHandler(formAction, actionContext)
        });
      } else {
        actionHandler(formAction, actionContext)
      }
    }
}

interface ISubmissionActionsProps {
  page?: string,
  form: IFormio,
  submission?: ISubmission,
  formActions?: Record<string, any>[],
  onEdit?: () => void,
  onDelete?: () => void,
  handleOpenDialog?: () => void,
  actionHandler?: () => void,
  handleSubmit?: (submission: ISubmission, action: any, context: Record<string, any>) => void,
  handlePrint?: Function,
  onInitialized?: () => void,
  onCustomEvent?: () => void,
  onFormChange?: () => void,
}

const SubmissionActions = React.memo((props: ISubmissionActionsProps) => {
  const {
    page,
    form,
    formActions = [],
    submission,
    actionHandler,
    handlePrint = () => { },
    handleOpenDialog = () => {},
    onEdit = () => { },
    onDelete = () => { }
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mapButtons, setMapButtons] = useState<Record<string, IComponent>>({});
  const user = useSelector((state: Record<string, any>) => state.user);
  const formAccesses = useSelector((state: Record<string, any>) => state.meta.formAccesses);
  const [formPerms, setFormPerms] = useState<Record<string, boolean>>({});
  const toast = useRef<Toast>(null);
  
  useEffect(() => { 
    if (form) {
      const btnActions: Record<string, IComponent> = {};
      Utils.eachComponent(form?.components, (component: IComponent) => {
        if (component.type == 'button' && component.action == 'custom') {
          btnActions[component.key] = component;
        }
      });
      setMapButtons(btnActions);
      const access = formAccesses[form._id];
      if (access && user?.roles) {
        let formPerms = access?.reduce((accumulator: Record<string, any>, item: Record<string, any>) => {
          for (let ind = 0; ind < user.roles.length; ind++) {
            if (item[user.roles[ind]]) {
              accumulator[item.action] = true;
              break;
            }
          }
          return accumulator;
        }, {});
        setFormPerms(formPerms);
      }
    }
  }, [form, user, formAccesses]);
  const mapActions = formActions.reduce((map, formAction) => { 
    const actionName = _get(formAction, "data.actionName");
    map[actionName] = formAction;
    return map;
  }, {});
  
  const showButton = (formAction: ISubmission) => { 
    let formOpen = _get(formAction, 'data.formOpen', '');
    let apiCall = _get(formAction, 'data.apiCall', '');
    return formOpen || apiCall;
  }
  const openList = () => { 
    const url = createUrlSubmissionList({ formId: form._id });
    navigate(url);
  }
  const onClickButton = async (event: React.MouseEvent<HTMLButtonElement>, actionName: string) => { 
    const context: IContext = {
      form,
      t,
      navigate,
      handleOpenDialog,
      submission,
      toast
    }
    const action = mapActions[actionName];
    let button = mapButtons[actionName];
    const uiAction = _get(action, "data.uiAction");
    if (uiAction == ACTION_PREVIEW_FORM) {
      handlePrint();
    } else if (uiAction == ACTION_XLSX_BINDING) {

    } else {
      handleCustomAction(event, action, button, context);
    }
  }
  const renderStartContent = () => {
    let buttons = [];  
    // buttons.push(
    //   createButton({
    //     key: "backtolist",
    //     label: t('button.openlist'),
    //     icon: "pi pi-menu",
    //     onClick: openList
    //   })
    // )
    const context: IContext = {
      form,
      t,
      navigate,
      handleOpenDialog,
      submission,
      toast
    }
    //Custom actions
    for (let actionName in mapActions) {
      const action = mapActions[actionName];
      let button = mapButtons[actionName];
      let key = button?.key || actionName
      let label = button?.label || _get(action, "data.label") || t(_get(action, "data.i18nKey"));
      buttons.push(createButton({
        key,
        label,
        onClick: (event) => onClickButton(event, actionName)
      }));
    }
    return buttons;
  }
  const renderEndContent = () => {
    let buttons = [];  
    //Edit 
    const isOwnSubmission = false;  //submission?.owner == user;
    if (page == "view" && user) {
      const allowAll = user.roles.includes(ACCESS_FULL) || user.roles.includes(STAFF_DESIGNER);
      if (allowAll || formPerms[ACTION_UPDATE_ALL] || (formPerms[ACTION_UPDATE_OWN] && isOwnSubmission)) {
        buttons.push(createButton({
          key: "edit",
          label: t('button.edit'),
          icon: "pi pi-pencil",
          onClick: onEdit
        }));
      }
      //Delete
      if (allowAll || formPerms[ACTION_DELETE_ALL] || (formPerms[ACTION_DELETE_OWN] && isOwnSubmission)) {
        buttons.push(createButton({
          key: "delete",
          label: t('button.delete'),
          icon: "pi pi-trash",
          severity: "danger",
          onClick: onDelete
        }))
      }
    }
    // const components = FormioUtils.flattenComponents(form?.components);
    // for(var field in components) {
    //   const comp = components[field];
    //   const formAction = mapActions[comp.properties.action];
    //     if (comp.type == 'button' && comp.action == 'custom' && showButton(formAction)) {
    //         buttons.push(<Button key={comp.key} label={comp.label} 
    //           disabled={!mapActions[comp.properties.action] || enabled == 'false' }
    //           onClick={() => actionHandler(formAction, { properties: comp.properties })} className="mr-1"/>)
    //     }
    // }
    return buttons;
  };  
  const startActions = renderStartContent();
  const endActions = renderEndContent();
  return startActions.length > 0 || endActions.length > 0
    ? (<>
      <Toast ref={toast} position="top-center"/>
      <Toolbar start={startActions} end={endActions} className="mb-2" />
      </>)
      : null;
});
export default SubmissionActions;