export default [
  {
    type: 'select',
    input: true,
    weight: 0,
    tooltip: 'The inner component.',
    key: 'innerComponent',
    defaultValue: '',
    label: 'Inner component',
    dataSrc: 'values',
  },
];
