import PropTypes from 'prop-types';
// components
import { SplashScreen } from 'src/components/loading-screen';
//
import { FormsContext } from './forms-context';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
export function FormsConsumer({ children }: Props) {
  return (
    <FormsContext.Consumer>
      {(forms) => (forms.isLoading ? <SplashScreen /> : children)}
    </FormsContext.Consumer>
  );
}

FormsConsumer.propTypes = {
  FormsConsumer: PropTypes.node,
};
