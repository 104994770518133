import React, { useEffect , useState, useMemo} from "react";
import { connect, useDispatch, useSelector} from "react-redux";
import {
  selectRoot,
  resetSubmissions,
  saveSubmission,
  Form,
  selectError,
  Errors,
} from "@formio/react";
import cloneDeep from "lodash/cloneDeep";
import { useNavigate, useParams } from "react-router-dom";
import { default as formio_resourceBundles } from "src/locales/formio";
import Loading from "src/components/containers/Loading";
import _get from "lodash/get";
import {
  setFormSubmissionError,
  setFormSubmissionLoading,
} from "src/actions/formActions";
import SubmissionError from "src/components/containers/SubmissionError";
import { getUserRolePermission } from "src/helper/user";
import {
  CLIENT,
  CUSTOM_SUBMISSION_URL,
  CUSTOM_SUBMISSION_ENABLE,
  MULTITENANCY_ENABLED,
} from "src/constants";
import {
  CLIENT_EDIT_STATUS,
  UPDATE_EVENT_STATUS,
  getProcessDataReq,
} from "src/constants/applicationConstants";
import { updateApplicationEvent } from "src/apiManager/services/applicationServices";
//import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import { Translation, useTranslation } from "react-i18next";
import { saveCustomSubmission } from "src/apiManager/services/FormServices";
import { cleanupSubmissionData } from "../../../../Formio/components/helper";
import { BASE_CONTEXT } from "src/config-global";
const Edit = React.memo((props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.user.lang);
  const { formId, submissionId } = useParams();
  const {
    hideComponents,
    onSubmit,
    options,
    errors,
    onFormSubmit,
    onCustomEvent,
    onInitialized = () => {},
    onFormChange = () => {},
    form: { form, isActive: isFormActive },
    submission: { submission, isActive: isSubActive, url },
  } = props;

  const [updatedSubmissionData, setUpdatedSubmissionData] = useState({});
  const applicationStatus = useSelector(
    (state) => state.applications.applicationDetail?.applicationStatus || ""
  );
  const userRoles = useSelector((state) => {
    return selectRoot("user", state).roles;
  });
  const applicationDetail = useSelector(
    (state) => state.applications.applicationDetail
  );

  const isFormSubmissionLoading = useSelector(
    (state) => state.formDelete.isFormSubmissionLoading
  );
  const tenantKey = useSelector((state) => state.tenants?.tenantId);
  const customSubmission = useSelector(
    (state) => state.customSubmission?.submission || {}
  );
  const redirectUrl = MULTITENANCY_ENABLED ? `${BASE_CONTEXT}/tenant/${tenantKey}/` : `${BASE_CONTEXT}/`;
  useEffect(() => {
    // Check if the application is in "Resubmit" or "Awaiting Acknowledgement" status (old approach and it’s kept to have backward compatibility)
    // In the new approach, we will use the "isResubmit" key
    if (applicationStatus && !onFormSubmit) {
      if (
        getUserRolePermission(userRoles, CLIENT) &&
        !CLIENT_EDIT_STATUS.includes(applicationStatus) &&
        !applicationDetail.isResubmit
      ) {
        // Redirect the user to the submission view page if not allowed to edit
        navigate(`/form/${formId}/submission/${submissionId}`);
      }
    }
  }, [
    applicationStatus,
    userRoles,
    dispatch,
    submissionId,
    formId,
    onFormSubmit,
  ]);
  const updatedSubmission = useMemo(()=>{
    if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
      return cloneDeep(customSubmission);
    } else {
      return cloneDeep(submission);
    }
  },[customSubmission,submission]);

  if (isFormActive || (isSubActive && !isFormSubmissionLoading) || !updatedSubmission?.data) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="main-header">
        <SubmissionError
          modalOpen={props.submissionError.modalOpen}
          message={props.submissionError.message}
          onConfirm={props.onConfirm}
        ></SubmissionError>
        <h4 className="text-truncate">{form.title}</h4>
      </div>
      <Errors errors={errors} />
        <div className="ml-4 mr-4">
          <Form
            form={form}
            submission={isFormSubmissionLoading ? updatedSubmissionData : updatedSubmission}
            url={url}
            hideComponents={hideComponents}
            onSubmit={(submission) => {
                let components = FormioUtils.flattenComponents(form?.components);
                let extraParams = _get(components['submit'], "properties");
                setUpdatedSubmissionData(submission);
                const submissionPayload = { ...submission, data: cleanupSubmissionData(submission.data) };
                submissionPayload.data.editor = props.user?.name;
                onSubmit(
                  submissionPayload,
                  applicationDetail,
                  onFormSubmit,
                  form._id,
                  navigate,
                  redirectUrl,
                  extraParams
                );
              }
            }
            options={{
              ...options,
              i18n: formio_resourceBundles,
              language: lang,
            }}
            onInitialized={() => onInitialized(form, dispatch)}
            //onChange={(event) => onFormChange(form, event, dispatch)}
            onCustomEvent={onCustomEvent}
          />
        </div>
    </div>
  );
});

Edit.defaultProps = {
  onCustomEvent: () => {},
  // onInitialized,
  // onFormChange
};

const mapStateToProps = (state) => {
  const form = selectRoot("form", state);
  const submission = selectRoot("submission", state);
  return {
    user: state.user.userDetail,
    form: form ? cloneDeep(form) : {},
    submission: submission ? cloneDeep(submission) : {},
    isAuthenticated: state.user.isAuthenticated,
    errors: [selectError("form", state), selectError("submission", state)],
    options: {
      noAlerts: false,
      i18n: {
        en: {
          error: (
            <Translation>
              {(t) => t("Please fix the errors before submitting again.")}
            </Translation>
          ),
        },
      },
    },
    submissionError: selectRoot("formDelete", state).formSubmissionError,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (
      submission,
      applicationDetail,
      onFormSubmit,
      formId,
      navigate,
      redirectUrl,
      extraParams
    ) => {
      dispatch(setFormSubmissionLoading(true));
      const callBack = (err, submission) => {
        if (!err) {
          if (
            UPDATE_EVENT_STATUS.includes(applicationDetail.applicationStatus) ||
            applicationDetail.isResubmit
          ) {
            const data = getProcessDataReq(applicationDetail,submission.data);
            dispatch(
              updateApplicationEvent(applicationDetail.id, data, () => {
                dispatch(resetSubmissions("submission"));
                dispatch(setFormSubmissionLoading(false));
                if (onFormSubmit) {
                  onFormSubmit();
                } else {
                  toast.success(
                    <Translation>{(t) => t("Submission Saved")}</Translation>
                  );
                  navigate(
                    // eslint-disable-next-line max-len
                    //`${redirectUrl}form/${formId}/submission/${submission._id}`
                    `${redirectUrl}form/${formId}/submission/list`
                  );
                  
                }
              })
            );
          } else {
            dispatch(resetSubmissions("submission"));
            dispatch(setFormSubmissionLoading(false));
            if (onFormSubmit) {
              onFormSubmit();
            } else {
              toast.success(
                <Translation>{(t) => t("Submission Saved")}</Translation>
              );
                navigate(
                  // eslint-disable-next-line max-len
                  //`${redirectUrl}form/${formId}/submission/${submission._id}/edit`
                  `${redirectUrl}form/${formId}/submission/list`
                );
            }
          }
        } else {
          dispatch(setFormSubmissionLoading(false));
          const ErrorDetails = {
            modalOpen: true,
            message: (
              <Translation>
                {(t) => t("Submission cannot be done.")}
              </Translation>
            ),
          };
          toast.error(
            <Translation>{(t) => t("Error while Submission.")}</Translation>
          );
          dispatch(setFormSubmissionError(ErrorDetails));
        }
      };
      if (extraParams?.customAction) {
        submission.extraParams = extraParams;
        saveCustomSubmission(
          submission,
          formId,
          callBack,
          extraParams?.customAction
        );
      } else {
        if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
          saveCustomSubmission(
            submission,
            onFormSubmit ? formId : formId,
            callBack
          );
        }else{
          dispatch(
            saveSubmission(
              "submission",
              submission,
              onFormSubmit ? formId : formId,
              callBack
            )
          );
        }
      }
    },
    onConfirm: () => {
      const ErrorDetails = { modalOpen: false, message: "" };
      dispatch(setFormSubmissionError(ErrorDetails));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
