/*
https://handsontable.com/docs/react-data-grid/events-and-hooks/#definition-for-source-argument
Binding a submission into a handsontable using xlsx template
*/
import React, { useEffect, useRef, useState } from "react";
import { HotTable, HotColumn, HotColumnProps } from "@handsontable/react";
import { addClassesToRows, alignHeaders } from "./handsonhooks";
import Handsontable from "handsontable";
import { CellChange, ChangeSource, RowObject } from "handsontable/common";
import "handsontable/dist/handsontable.min.css";
import "pikaday/css/pikaday.css";
import HotTableClass from "@handsontable/react/hotTableClass";
import { IFormio, ISubmission } from "src/types/form";
import { Range, WorkBook, WorkSheet, utils } from "xlsx";
import { DetailedSettings, Settings as MergeCellSettings} from "handsontable/plugins/mergeCells";
import { Paper } from "@mui/material";
import Handlebars from "handlebars";
import { CellProperties, CellSettings } from "handsontable/settings";
import Core from "handsontable/core";
import { handsontablePlugin, imageRenderer, qrcodeRenderer, xlsxStylesRenderer }  from "src/utils/handsontable-plugins";
import { useSelector } from "react-redux";
import moment from "moment";
import { Settings as CustomBorderSettings} from "handsontable/plugins/customBorders";
//import _template from "lodash/template";
// Handlebars.registerHelper("math", function (lvalue: string,
//   operator: string,
//   rvalue: string,
//   options: Record<string, any>) {
//     let lnumber = parseFloat(lvalue);
//     let rnumber = parseFloat(rvalue);
        
//     return {
//         "+": lnumber + rnumber,
//         "-": lnumber - rnumber,
//         "*": lnumber * rnumber,
//         "/": lnumber / rnumber,
//         "%": lnumber % rnumber
//     }[operator];
// });
const CHARACTER_WIDTH = 8.5;
export interface IHandsonColumn extends HotColumnProps {
  header?: string,
  colName?: string,
  colIndex?: number,
  width?: number,
  
}
interface IXlsxSubmissionProps {
  form: IFormio | undefined,
  submission?: ISubmission,
  templateSubmission?: ISubmission,
  xlsxTemplate?: WorkBook,
  columns?: IHandsonColumn[],
  data?: RowObject[] | any[][],
  height?: number,
  afterChange?: (changes: CellChange[] | null, source: ChangeSource) => void,
  // formId?: string,
  // onSubmit: Function
}
type ColWidth = number | undefined;
export type Ref = HotTableClass;
const createHotcolumnProp = (column: IHandsonColumn, index?: number) => {
  let colProp: HotColumnProps = {
    data: column.colName || column.colIndex || index,
    readOnly: column.readOnly,
  };
  return colProp;
}
const registerHandleBarHelpers = () => { 
  // Handlebars.registerHelper("iterate", function (context, field, options) {
  //   var ret: any[] = [];
  //   for (var i = 0, j = context.length; i < j; i++) {
  //     ret.push(options.fn(context[i]));
  //   }

  //   return ret;
  // });
  Handlebars.registerHelper("aggsum", function (context, options) {
    var ret: number = 0;
    for (var i = 0, j = context.length; i < j; i++) {
      let elmValue = options.fn(context[i]);
      try {
        let value = parseFloat(elmValue);
        ret += value;
      } catch (e) {
        console.error(e);
      }
    }
    return ret;
  });
  Handlebars.registerHelper("mul", function (lvalue: string,
    rvalue: string,
    options: Record<string, any>) {
      let lnumber = parseFloat(lvalue);
      let rnumber = parseFloat(rvalue);
    return !isNaN(lnumber) && !isNaN(rnumber) ? lnumber * rnumber : 0;
  });
  Handlebars.registerHelper("qrcode", function (value: string,
    options: Record<string, any>) {
     
    return "#qrcode#" + value;
  });
}
handsontablePlugin.registerRenderers();
// Handsontable.renderers.registerRenderer('xlsxStylesRenderer', xlsxStylesRenderer);
// Handsontable.renderers.registerRenderer('imageRenderer', imageRenderer);
// Handsontable.renderers.registerRenderer('qrcodeRenderer', qrcodeRenderer);

const XlsxSubmission = React.forwardRef<Ref, IXlsxSubmissionProps>((props, ref) => { 
  const {
    xlsxTemplate,
    submission,
    templateSubmission,
    columns,
    height,
    afterChange = (changes: CellChange[] | null, source: ChangeSource) => { }
  } = props;
  const userDetail = useSelector((state: Record<string, any>) => state.user.userDetail);
  const [data, setData] = useState<any[][]>();
  const [widths, setWidths] = useState<ColWidth[]>();
  const [mergeCells, setMergeCells] = useState<MergeCellSettings>();
  const [customBorders, setCustomBorders] = useState<CustomBorderSettings>();
  const [styledCells, setStyledCells] = useState<CellSettings[]>([]);
  const [rowHeaders, setRowHeaders] = useState<boolean>(false)
  const [rowHeights, setRowHeights] = useState<(number | undefined )[]>([])
  const renderColumn = (columnProps: IHandsonColumn, index: number) => {
    return (<HotColumn key={index}
      {...columnProps}
    />);
  }
  const headers = columns?.map((column) => column.header || "");
  /*
   * Bind submission to sheet template.
   * Return 2d Data and styledCell arrays
   */
  const bindData = (submission: ISubmission, sheet: WorkSheet) => { 
    let separator = `__${submission._id}__`;
    let header = 1, range;
    if (sheet["!ref"]) {
      range = utils.decode_range(sheet["!ref"]);
    }
    const parserOptions = {
      cellDates: true,
      UTC: true,
      header,
      range
    };
    const sheetData: any[][] = utils.sheet_to_json(sheet, parserOptions);
    const data: any[][] = [];
    const styledCells: CellSettings[] = [];
    registerHandleBarHelpers();
    Handlebars.registerHelper("iterate", function(context, options) {
      var ret: any[] = [];
      for (var i = 0, j = context.length; i < j; i++) {
        ret.push(options.fn(context[i]));
      }

      return ret.join(separator);
    });
    Handlebars.registerHelper("iterate2", function(context, part, total, options) {
      var ret: any[] = [];
      //Chi hien thi 1 phan {part}/{total} record trong context
      if (part > 0 && total >= part) {
        let size = Math.ceil(context.length / total);
        let start = size * (part - 1);
        let end = part < total ? start + size : context.length;
        for (var i = start; i < end; i++) {
          ret.push(options.fn(context[i]));
        }
      } else {
        for (var i = 0, j = context.length; i < j; i++) {
          ret.push(options.fn(context[i]));
        }
      }
      

      return ret.join(separator);
    });
    const context = {user: userDetail, ...submission, created: moment(submission.created).format("DD/MM/yyyy hh:mm:ss")};
    let fistRowLength = 0;
    for (let rowInd = 0; rowInd < sheetData.length; rowInd++) {
      let sheetRow = sheetData[rowInd];
      //adjust first row
      if (rowInd == 0) {
        fistRowLength = sheetRow.length;
      } else if (sheetRow.length > fistRowLength) {
        for (let i = fistRowLength; i < sheetRow.length; i++) {
          data[0].push("");
        }
        fistRowLength = data[0].length;
      }
      /*
       * Lay gia tri thuoc dong dang xu ly.
       * Khi render list of item, 1 so dong phia sau co the dc tao ra o loop truoc
       */
      let row: any[] = data[rowInd] || [];
      data[rowInd] = row;
      for (let col = 0; col < sheetRow.length; col++) {
        let cell = sheetRow[col];
        if (typeof cell == 'string') {
          try {
            const template = Handlebars.compile(cell);
            //var compiled = _template(cell);
            let cellValue = template(context);
            if (typeof cellValue === 'string') {
              if (cellValue.startsWith("#qrcode#")) {
                let cellSetting: CellSettings = {
                  row: rowInd,
                  col,
                  renderer: 'qrcodeRenderer',
                  value: cellValue.substring("#qrcode#".length)
                };
                styledCells.push(cellSetting);
              } else {
                try {
                  let parts = cellValue.split(separator);
                  for (let j = 0; j < parts.length; j++) {
                    let rowValue = data[rowInd + j] || [];
                    data[rowInd + j] = rowValue;
                    rowValue[col] = parts[j];
                  }
                } catch (e) {
                  row[col] = cellValue;
                }
              }
            } else if (cellValue != undefined && cellValue != null) {
              row[col] = cellValue;
            }
          } catch (e) {
            console.log(e)
            row[col] = row[col] || cell;
          }
        } else {
          row[col] = row[col] || cell;
        }
      }
    }
    return {data, styledCells};
  }
  const parseMetadata = (sheet: WorkSheet, styles: any) => { 
    let widths: ColWidth[] = [];
    let mergeCells: MergeCellSettings = [];
    let styledCells: CellSettings[] = [];
    if (sheet["!ref"] && sheet["!cols"]) {
      let range = utils.decode_range(sheet["!ref"]);
      for (let c = 0; c <= range.e.c; c++) {
        let colStyle = sheet["!cols"][c];
        if (colStyle?.wch) {
          widths.push(colStyle.wch * (templateSubmission?.data?.characterWidth || CHARACTER_WIDTH));
        } else if (colStyle?.wpx) {
          widths.push(colStyle.wpx);
        } else {
          console.log("missing colstyle at col: ", c);
        }
      }
    
      if (sheet["!merges"]) {
        for (let ind = 0; ind < sheet["!merges"].length; ind++) {
          let range: Range = sheet["!merges"][ind];
          let mergeDetail: DetailedSettings = {
            row: range.s.r,
            col: range.s.c,
            rowspan: range.e.r - range.s.r + 1,
            colspan: range.e.c - range.s.c + 1
          }
          mergeCells.push(mergeDetail);
        }
      }
      
      for (let key in sheet) {
        if (key.startsWith("!")) continue;
        let cell = sheet[key], range;
        if ((cell.s || cell.h)&& (range = utils.decode_range(key))) {
          if (range) {
            let cellSetting: CellSettings = {
              row: range.s.r,
              col: range.s.c
              //comment: cell.s
            };
            if (cell.s) {
              if (cell.s.fontInd && styles?.fonts.length > cell.s.fontInd) {
                let cellFont = styles.fonts[cell.s.fontInd];
                cellSetting.font = cellFont;
                cellSetting.renderer = 'xlsxStylesRenderer';
              }
              let classNames = [];
              if (cell.s.valign) {
                classNames.push("ht" + cell.s.valign);
              }
               if (cell.s.halign) {
                classNames.push("ht" + cell.s.halign);
              }
              if (classNames.length > 0) {
                cellSetting.className = classNames.join(" ");
              }
            } else if (cell.h) {
              cellSetting.renderer = 'imageRenderer';
              cellSetting.size = cell.size;
              cellSetting.base64 = cell.h;
            }
            styledCells.push(cellSetting);
          }
        }
      }
    }
    let rowHeights = sheet["!rows"]?.map((row) => row.hpx)
    return {
      widths,
      rowHeights,
      mergeCells,
      styledCells
    }
  }
  useEffect(() => {
    if (xlsxTemplate && submission) {
      let sheetName = xlsxTemplate.SheetNames[0];
      let sheet = xlsxTemplate.Sheets[sheetName];
      let styles = (xlsxTemplate as any).Styles;
      let customBorders = (sheet as any)["!borders"];
      if (customBorders) {
        setCustomBorders(customBorders);
      }
      let meta = parseMetadata(sheet, styles);
      let bindedData = bindData(submission, sheet);
      setWidths(meta.widths);
      setRowHeights(meta.rowHeights!);
      setMergeCells(meta.mergeCells);
      setData(bindedData.data);
      let styledCells = meta.styledCells.concat(bindedData.styledCells);
      setStyledCells(styledCells);
    }
  }, [xlsxTemplate, submission]);
  
  return (
    <Paper elevation={6} className="paperA4">
      <HotTable ref={ref} className="content"
        data={data}
        colWidths={widths}
        mergeCells={mergeCells}
        cell={styledCells}
        customBorders={customBorders}
        rowHeaders={rowHeaders}
        rowHeights={rowHeights}
        colHeaders={headers}
        dropdownMenu={true}
        hiddenColumns={{
          indicators: true,
        }}
        contextMenu={true}
        multiColumnSorting={true}
        filters={true}
        autoWrapCol={true}
        autoWrapRow={true}
        afterGetColHeader={alignHeaders}
        beforeRenderer={addClassesToRows}
        afterChange={afterChange}
        manualRowMove={true}
        readOnly={true}
        height="auto"
        licenseKey="non-commercial-and-evaluation"
      >
        { columns?.map(renderColumn) }
      </HotTable>
    </Paper>
  )
})
export default XlsxSubmission;

