import ACTION_CONSTANTS from "src/actions/actionConstants";
import {
  setShowApplications,
  setUserRolesToObject,
} from "src/helper/user";
import { LANGUAGE } from "src/constants";
import { setFormAndSubmissionAccess } from "src/helper/access";

const getLanguages = localStorage.getItem("languages");
const roleIds = localStorage.getItem("roleIds");
const roleIdsFromLocalStorage = roleIds ? JSON.parse(roleIds) : undefined;
const formAccess = roleIdsFromLocalStorage ? setFormAndSubmissionAccess("formAccess", roleIdsFromLocalStorage) : "[]";
const submissionAccess = roleIdsFromLocalStorage? setFormAndSubmissionAccess("submissionAccess", roleIdsFromLocalStorage): "[]";
const initialState = {
  bearerToken: "",
  roles: "",
  roleIds: roleIdsFromLocalStorage
    ? setUserRolesToObject(roleIdsFromLocalStorage)
    : {},
  formAccess, //formAccess - if using initial array, redux fire error "Invariant failed: A state mutation was detected between dispatches, in the path 'user.formAccess.0.roles'"
  submissionAccess, // [],
  userDetail: null,
  isAuthenticated: false,
  currentPage: "",
  showApplications: false,
  lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : LANGUAGE,
  selectLanguages: getLanguages ? JSON.parse(getLanguages) : [],
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case ACTION_CONSTANTS.SET_USER_TOKEN:
      localStorage.setItem("authToken", action.payload);
      return { ...state, bearerToken: action.payload };
    case ACTION_CONSTANTS.SET_USER_ROLES:
      return { ...state, roles: action.payload };
    case ACTION_CONSTANTS.SET_USER_DETAILS:
      return {
        ...state,
        userDetail: action.payload,
        showApplications: setShowApplications(action.payload?.groups || [])
      };
    case ACTION_CONSTANTS.SET_USER_AUTHENTICATION:
      return { ...state, isAuthenticated: action.payload };
    case ACTION_CONSTANTS.SET_LANGUAGE:
      localStorage.setItem("lang", action.payload);
      return { ...state, lang: action.payload };
    case ACTION_CONSTANTS.SET_SELECT_LANGUAGES:
      localStorage.setItem("languages", JSON.stringify(action.payload));
      return { ...state, selectLanguages: action.payload };
    case ACTION_CONSTANTS.ROLE_IDS:
      return { ...state, roleIds: setUserRolesToObject(action.payload) };
    case ACTION_CONSTANTS.ACCESS_ADDING:
      return {
        ...state,
        formAccess: setFormAndSubmissionAccess("formAccess", action.payload),
        submissionAccess: setFormAndSubmissionAccess("submissionAccess", action.payload),
      };
    default:
      return state;
  }
};

export default user;
