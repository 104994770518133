/*
 * Editable spreadsheet utilizing handsontable
*/
import React, { useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useDispatch } from 'src/redux/store';
import {
  selectRoot,
  resetSubmissions,
  saveSubmission,
  Form,
  selectError,
  Errors,
  Utils,
} from "@formio/react";
import cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loading from "src/components/containers/Loading";
import { setFormSubmissionLoading } from "src/actions/formActions";
import { useTranslation } from "react-i18next";
import { default as formio_resourceBundles } from "src/locales/formio";
import {
  CUSTOM_SUBMISSION_URL,
  CUSTOM_SUBMISSION_ENABLE,
  MULTITENANCY_ENABLED,
} from "src/constants";
import { saveCustomSubmission, loadForm, loadFormio, deleteSubmission, fetchCustomSubmission } from "src/apiManager/services/FormServices";
import DownloadPDFButton from "src/components/Form/ExportAsPdf/downloadPdfButton";
import SubmissionActions from "../../../smartform/SubmissionActions";
import { Dialog } from "primereact/dialog";
import FormContainer from "src/components/smartform/FormContainer";
import { actionHandler, cleanupSubmissionData, handleOpenForm } from "src/components/Formio/components/helper";
import { fetchFormByAlias } from "src/apiManager/services/bpmFormServices";
import { loadFormActions } from "src/helper/helper";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { createUrlSubmissionEdit, createUrlSubmissionList } from "src/utils/meta";
import { IFormio, ISubmission } from "src/types/form";
import { FORM_ACTIONS } from "src/config-global";
import HandsonTable, { IHandsonColumn } from 'src/components/smartform/HandsonTable';

interface ISpreadSheetProps {
  dispatch?: Function,
  columns?: Record<string, any>[],
  resolveSubmissionOperation?: Function,
  pageSizes: number[],
  formId: string,
  formActions?: ISubmission[],
  refFormAction: ISubmission,
  refSubmission: ISubmission,
  filters: Record<string, any>,
  builderMode: boolean,
  submissions?: Record<string, any>,
  getSubmissions?: Function,
  onPageSizeChanged?: Function,
  onAction?: Function,
  onCreate: Function,
  params?: Record<string, any>
}
const SpreadSheet = React.memo((props: ISpreadSheetProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formId, submissionId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    // hideComponents,
    // onSubmit,
    // options,
    // errors,
    // form: { form, isActive: isFormActive },
    // formActions,
    // submission: { submission, isActive: isSubActive, url },
    // onCustomEvent,
    // onInitialized = (form) => {},
    // showPrintButton,
  } = props;
  const tenantKey = useSelector((state: Record<string, any>) => state.tenants?.tenantId);
  const tenantUrl = MULTITENANCY_ENABLED ? `/tenant/${tenantKey}` : "";
  const formActionSubmissions = useSelector((state: Record<string, any>) => state.meta.submissions[FORM_ACTIONS]);
  const [form, setForm] = useState<IFormio>();
  const [formAction, setFormAction] = useState<ISubmission>();
  const [submissions, setSubmissions] = useState<ISubmission[]>();
  const [columns, setColumns] = useState<IHandsonColumn[]>();
  const isFormSubmissionLoading = useSelector(
    (state: Record<string, any>) => state.formDelete.isFormSubmissionLoading
  );

  const customSubmission = useSelector(
    (state : Record<string, any>) => state.customSubmission?.submission || {}
  );
  const actionId = searchParams.get("actionId");
  const refFormId = searchParams.get("refForm");
  const refSubmissionId = searchParams.get("refSubmission");

  const fetchForm = async (formId: string) => {
    loadFormio(formId, (err: any, form: IFormio) => {
      setForm(form);
    })
  }
  const createTableData = (submissions: ISubmission[]| undefined) => { 
    let data: any[][] = [];
    return data;
  }
  // const createMappingSubmission = (submission: ISubmission, formAction: ISubmission) => { 
  //   if (formId && form && submission && formAction) {
  //     let allComponents = Utils.flattenComponents(form.components, false);
  //     let data: Record<string, any> = {};
  //     for (let key in allComponents) {
  //       let component = allComponents[key];
  //       if (component.calculateValue) {
  //         let fieldValue = Utils.evaluate(component.calculateValue, {
  //           component,
  //           data: submission.data,
  //           row: submission.data,
  //           submission: submission
  //         }, "value", false);
  //         if (fieldValue) {
  //           data[component.key] = fieldValue;
  //         }
  //       }
  //     }
  //     let result: ISubmission = {
  //       access: submission.access,
  //       created: submission.created,
  //       data,
  //       externalIds: [],
  //       extraParams: undefined,
  //       form: formId,
  //       metadata: submission.metadata,
  //       modified: submission.modified,
  //       owner: submission.owner,
  //       roles: [],
  //       _id: ""
  //     };
  //     return result;
  //   }
  // }
  useEffect(() => { 
    for (let action of formActionSubmissions) {
      if (actionId == _get(action, "_id")) {
        setFormAction(action);
        break;
      }
    }
  }, [actionId, formActionSubmissions])
  useEffect(() => { 
    if (formId) {
        fetchForm(formId);
      }
  }, [formId]);
  // useEffect(() => { 
  //   if (formId && refFormId && refSubmissionId) {
  //     fetchCustomSubmission(refSubmissionId, refFormId,
  //       (err: any, submission: ISubmission) => {
  //         setSubmission(submission);
  //       }
  //     )
  //   }
  // }, [refFormId, refSubmissionId])
  // useEffect(() => { 
  //   if (formAction && submission) {
  //     // let mapSubmission = createMappingSubmission(submission, formAction);
  //     // setMappingSubmission(mapSubmission);
  //   }
  // }, [formAction, submission])
  // const selectedSubmission = useMemo(()=>{
  //   if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
  //     return cloneDeep(customSubmission);
  //   } else {
  //     return cloneDeep(submission);
  //   }
  // },[customSubmission,submission]);

  // if (isFormActive || (isSubActive && !isFormSubmissionLoading) || !selectedSubmission?.data) {
  //   return <Loading />;
  // }
  // const actionContext = () => ({
  //   form,
  //   submissionId: selectedSubmission?._id,
  //   navigate,
  //   dispatch,
  //   handleOpenDialog
  // });
  return (
    <div>
      <ConfirmDialog />
      {/* {form ?
        <SubmissionActions page="spreadsheet" form={form}
          formActions={formActions}
          submission={selectedSubmission}
          submissionId={selectedSubmission._id} /> : null} */}
      {/* <Errors errors={errors} />       */}
      <div>
        <HandsonTable data={ createTableData (submissions)} columns={columns}/>
      </div>
    </div>
  );
});

export default SpreadSheet;

