export const defaultSubmissionData = {
  limit: 1,
  pagination: {
    page: 1,
    numPages: 1,
    total: 1,
  },
  sort: "",
  submissions: [],
};
