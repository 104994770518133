import { useDispatch, useSelector } from "react-redux";
// components
import { SplashScreen } from 'src/components/loading-screen';
//
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthConsumer({ children }: Props) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: Record<string, any>) => state.user.isAuthenticated
  );
  return (
    <AuthContext.Consumer>
      {(context) => (context.authenticated ? children : <SplashScreen /> )}
    </AuthContext.Consumer>
  );
}
