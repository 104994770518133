
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import SubmissionCalendar from 'src/components/smartform/SubmissionCalendar';
import { FORM_PAGE } from 'src/config-global';
import _get from "lodash/get";
import { IFieldMapper, IFormio, IPageParameter, ISubmission } from 'src/types/form';
import { loadFormio } from 'src/apiManager/services/FormServices';
import { handleSubmit } from 'src/components/Formio/components/helper';

interface ICalendarViewProps {
    refForm?: IFormio,
    refSubmission?: ISubmission
    refFormAction?: any,
    parameters?: Record<string, any>,
    mapper?: Record<string, any>
}

export default function CalendarView(props: ICalendarViewProps) {
    const mapPages = useSelector((state: Record<string, any>) => state.meta.mapSubmissions[FORM_PAGE]);
    const { formId } = useParams();
    const [form, setForm] = useState<IFormio>();
    /*
    * store page parameters when current list is open form a Page
    * Parameters is put into scope's group: view, filter, api
    */
    const [pageParameters, setPageParameters] = useState({});
    const [pageData, setPageData] = useState({});
    const [fieldMappers, setFieldMappers] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const pageId = searchParams.get("pageId");
    const loadForm = async (formId: string) => { 
        let form = await loadFormio(formId);
        setForm(form);
    }
    useEffect(() => {
        if (formId) {
            loadForm(formId);
        }
     }, [formId]);
    useEffect(() => {
        if (pageId) {
            setPageData(_get(mapPages[pageId], "data", {}))
        }
    }, [pageId])

    const callback = (err: any, data: any) => {
        console.log(data);
    }
    return <>
        {form && <SubmissionCalendar form={form} pageData={pageData} onSubmit={(submission: ISubmission) =>handleSubmit(submission, form!, callback)}/>}
    </>
}