import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep";

export function buildTree(listItems, mapFields) {
    let result = [];
    const getValue = (item, field) => {
        let path = mapFields ? mapFields[field] || field : field;
        return _get(item, path);
    }
    let mapTreeItems = {};
    if (!Array.isArray(listItems)) {
        return result;
    }
    const createTreeItem = (itemId, listItem) => {
        let treeItem = 
        {
            id: itemId,
            label: getValue(listItem, "label"),
            //name: getValue(listItem, "name"),
            data: getValue(listItem, "data"),
            //icon: getValue(listItem, 'pi pi-fw pi-inbox',
            children: [],
            _item: listItem
        };
        return treeItem
    }
    //1. Frist step create map items
    for (let ind = 0; ind < listItems.length; ind++) {
        const listItem = listItems[ind];
        let itemId = getValue(listItem, "id");
        let treeItem = createTreeItem(itemId, listItem);
        // if (typeof treeItem.data?.form == 'object') {
        //     delete treeItem.data?.form;
        // }
        mapTreeItems[itemId] = treeItem;
    }
    //2. Second step create tree structure. Map parent
    for (let ind = 0; ind < listItems.length; ind++) {
        const listItem = listItems[ind];
        let itemId = getValue(listItem, "id");
        let parentId = getValue(listItem, "parent");
        let parent = parentId ? mapTreeItems[parentId] : null;
        let treeItem = mapTreeItems[itemId];            
        if (parent) {
            //treeItem.key = `${parent.key}-${parent.children.length}`;
            parent.children.push(treeItem);
        } else {
            //treeItem.key = `${result.length}`;
            result.push(treeItem);
        }
        //listItem.path = treeItem.key;
    }
    //3. Clean up empty nodes
    const cleanNodes = (nodes) => {
        if (nodes.length == 0) return nodes;
        return nodes.filter(node => {
            let form = _get(node, "data.form");
            let action = _get(node, "data.action");
            if (form && action) {
                //Leaf
                return true;
            }
            let children = cleanNodes(node.children);
            if (children.length > 0) {
                node.children = children;
                return true;
            }
            return false;
        });
    }
    // result = cleanNodes(result);
    //4. Third step create key/path value
    const setItemKey = (item, key) => {
        //Set the tree item's key
        item.key = key;
        //Set path for the original listItem
        item._item.path = key;
        //Loop for the tree item's children
        if (Array.isArray(item.children)) {
            for (let ind = 0; ind < item.children.length; ind ++) {
                let childKey = `${key}-${ind}`;
                setItemKey(item.children[ind], childKey);
            }
        }
    }
    for (let ind = 0; ind < result.length; ind++) {
        let key = `${ind}`;
        setItemKey(result[ind], key);
    }
    return result;
}

export function findNode(menuTree, key) {
    let item = null;
    let currentChildren = menuTree;
    if (typeof key == 'string' && key) {
        let paths = key.split('-');
        for (let i = 0; i < paths.length; i++) {
            let ind = paths[i];
            if (ind != null && ind != undefined && currentChildren) {
                item = currentChildren[paths[i]];
                currentChildren = item.children;
            } else {
                break;
            }
        }
    }
    return item;
}

export const createGroupTree = (kcgroups) => {
    return kcgroups.map((group, index) => {
        let children = group.subGroups ? createGroupTree(group.subGroups) : undefined;
        //group.key = index;
        let node = {
            id: group.id,
            key: group.path,
            data: group.path,
            label: group.name,
            children
        };
        return node;
    })
};
