import { lazy, useContext, Suspense } from "react";
import { Outlet } from 'react-router-dom';
import ApplicationLayout from 'src/layouts/application';
import { SplashScreen } from 'src/components/loading-screen';
// config
import { BASE_CONTEXT } from 'src/config-global';
import AccessPage from "src/pages/admin/permission/access";
// USER
const UserProfilePage = lazy(() => import('src/pages/admin/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/admin/user/cards'));
const UserListPage = lazy(() => import('src/pages/admin/user/list'));
const UserAccountPage = lazy(() => import('src/pages/admin/user/account'));
const UserCreatePage = lazy(() => import('src/pages/admin/user/new'));
const UserChangePasswordPage = lazy(() => import('src/pages/admin/user/change-password'));
const UserEditPage = lazy(() => import('src/pages/admin/user/edit'));
const GroupListPage = lazy(() => import('src/pages/admin/group/list'));

const MenuListPage = lazy(() => import('src/pages/admin/menu/list'));
const MenuCreatePage = lazy(() => import('src/pages/admin/menu/create'));
const MenuEditPage = lazy(() => import('src/pages/admin/menu/edit'));

const FormActionsListPage = lazy(() => import('src/pages/admin/formActions/list'));
const FormActionsCreatePage = lazy(() => import('src/pages/admin/formActions/create'));
const FormActionsEditPage = lazy(() => import('src/pages/admin/formActions/edit'));

export const adminRoutes = [
  {
    path: `${BASE_CONTEXT}/admin`,
    element: (
        <ApplicationLayout>
          <Suspense fallback={<SplashScreen />}>
              <Outlet />
          </Suspense>
        </ApplicationLayout>
    ),
    children: [
      {
        path: 'permission',
        children: [
          { path: 'access', element: <AccessPage />, index: true },
          { path: 'group', element: <GroupListPage /> },
          { path: 'role', element: <GroupListPage /> },
        ],
      },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
          { path: 'change-password', element: <UserChangePasswordPage /> },
        ]
      },
      {
        path: 'group',
        children: [
          { path: 'list', element: <GroupListPage />, index: true },
          { path: 'create', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
        ]
      },
      {
        path: 'menu',
        children: [
          { path: 'list', element: <MenuListPage /> },
          { path: 'create', element: <MenuCreatePage /> },
          { path: ':id/edit', element: <MenuEditPage /> },
        ]
      },
      {
        path: 'formactions',
        children: [
          { path: 'list', element: <FormActionsListPage /> },
          { path: 'create', element: <FormActionsCreatePage /> },
          { path: ':id/edit', element: <FormActionsEditPage /> },
        ]
      }
    ]
  }
]