import React, { useState } from "react";
import {
    Form,
    Errors,
    Utils as FormioUtils,
} from "@formio/react";
import _get from "lodash/get";
import SubmissionActions from "./SubmissionActions";
import { IFormio, ISubmission } from "src/types/form";
import { IContext } from "src/types/common";

interface IFormContainerProps {
    form: IFormio,
    submission?: ISubmission,
    formActions?: Record<string, any>[],
    actionHandler?: () => void,
    handleSubmit?: (submission: ISubmission, action: any, context: Record<string, any>) => void,
    onInitialized?: () => {},
    onCustomEvent?: () => {},
    onFormChange?: () => {},
}

const FormContainer = React.memo((props: IFormContainerProps) => {
    const {
        form, 
        submission,
        formActions,
        actionHandler = () => { },
        handleSubmit = (submission, action, context) => { },
        onInitialized = (form: IFormio) => { },
        onCustomEvent = () => { },
        // onFormChange = () => { },
        // ...options
    } = props;
    const [errors, setErrors] = useState([]);
    const onSubmit = (submission: ISubmission) => { 
        //Find action associated with the submit button.
        const components = FormioUtils.flattenComponents(form?.components as any[], false);
        let actionName = null;
        let context: IContext = {};
        for (var field in components) {
            const comp = components[field];
            if (comp.type == 'button' && comp.action == 'submit') {
                actionName = comp.properties.action;
                context.properties = comp.properties;
                break;
            }
        }
        
        let submitAction = null;
        if (actionName && formActions) {
            for (let ind = 0; ind < formActions.length; ind++) {
                const formAction = formActions[ind];
                if (actionName == _get(formAction, "data.actionName")) {
                    submitAction = formAction;
                    break;
                }
            }
        } 
        handleSubmit(submission, submitAction, context);
    }
    return (
        <div className="form-container">
            <SubmissionActions
                form={form}
                formActions={formActions}
                actionHandler={actionHandler}
                submission={submission}
            />
            <Errors errors={errors} />
            <div className="sub-container">
                {form &&
                    <Form
                        form={form}
                        submission={submission}
                        onSubmit={onSubmit}
                        // options={{
                        //     ...options,
                        // }}
                        onInitialized={() => onInitialized(form!)}
                        // onChange={(event) => onFormChange(form, event, dispatch)}
                        onCustomEvent={onCustomEvent}
                    />
                }
                </div>
        </div>
    );
});
export default FormContainer;