import { lazy, useContext, Suspense } from "react";
import { Outlet } from 'react-router-dom';
import ApplicationLayout from 'src/layouts/application';
import { SplashScreen } from 'src/components/loading-screen';
// config
import { BASE_CONTEXT } from 'src/config-global';
// USER
const UserProfilePage = lazy(() => import('src/pages/admin/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/admin/user/cards'));
const UserListPage = lazy(() => import('src/pages/admin/user/list'));
const UserAccountPage = lazy(() => import('src/pages/admin/user/account'));
const UserCreatePage = lazy(() => import('src/pages/admin/user/new'));
const UserEditPage = lazy(() => import('src/pages/admin/user/edit'));

const MenuListPage = lazy(() => import('src/pages/admin/menu/list'));
const MenuCreatePage = lazy(() => import('src/pages/admin/menu/create'));
const MenuEditPage = lazy(() => import('src/pages/admin/menu/edit'));

const FormActionsListPage = lazy(() => import('src/pages/admin/formActions/list'));
const FormActionsCreatePage = lazy(() => import('src/pages/admin/formActions/create'));
const FormActionsEditPage = lazy(() => import('src/pages/admin/formActions/edit'));

export const calendarRoute = {
  path: `${BASE_CONTEXT}/calendar`,
  element: (
      <ApplicationLayout>
        <Suspense fallback={<SplashScreen />}>
            <Outlet />
        </Suspense>
      </ApplicationLayout>
  ),
  children: [
    {
      path: 'user',
      children: [
        { element: <UserProfilePage />, index: true },
        { path: 'profile', element: <UserProfilePage /> },
        { path: 'cards', element: <UserCardsPage /> },
        { path: 'list', element: <UserListPage /> },
        { path: 'new', element: <UserCreatePage /> },
        { path: ':id/edit', element: <UserEditPage /> },
        { path: 'account', element: <UserAccountPage /> },
      ]
    },
    {
      path: 'menu',
      children: [
        { path: 'list', element: <MenuListPage /> },
        { path: 'create', element: <MenuCreatePage /> },
        { path: ':id/edit', element: <MenuEditPage /> },
      ]
    },
    {
      path: 'formactions',
      children: [
        { path: 'list', element: <FormActionsListPage /> },
        { path: 'create', element: <FormActionsCreatePage /> },
        { path: ':id/edit', element: <FormActionsEditPage /> },
      ]
    }
  ]
}