import React, { useEffect, useState, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getForm,
  getSubmissions,
  selectRoot,
  selectError,
  SubmissionGrid,
  Errors,
  deleteSubmission,
} from "@formio/react";
import cloneDeep from "lodash/cloneDeep";

import Loading from "src/components/containers/Loading";
import {
  OPERATIONS,
  CLIENT,
  STAFF_DESIGNER,
  STAFF_REVIEWER,
  MULTITENANCY_ENABLED,
} from "src/constants";
import Confirm from "src/components/containers/Confirm";
import { setFormSubmissionDeleteStatus } from "src/actions/formActions";
import { getAllApplicationsByFormId } from "src/apiManager/services/applicationServices";
import {
  addApplicationDetailsToFormComponent,
  getRelevantApplications,
} from "src/apiManager/services/formatterService";
import { defaultSubmissionData } from "src/constants/submissionConstants";
import { setApplicationListLoader } from "src/actions/applicationActions";
import { Translation, useTranslation } from "react-i18next";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { BASE_CONTEXT } from 'src/config-global';

const getOperations = (userRoles) => {
  let operations = [];
  if (userRoles.includes(STAFF_DESIGNER)) {
    operations.push(
      { ...OPERATIONS.viewSubmission, title: null},
      { ...OPERATIONS.editSubmission, title: null},
      { ...OPERATIONS.deleteSubmission, title: null} 
    );
  }
  else if (userRoles.includes(STAFF_REVIEWER)) {
    operations.push(OPERATIONS.viewSubmission, OPERATIONS.deleteSubmission);
  } else if (userRoles.includes(CLIENT)) {
    operations.push(OPERATIONS.viewSubmission);
  }
  return operations;
};

const SubmissionList = React.memo((props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { formId } = useParams();
  const {
    form,
    submissions,
    isLoading,
    errors,
    userRoles,
    submissionFormId,
    submissionId,
  } = props;

  const operations = getOperations(userRoles);
  const formApplicationsList = useSelector(
    (state) => state.applications.formApplicationsList
  );
  const isApplicationsListLoading = useSelector(
    (state) => state.applications.isApplicationListLoading
  );
  const [formData, setFormData] = useState();
  const [submissionListData, setSubmissionListData] = useState(
    defaultSubmissionData
  );

  const tenantKey = useSelector((state) => state.tenants?.tenantId);
  const redirectUrl = MULTITENANCY_ENABLED ? `/tenant/${tenantKey}/` : "/";
  const onAction = (submission, action) => {
    switch (action) {
      case "viewSubmission":
        dispatch(
          navigate(
            `${BASE_CONTEXT}${redirectUrl}form/${formId}/submission/${submission._id}`
          )
        );
        break;
      case "edit":
        dispatch(
          navigate(
            `${BASE_CONTEXT}${redirectUrl}form/${formId}/submission/${submission._id}/edit`
          )
        );
        break;
      case "delete":{
        const submissionDetails = {
          modalOpen: true,
          formId: formId,
          submissionId: submission._id,
        };
        dispatch(setFormSubmissionDeleteStatus(submissionDetails));
        break;
      }
      default:
    }
  };
  const fetchSubmissions = (page, query = { 
    //"data.applicationId__regex": "/^\d+$/" 
  }) =>
    dispatch(
      getSubmissions("submissions", page, query, formId, (err, submissions) => {
        console.log(submissions);
      })
    );
  const onYes = (formId, submissionId, submissions) => {
    dispatch(
      deleteSubmission("submission", submissionId, formId, (err) => {
        if (!err) {
          const submissionDetails = {
            modalOpen: false,
            submissionId: "",
            formId: "",
          };
          dispatch(setFormSubmissionDeleteStatus(submissionDetails));
          fetchSubmissions(1, submissions.query);
        }
      })
    );
  };
  const onNo = () => {
    const submissionDetails = {
      modalOpen: false,
      submissionId: "",
      formId: "",
    };
    dispatch(setFormSubmissionDeleteStatus(submissionDetails));
  };
  useEffect(() => {
    dispatch(setApplicationListLoader(true));
    dispatch(getAllApplicationsByFormId(formId));
    fetchSubmissions(1, {});
  }, [dispatch, formId]);

  useEffect(() => {
    dispatch(getForm("form", formId, (err, form) => {
      if (form && form.components) {
        setFormData(addApplicationDetailsToFormComponent(cloneDeep(form)));
      }
    }));
  }, [formId, setFormData]);
  useEffect(() => {
    setSubmissionListData(submissions);
  }, [submissions, setSubmissionListData]);
  // useEffect(() => {
  //   if (formApplicationsList.length && submissions) {
  //     let updatedSubmissionList = getRelevantApplications(
  //       formApplicationsList,
  //       cloneDeep(submissions)
  //     );
  //     setSubmissionListData(updatedSubmissionList);
  //   }
  // }, [formApplicationsList, submissions, setSubmissionListData]);
  const handleCreate = () => {
    const submissionCreateUrl = `${BASE_CONTEXT}${redirectUrl}form/${formId}/submissions/create`;
    navigate(submissionCreateUrl);
  }
  //TODO add formApplicationLoader
  if (isLoading || isApplicationsListLoading) {
    return <Loading />;
  }
  const header = 
        (<div className="ml-3">
            <i className="fa fa-wpforms" aria-hidden="true" /> &nbsp;{" "}
            {formData?.title}
        </div>);
  const actions = (
      <React.Fragment>
          <Button icon="pi pi-plus" 
            onClick={handleCreate}
            className="mr-2" />
      </React.Fragment>
  );
  console.log(submissionListData);
  return (
    <>
      <Confirm
        modalOpen={props.modalOpen}
        message={
          <Translation>
            {(t) => t("Are you sure you wish to delete this submission?")}
          </Translation>
        }
        onNo={() => onNo()}
        onYes={() => onYes(submissionFormId, submissionId, submissions)}
      ></Confirm>
      <Errors errors={errors} />
      <Panel header={header} icons={actions}>
        {formData && (
            <SubmissionGrid
              submissions={submissionListData}
              form={formData}
              onAction={onAction}
              getSubmissions={fetchSubmissions}
              operations={operations}
            />
          )}
      </Panel>
      {/* <div className="main-header">
        <Link className="back-icon" to={`${redirectUrl}form`}>
          <i className="fa fa-chevron-left fa-lg" />
        </Link>
        <span className="ml-3">
          <img src="/form.svg" width="30" height="30" alt="form" />
        </span>
        <h3 className="ml-3">
          <span className="task-head-details d-flex align-items-center">
            {" "}
            <i className="fa fa-wpforms" aria-hidden="true" />
            <span className="forms-text">
              <Translation>{(t) => t("Forms")}</Translation> /
            </span>
            {formData?.title}
          </span>
        </h3>
        {userRoles.includes(CLIENT) ? 
        <Link className="btn btn-primary form-btn btn-right" to={`/form/${formId}`}>
        <img src="/webfonts/fa_plus.svg" alt="back"/> New Submisssion
        </Link> : null} 
      </div> */}
    </>
  );
});

const mapStateToProps = (state) => {
  const form = selectRoot("form", state);
  // const applicationId = useSelector(
  //   (state) =>
  //     state[CUSTOM_SUBMISSION_ENABLE ? "customSubmission" : "submission"]?.submission?.data?.applicationId ||
  //     null
  // );
  const submissions = selectRoot("submissions", state);
  return {
    submissions: submissions,
    isLoading: form.isActive || submissions.isActive,
    errors: [selectError("submissions", state), selectError("form", state)],
    userRoles: selectRoot("user", state).roles || [],
    modalOpen: selectRoot("formDelete", state).formSubMissionDelete.modalOpen,
    submissionFormId: selectRoot("formDelete", state).formSubMissionDelete
      .formId,
    submissionId: selectRoot("formDelete", state).formSubMissionDelete
      .submissionId,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchSubmissions: (page, formId, query = { 
      //"data.applicationId__regex": "/^\d+$/" 
    }) =>
      dispatch(
        getSubmissions("submissions", page, query, formId)
      ),
    onAction: (submission, action, redirectUrl) => {
      switch (action) {
        case "viewSubmission":
          dispatch(
            navigate(
              `${redirectUrl}form/${ownProps.formId}/submission/${submission._id}`
            )
          );
          break;
        case "edit":
          dispatch(
            navigate(
              `${redirectUrl}form/${ownProps.formId}/submission/${submission._id}/edit`
            )
          );
          break;
        case "delete":{
          const submissionDetails = {
            modalOpen: true,
            formId: ownProps.formId,
            submissionId: submission._id,
          };
          dispatch(setFormSubmissionDeleteStatus(submissionDetails));
          break;
        }
        default:
      }
    },
    onYes: (formId, submissionId, submissions) => {
      dispatch(
        deleteSubmission("submission", submissionId, formId, (err) => {
          if (!err) {
            const submissionDetails = {
              modalOpen: false,
              submissionId: "",
              formId: "",
            };
            dispatch(setFormSubmissionDeleteStatus(submissionDetails));
            dispatch(
              getSubmissions(
                "submissions",
                1,
                submissions.query,
                formId
              )
            );
          }
        })
      );
    },
    onNo: () => {
      const submissionDetails = {
        modalOpen: false,
        submissionId: "",
        formId: "",
      };
      dispatch(setFormSubmissionDeleteStatus(submissionDetails));
    },
  };
};

export default connect(mapStateToProps)(SubmissionList);
