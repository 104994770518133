export { FormioHelper } from './utils';
import { EditTable } from './edittable';
import { ReactWrapper } from './reactwrapper';
//Inner components
import reactComponent from "./components";

export default {
    editTable: EditTable,
    reactwrapper: ReactWrapper,
    //resourcefields: ResourceFields,
    ...reactComponent
}