//import Keycloak from "keycloak-js";
import { Translation } from "react-i18next";
const getEnvVar = function(name) {
  return (window._env_ && window._env_[name]) ||
  process.env[name]
}
//application details
export const APPLICATION_NAME = getEnvVar("VITE_APPLICATION_NAME");
//language details
export const LANGUAGE =
  (window._env_ && window._env_.VITE_LANGUAGE) ||
  process.env.VITE_LANGUAGE ||
  "en";
//custom url
export const WEB_BASE_CUSTOM_URL =
  (window._env_ && window._env_.VITE_WEB_BASE_CUSTOM_URL) ||
  process.env.VITE_WEB_BASE_CUSTOM_URL ||
  "";
export const KEYCLOAK_ENABLE_CLIENT_AUTH_VARIABLE =
  (window._env_ && window._env_.VITE_KEYCLOAK_ENABLE_CLIENT_AUTH) ||
  process.env.VITE_KEYCLOAK_ENABLE_CLIENT_AUTH ||
  false;
export const KEYCLOAK_ENABLE_CLIENT_AUTH =
  KEYCLOAK_ENABLE_CLIENT_AUTH_VARIABLE === "true" ||
  KEYCLOAK_ENABLE_CLIENT_AUTH_VARIABLE === true
    ? true
    : false;
export const CUSTOM_SUBMISSION_URL =
  (window._env_ && window._env_.VITE_CUSTOM_SUBMISSION_URL) ||
  process.env.VITE_CUSTOM_SUBMISSION_URL ||
  "";
const CUSTOM_SUBMISSION_ENABLED_VARIABLE =
  (window._env_ && window._env_.VITE_CUSTOM_SUBMISSION_ENABLED) ||
  process.env.VITE_CUSTOM_SUBMISSION_ENABLED ||
  "";
export const CUSTOM_SUBMISSION_ENABLE =
  CUSTOM_SUBMISSION_ENABLED_VARIABLE === "true" ||
  CUSTOM_SUBMISSION_ENABLED_VARIABLE === true
    ? true
    : false;
//keycloak
export const Keycloak_Client =
  (window._env_ && window._env_.VITE_KEYCLOAK_CLIENT) ||
  process.env.VITE_KEYCLOAK_CLIENT ||
  "forms-flow-web";

const MULTITENANCY_ENABLED_VARIABLE =
  (window._env_ && window._env_.VITE_MULTI_TENANCY_ENABLED) ||
  process.env.VITE_MULTI_TENANCY_ENABLED ||
  false;
export const PUBLIC_WORKFLOW_ENABLED =
  (window._env_ && window._env_.VITE_PUBLIC_WORKFLOW_ENABLED) === "true" ||
  process.env.VITE_PUBLIC_WORKFLOW_ENABLED === "true"
    ? true
    : false;

export const MULTITENANCY_ENABLED =
  MULTITENANCY_ENABLED_VARIABLE === "true" ||
  MULTITENANCY_ENABLED_VARIABLE === true
    ? true
    : false;

export const BASE_ROUTE = MULTITENANCY_ENABLED ? "/tenant/:tenantId/" : "/";

export const Keycloak_Tenant_Client = (window._env_ && window._env_.VITE_KEYCLOAK_TENANT_CLIENT) ||
process.env.VITE_KEYCLOAK_TENANT_CLIENT || "forms-flow-web";

export const KEYCLOAK_REALM =
  (window._env_ && window._env_.VITE_KEYCLOAK_URL_REALM) ||
  process.env.VITE_KEYCLOAK_URL_REALM ||
  "forms-flow-ai";
export const KEYCLOAK_URL = getEnvVar("VITE_KEYCLOAK_URL");
  (window._env_ && window._env_.VITE_KEYCLOAK_URL) ||
  process.env.VITE_KEYCLOAK_URL;
export const KEYCLOAK_AUTH_URL = getEnvVar("VITE_KEYCLOAK_AUTH_URL");;

export const OPERATIONS = {
  insert: {
    action: "insert",
    buttonType: "primary button_font",
    //icon: "pencil",
    icon: "pi pi-pencil",
    permissionsResolver: function permissionsResolver() {
      return true;
    },
    title: <Translation>{(t) => t("Submit New")}</Translation>,
  },
  submission: {
    action: "submission",
    buttonType: "outline-primary button_font",
    icon: "list-alt",
    permissionsResolver: function permissionsResolver() {
      return true;
    },
    title: <Translation>{(t) => t("View Submissions")}</Translation>,
  },
  editForm: {
    action: "edit",
    buttonType: "secondary button_font",
    //icon: "edit",
    icon: "pi pi-pencil",
    permissionsResolver: function permissionsResolver() {
      return true;
    },

    title: <Translation>{(t) => t("Edit Form")}</Translation>,
  },
  viewForm: {
    action: "viewForm",
    buttonType: "outline-primary button_font",
    icon: "pencil-square-o",
    permissionsResolver: function permissionsResolver() {
      return true;
    },

    title: <Translation>{(t) => t("View/Edit Form")}</Translation>,
  },
  delete: {
    action: "delete",
    buttonType: " delete_button",
    icon: "trash",
    permissionsResolver: function permissionsResolver() {
      return true;
    },
  },
  viewSubmission: {
    action: "viewSubmission",
    buttonType: "primary",
    //icon: "list",
    icon: "pi pi-eye",
    permissionsResolver: function permissionsResolver() {
      return true;
    },

    title: <Translation>{(t) => t("View")}</Translation>,
  },
  editSubmission: {
    action: "edit",
    buttonType: "secondary",
    //icon: "edit",
    icon: "pi pi-pencil",
    permissionsResolver: function permissionsResolver() {
      return true;
    },

    title: <Translation>{(t) => t("Edit")}</Translation>,
  },
  deleteSubmission: {
    action: "delete",
    buttonType: "danger",
    //icon: "trash",
    icon: "pi pi-trash",
    permissionsResolver: function permissionsResolver() {
      return true;
    },

    title: <Translation>{(t) => t("Delete")}</Translation>,
  },
};

export const PageSizes = [20, 50, 100, 200];

// draft config
const DRAFT_POLLING_RATE_FROM_ENV =
  (window._env_ && window._env_.VITE_DRAFT_POLLING_RATE) ||
  process.env.VITE_DRAFT_POLLING_RATE;
export const DRAFT_POLLING_RATE = DRAFT_POLLING_RATE_FROM_ENV
  ? Number(DRAFT_POLLING_RATE_FROM_ENV)
  : null;
const DRAFT_ENABLED_VARIABLE =
  (window._env_ && window._env_.VITE_DRAFT_ENABLED) ||
  process.env.VITE_DRAFT_ENABLED ||
  false;
export const DRAFT_ENABLED =
  DRAFT_ENABLED_VARIABLE === "true" || DRAFT_ENABLED_VARIABLE === true
    ? true
    : false;

export const ENABLE_FORMS_MODULE =
  window._env_?.VITE_ENABLE_FORMS_MODULE === "false" ||
  window._env_?.VITE_ENABLE_FORMS_MODULE === false
    ? false
    : true;

export const ENABLE_TASKS_MODULE =
  window._env_?.VITE_ENABLE_TASKS_MODULE === "false" ||
  window._env_?.VITE_ENABLE_TASKS_MODULE === false
    ? false
    : true;

export const ENABLE_DASHBOARDS_MODULE =
  window._env_?.VITE_ENABLE_DASHBOARDS_MODULE === "false" ||
  window._env_?.VITE_ENABLE_DASHBOARDS_MODULE === false
    ? false
    : true;

export const ENABLE_PROCESSES_MODULE =
  window._env_?.VITE_ENABLE_PROCESSES_MODULE === "false" ||
  window._env_?.VITE_ENABLE_PROCESSES_MODULE === false
    ? false
    : true;

export const ENABLE_APPLICATIONS_MODULE =
  window._env_?.VITE_ENABLE_APPLICATIONS_MODULE === "false" ||
  window._env_?.VITE_ENABLE_APPLICATIONS_MODULE === false
    ? false
    : true;

export const CLIENT = "formsflow-client";
export const STAFF_DESIGNER = "formsflow-designer";
export const STAFF_REVIEWER = "formsflow-reviewer";
export const ANONYMOUS_USER = "anonymous";
export const PATH_DESIGNER_FORM = "designer/form";
export const ACCESS_FULL = "access-full";
export const REVIEWER_GROUP = KEYCLOAK_ENABLE_CLIENT_AUTH ? "formsflow-reviewer" : "formsflow/formsflow-reviewer";

export const ACTION_CREATE_OWN = "create_own";
export const ACTION_READ_OWN = "read_own";
export const ACTION_UPDATE_OWN = "update_own";
export const ACTION_DELETE_OWN =  "delete_own";
export const ACTION_CREATE_ALL = "create_all";
export const ACTION_READ_ALL = "read_all";
export const ACTION_UPDATE_ALL = "update_all";
export const ACTION_DELETE_ALL = "delete_all";
export const ACTION_PREVIEW_FORM = "previewForm";
export const ACTION_XLSX_BINDING = "xlsxBinding";
export const DEFAULT_ACTIONS = [
  { action: ACTION_READ_ALL },
  { action: ACTION_READ_OWN },
  { action: ACTION_CREATE_OWN },
  { action: ACTION_UPDATE_ALL },
  { action: ACTION_UPDATE_OWN },
  { action: ACTION_DELETE_ALL },
  { action: ACTION_DELETE_OWN },
];