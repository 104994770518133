import { Navigate, Route, Routes, useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubmission, selectRoot } from "@formio/react";
import { getApplicationById } from "src/apiManager/services/applicationServices";
import { setApplicationDetailLoader } from "src/actions/applicationActions";
import { getUserRolePermission } from "src/helper/user";
import _get from "lodash/get";
import {
  BASE_ROUTE,
  CLIENT,
  CUSTOM_SUBMISSION_URL,
  CUSTOM_SUBMISSION_ENABLE,
  STAFF_DESIGNER,
  STAFF_REVIEWER,
  MULTITENANCY_ENABLED
} from "src/constants";
import { CLIENT_EDIT_STATUS } from "src/constants/applicationConstants";
import Loading from "src/components/containers/Loading";
import { clearSubmissionError } from "src/actions/formActions";
import { getCustomSubmission, getCustomSubmissions, getSubmissions, loadForm } from "src/apiManager/services/FormServices";
import { useTranslation } from "react-i18next";
import NotFound from "src/components/NotFound";
import View from "./View";
import Edit from "./Edit";
import Action from "./Action";
import { BASE_CONTEXT } from "src/config-global";
import { eventHandler, onInitialized, onFormChange } from "../event";
import Preview from "../Preview";

const Item = React.memo((props) => {
  const { formId, submissionId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    refForm,
    refSubmission,
    refFormActions,
  } = props;
  const applicationDetail = useSelector(
    (state) => state.applications.applicationDetail
  );
  const applicationId = useSelector(
    (state) =>
      state[CUSTOM_SUBMISSION_ENABLE ? "customSubmission" : "submission"]?.submission?.data?.applicationId ||
      null
  );
  const userRoles = useSelector((state) => {
    return selectRoot("user", state).roles;
  });
  const applicationStatus = useSelector(
    (state) => state.applications.applicationDetail?.applicationStatus || "active"
  );
  const formActionSubmissions = useSelector((state) => state.meta.submissions.formActions);
  const [showSubmissionLoading, setShowSubmissionLoading] = useState(true);
  const [editAllowed, setEditAllowed] = useState(false);
  const submissionError = useSelector((state) => state.submission?.error);
  const [formActions, setFormActions] = useState();

  const redirectUrl = MULTITENANCY_ENABLED ? `/tenant/${tenantKey}/` : `/`
  const onCustomEvent = (event, params) => {
    console.log(event, params);
  }
  useEffect(() => {
    dispatch(clearSubmissionError("submission"));
    if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
      dispatch(getCustomSubmission(submissionId, formId));
    } else {
      dispatch(getSubmission("submission", submissionId, formId));
    }
  }, [submissionId, formId, dispatch]);

  //Load form action
  useEffect(() => {
    if (formId && Array.isArray(formActionSubmissions)) {
      let actions = formActionSubmissions?.filter((action) => _get(action, "data.sourceForm.formId") == formId);
      setFormActions(actions);
      //loadFormActions(formId, (err, actions) => {setFormActions(actions)});
    }
  }, [formId, formActionSubmissions]);

  useEffect(() => {
    if (applicationId) {
      dispatch(setApplicationDetailLoader(true));
      dispatch(getApplicationById(applicationId));
    }
  }, [applicationId, dispatch]);

  useEffect(() => {
    if (getUserRolePermission(userRoles, STAFF_REVIEWER) || getUserRolePermission(userRoles, STAFF_DESIGNER)) {
      setEditAllowed(true);
    } else if (applicationStatus) {
      if (getUserRolePermission(userRoles, CLIENT)) {
        setEditAllowed(CLIENT_EDIT_STATUS.includes(applicationStatus)
          || applicationDetail.isResubmit ? true : false);
        setShowSubmissionLoading(false);
      }
    }
  }, [applicationStatus, userRoles]);

  useEffect(() => {
    if (editAllowed && applicationStatus) setShowSubmissionLoading(false);
  }, [applicationStatus, editAllowed]);
  
  const root = `${BASE_CONTEXT}${redirectUrl}form/:formId/submission/:submissionId`;
  const context = {
    navigate
  }
  return (
    <div>
      <ul className="nav nav-tabs">
        {/* {showViewSubmissions && getUserRolePermission(userRoles, STAFF_REVIEWER) ?
        <li className="nav-item">
          <Link className="nav-link" to={`${redirectUrl}form/${formId}/submission`}>
            <i className="fa fa-chevron-left fa-lg" />
          </Link>
        </li>:null} */}
        {/*{(path.indexOf("edit") > 0) ?
          <li className="nav-item">
            <Link className="nav-link" to={`/form/${formId}/submission/${submissionId}`}>
            <img src="/webfonts/fa_eye.svg" alt="back"/> View
            </Link>
          </li>
          :
          editAllowed ? (<li className="nav-item">
            <Link className="nav-link" to={`/form/${formId}/submission/${submissionId}/edit`}>
              <img src="/webfonts/fa_edit.svg" alt="back"/> Edit
            </Link>
          </li>) : null
        }*/}
      </ul>
      <Routes>
        <Route
            exact
            path={''}
            element={ !submissionError ? 
            <View onCustomEvent={eventHandler} 
              onInitialized={onInitialized}
              formActions={formActions}
              /> : <NotFound
                errorMessage={t("Bad Request")}
                errorCode={400}
              />}
        />
        <Route exact path={`preview`}
          element={<Preview refForm={refForm} refSubmission={refSubmission}/>}>
        </Route>
        <Route exact path = {`edit/:notavailable`} element={<Navigate  to='/404'/>}/>
        <Route
            exact path={`edit`}
            element={ showSubmissionLoading 
              ? <Loading/> 
              : ( editAllowed ? 
                <Edit onCustomEvent= {(event) => eventHandler(event, context)}
                  onInitialized={onInitialized}
                  onFormChange={onFormChange}
                  /> : null)}
        />
        <Route
          path={`:action`}
          Component={Action}
        />
      </Routes>
    </div>
  );
});

export default Item;
