import { useEffect, useRef, useState } from "react";
import _get from "lodash/get";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  MULTITENANCY_ENABLED,
} from "src/constants";
import { loadFormio, getSubmissions, fetchSubmission, FormSubmissionParams, fetchExcelTemplate } from "src/apiManager/services/FormServices";
import { IFormio, ISubmission } from "src/types/form";
import "src/assets/styles/print.css";
import XlsxSubmission from "src/components/smartform/XlsxSubmission";
import * as XLSX from 'xlsx';
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { useReactToPrint } from "react-to-print";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

interface IPreviewProps {
  dispatch?: Function,
  form?: IFormio,
  formId?: string,
  submissions?: Record<string, any>,
  refSubmission?: ISubmission,
  refFormAction?: ISubmission,
}
const Preview = (props: IPreviewProps) => {
  const {
    form
  } = props;
  const { t } = useTranslation();
  const { formId, submissionId } = useParams();
   const printComponentRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const tenantKey = useSelector((state: Record<string, any>) => state.tenants?.tenantId);
  const tenantUrl = MULTITENANCY_ENABLED ? `/tenant/${tenantKey}` : "";
  const [submissionForm, setSubmissionForm] = useState<IFormio>();
  const [submissions, setSubmissions] = useState<ISubmission[]>();
  const [xlsxTemplate, setXlsxTemplate] = useState<XLSX.WorkBook>();
  const [templateSubmissions, setTemplateSubmissions] = useState<ISubmission[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<ISubmission>();
  const isFormSubmissionLoading = useSelector(
    (state: Record<string, any>) => state.formDelete.isFormSubmissionLoading
  );

  const customSubmission = useSelector(
    (state : Record<string, any>) => state.customSubmission?.submission || {}
  );
  const templateFormId = searchParams.get("templateFormId");
  const templateSubmissionId = searchParams.get("templateSubmissionId");
  const fieldName = searchParams.get("fieldName");
  const submissionIds = searchParams.get("submissionIds");

  useEffect(() => { 
    if (form) {
      setSubmissionForm(form);
    } else if (formId) {
      loadFormio(formId, (err: any, form: IFormio) => {
        setSubmissionForm(form);
      })
    }
  }, [formId, form]);
    //Load xlsx template
  useEffect(() => { 
    if (templateFormId && fieldName) {
      loadTemplates();
    }
  }, [templateFormId, fieldName])
  useEffect(() => { 
    if (submissionForm) {
      if (submissionId) {
        fetchSubmission({
          formId: submissionForm._id,
          submissionId,
          callback: (err: any, submission: ISubmission) => { setSubmissions([submission])} 
        });
      } else {
        let params: Record<string, any> = {};
        if (submissionIds?.length || 0 > 0) {
          params.query = {
            _id__in: submissionIds
          };
        } else if (props.refSubmission && props.refFormAction) {
          let parameters = _get(props.refFormAction, "data.parameters", []);
          for (let param of parameters) {
            if (param.name == 'refField' && param.value) {
              params.query = { [`data.${param.value}._id`] : props.refSubmission._id };
            }
          }
        }
        getSubmissions(submissionForm._id, params,
          (err: any, submissions: ISubmission[]) => {
            setSubmissions(submissions);
          });
      }
    }
  }, [submissionForm, submissionIds])
  const loadTemplates = async () => { 
    if (templateFormId) {
      let data = await getSubmissions(templateFormId, {
        query: {
          "data.applyForm.formId": formId
        }
      });
      if (Array.isArray(data) && data.length > 0) {
        setTemplateSubmissions(data as ISubmission[]);
        changeTemplate(data[0])
      }
    }
    // let res = await fetchSubmission(params);
    // let files = _get(res.data, `data.${fieldName}`, []);
    // let file = files[0];
    // if (file?.originalName && file?.url) {
    //   let parts = file.url.split(";base64,");
    //   let parserOptions = {
    //     cellDates: true,
    //     cellStyles: true,
    //     UTC: true
    //   }
    //   const data = XLSX.read(parts[1], parserOptions);
    //   let firstSheet = data.SheetNames[0];
    // }
  }
  const changeTemplate = async (value: ISubmission) => { 
    setSelectedTemplate(value);
    if (value.form) {
      let params: FormSubmissionParams = {
        formId: value.form,
        submissionId: value._id
      }
      let template = await fetchExcelTemplate(params);
      if (Array.isArray(template.data) && template.data.length > 0) {
        setXlsxTemplate(template.data[0]);
      } 
    }
  }
  const bindSubmission = (submission: ISubmission) => { 
    return (
      <XlsxSubmission key={submission._id}
        form={submissionForm}
        templateSubmission = {selectedTemplate}
        xlsxTemplate={xlsxTemplate}
        submission={submission} />
    );
  }
  const handlePrint = useReactToPrint({
    //documentTitle: "",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    content: () => {
        return printComponentRef.current
    },
    removeAfterPrint: true,
  });
  const startActions = (
    <>
      <Dropdown value={selectedTemplate} onChange={(e) => changeTemplate(e.value)} options={templateSubmissions} optionLabel="data.templateName" 
        placeholder="Select a template" className="mr-2" />
      <Button size="small"
          label={t('button.print')}
          icon="pi pi-print" className="mr-2"
          onClick={handlePrint} />
    </>
  )
  return (
    <div>
      <Toolbar start={startActions} className="mb-2" />
      <div className="container" ref={printComponentRef}>
        {submissionForm && xlsxTemplate && submissions ?
              submissions.map(bindSubmission) : null}
      </div>
    </div>
    
  );
};

export default Preview;

