import PropTypes from 'prop-types';
import useSWR from "swr";
import { useEffect, useReducer, useCallback, useMemo, FC, ReactNode, useState } from 'react';

// utils
import { fetcher } from 'src/utils/axios';
import { endpoints } from 'src/api/formio.js';
//
import { FormsContext, IForm } from './forms-context';
import { Formio } from 'formiojs';
import { fetchBPMFormList } from 'src/apiManager/services/bpmFormServices';
import { useDispatch, useSelector } from 'react-redux';
import { SYS_FORM_NAMES } from 'src/config-global';
import { getSubmissions } from 'src/apiManager/services/FormServices';
import {
  setForms, setSubmissions
} from 'src/redux/slices/meta';
import _get from "lodash/get";
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};
interface IActionPayload {
  user: string;
}
interface IAction {
  type: string;
  payload: IActionPayload;
}
const reducer = (state: any, action: IAction) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

interface Props {
  children: ReactNode;
}
const FormsProvider: FC<Props> =  ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: Record<string, any>) => state.user.isAuthenticated);
  const bpmForms = useSelector((state: Record<string, any>) => state.bpmForms.forms);
  // const processMenus = (err: any, data: any) => {
  //   dispatch(setMenus(data));
  // };
  // const processFormAccess = (err: any, data: Record<string, any>[]) => {
  //   let mapFormAccess = data?.reduce((accumulator: Record<string, any>, item: Record<string, any>) => {
  //     let formId = _get(item, "data.form");
  //     let access = _get(item, "data.access");
  //     if (formId && access) {
  //       try {
  //         accumulator[formId] = JSON.parse(access);
  //       } catch (e) {
  //         console.error(e);
  //       }
  //     }
  //     return accumulator;
  //   }, {});
  //   dispatch(setFormAccesses(mapFormAccess));
  // };
  // const processExcelMappings = (err: any, data: any) => {
  //   dispatch(setExcelMappings(data));
  // };
  // const processFormActions = (err: any, data: any) => {
  //   dispatch(setFormActionSubmissions(data));
  // };
  const processFormSubmissions = (formName:string, data: any) => { 
    dispatch(setSubmissions({ form: formName, submissions: data })); 
  }
  useEffect(() => {
    if (isAuthenticated) {
      let filters = [1, 1000, "formName", "asc", "", "form,resource"];
      fetchBPMFormList(...filters, fetchCallback)(dispatch);
    }
  }, [dispatch, isAuthenticated])
  const fetchCallback = (err: any, data: Record<string, any>) => { 
    let forms: Record<string,any> = {
      
    };
    if (data?.forms && data.forms.length > 0) {
      for (let ind = 0; ind < data.forms.length; ind++) {
        let form = data.forms[ind];
        let formName = form.formName || form.title;
        if (SYS_FORM_NAMES.includes(formName)) {
          forms[formName] = form;
        }
      }
      dispatch(setForms(forms));
      for (let formName in forms) {
        let form = forms[formName];
        if (form != null) {
          getSubmissions(form.formId, { limit: 9999 },
            (err: any, data: any) => {
              if (data) {
                processFormSubmissions(formName, data);
              }
           });
        }
      }
      // if (formMenu) {
      //   dispatch(setFormMenu(formMenu));
      //   getSubmissions(formMenu.formId, { limit: 1000}, processMenus);
      // }
      // if (formAccess) {
      //   dispatch(setFormAccess(formAccess));
      //   getSubmissions(formAccess.formId, { limit: data.forms.length }, processFormAccess);
      // }
      // if (formActions) {
      //   dispatch(setFormActions(formActions));
      //   getSubmissions(formActions.formId, { limit: data.forms.length }, processFormActions);
      // }
      // if (formExcelMapping) {
      //   dispatch(setFormExcelMapping(formExcelMapping));
      //   getSubmissions(formExcelMapping.formId, {limit: 1000}, processExcelMappings);
      // }
    }
  };
  return <FormsContext.Provider value={bpmForms}>{children}</FormsContext.Provider>;
}
FormsProvider.propTypes = {
  children: PropTypes.node,
};

export { FormsProvider };
