import moment from 'moment';
import moment_timezone from 'moment-timezone/moment-timezone';
import jtz from 'jstimezonedetect';
const getEnvVar = function(name) {
  return (window._env_ && window._env_[name]) 
  || process.env[name]
}
export const ASSETS_API = import.meta.env.VITE_ASSETS_API;
export const BASE_CONTEXT = getEnvVar("VITE_BASE_CONTEXT") || "";
// API
// ----------------------------------------------------------------------
export const BACKEND_API = import.meta.env.VITE_BACKEND_API;

export const APPLICATION_NAME =
  (window._env_ && window._env_.VITE_APPLICATION_NAME) ||
  process.env.VITE_APPLICATION_NAME ||
  "formsflow.ai";
export const APPLICATION_LOGO = window._env_?.VITE_APPLICATION_LOGO ||  process.env.VITE_APPLICATION_LOGO; 
export const FIREBASE_API = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APPID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: import.meta.env.VITE_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  callbackUrl: import.meta.env.VITE_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = import.meta.env.VITE_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = `${BASE_CONTEXT}/`; // as '/dashboard'

export const LIST_CONFIGS = {
  PAGE_SIZE: 50,
  PAGE_OPTIONS: [50,100,200]
}
export const FORM_MENU = "menu";
export const FORM_PAGE = "page";
export const FORM_ACTIONS = "formActions";
export const FORM_ACCESS = "formAccess";
export const FORM_EXCEL_MAPPING = "excelMapping";
export const FORM_MESSAGE_TRANSLATION = "messageTranslation";
export const SYS_FORM_NAMES = [
  FORM_MENU, FORM_PAGE, FORM_ACTIONS, FORM_ACCESS, FORM_EXCEL_MAPPING, FORM_MESSAGE_TRANSLATION
]
export const TEMPLATE_FILE = "templateFile";
export const AppConfig = {
  projectUrl: getEnvVar("VITE_FORMIO_API_PROJECT_URL"),
  apiUrl: getEnvVar("VITE_FORMIO_API_SERVER_URL"),
  formsflowApiUrl: getEnvVar("VITE_FORMSFLOW_API_URL"),
  backendApiUrl: getEnvVar("VITE_BACKEND_API_URL"),
};

export const currentTimezone = () => {
  if (moment_timezone.currentTimezone) {
    return moment_timezone.currentTimezone;
  }
  moment_timezone.currentTimezone = jtz.determine().name();
  return moment_timezone.currentTimezone;
}

export const metadata = {
  timezone: currentTimezone(),
  offset: parseInt(moment().utcOffset(), 10),
  origin: document.location.origin,
  referrer: document.referrer,
  userAgent: navigator.userAgent,
  pathName: window.location.pathname,
  onLine: navigator.onLine
}