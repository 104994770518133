import React, { useEffect, useState, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  selectRoot,
  resetSubmissions,
  saveSubmission,
  Form,
  selectError,
  Errors,
} from "@formio/react";
// import utils from "formiojs/utils";
import { Utils as FormioUtils } from "@formio/react";
import cloneDeep from "lodash/cloneDeep";
import extend from "lodash/extend";
import _get from "lodash/get";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { default as formio_resourceBundles } from "src/locales/formio";
import Loading from "src/components/containers/Loading";
import { onInitialized, onFormChange } from "./event";
import {
  setFormSubmissionError,
  setFormSubmissionLoading,
} from "src/actions/formActions";
import SubmissionError from "src/components/containers/SubmissionError";
import { getUserRolePermission } from "src/helper/user";
import {
  CLIENT,
  CUSTOM_SUBMISSION_URL,
  CUSTOM_SUBMISSION_ENABLE,
} from "src/constants";
import {
  CLIENT_EDIT_STATUS,
  UPDATE_EVENT_STATUS,
  getProcessDataReq,
} from "src/constants/applicationConstants";
import { updateApplicationEvent } from "src/apiManager/services/applicationServices";
import { toast } from "react-toastify";
import { Translation, useTranslation } from "react-i18next";
import { fetchSubmission, saveCustomSubmission } from "src/apiManager/services/FormServices";
import { FORM_ACTIONS } from 'src/config-global';
import SubmissionTable from "src/components/smartform/SubmissionTable";
import SubmissionActions from "../../../smartform/SubmissionActions";
import { cleanupSubmissionData, getCustomButtons, actionHandler } from "../../../Formio/components/helper";

const Create = React.memo((props) => {
  const {
    onSubmit,
    options,
    errors,
    onFormSubmit,
    onCustomEvent,
    onInitialized,
    onFormChange,
    form: { form, isActive: isFormActive },
    submission: { submission, isActive: isSubActive, url },
    refForm,
    refSubmission,
    refFormActions,
  } = props;
  const { formId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const lang = useSelector((state) => state.user.lang);
  const allActions = useSelector((state) => state.meta.submissions[FORM_ACTIONS]);
  const [submitButton, setSubmitButton] = useState();
  const [showModal, setShowModal] = useState(false);
  const [formModal, setFormModal] = useState();
  const formData = cloneDeep(form);
  const [initSubmission, setInitSubmission] = useState({ data: {} });
  const sessionId = searchParams.get("session");
  const refFormId = searchParams.get("refFormId");
  const refSubmissionId = searchParams.get("refSubmissionId");

  const createInitSubmission = (submission, form) => {
    let initSubmission = {
      data: {}
    };
    const flatternComponent = FormioUtils.flattenComponents(form.components, true);
    for (let field in flatternComponent) {
      let component = flatternComponent[field];
      if (component.data?.resource == submission?.form) {
        initSubmission.data[field] = submission;
      }
    }
    return initSubmission;
  };
  useEffect(() => { 
    if (form?.components) {
      if (!refSubmission) {
        if (refFormId && refSubmissionId) {
          let params = {
            formId: refFormId,
            submissionId: refSubmissionId,
            callback: (err, submission) => {
              let initSubmission = createInitSubmission(submission, form);
              setInitSubmission(initSubmission);
            }
          }
          fetchSubmission(params)
        } else if (sessionId) {
          let submission = useSelector((state) => {
            return state.session[sessionId] || {};
          });
          let initSubmission = createInitSubmission(submission, form);
          setInitSubmission(initSubmission);
        }
      } else {
        let initSubmission = createInitSubmission(refSubmission, form);
        setInitSubmission(initSubmission);
      }
    }
  }, [refSubmission, refFormId, refSubmissionId, form]);
  
  
  const actionContext = () => ({
    form: refForm, submissionId: refSubmission,
    navigate,
    dispatch,
    handleOpenDialog: (formModal, formActions) => { 
      setFormModal(formModal);
      setFormModalActions(formActions);
      setShowModal(true);
    }
    });
  
  // const form = useSelector((state) => state.form?.form);
  // const [formData, setFormData] = useState();
  // const [updatedSubmissionData, setUpdatedSubmissionData] = useState(initSubmission);

  const applicationStatus = useSelector(
    (state) => state.applications.applicationDetail?.applicationStatus || ""
  );
  const userRoles = useSelector((state) => {
    return selectRoot("user", state).roles;
  });
  const applicationDetail = useSelector(
    (state) => state.applications.applicationDetail
  );

  // const isFormSubmissionLoading = useSelector(
  //   (state) => state.formDelete.isFormSubmissionLoading
  // );

  const tenantKey = useSelector((state) => state.tenants?.tenantId);
  // const customSubmission = useSelector(
  //   (state) => state.customSubmission?.submission || {}
  // );

  useEffect(() => {
    // Check if the application is in "Resubmit" or "Awaiting Acknowledgement" status (old approach and it’s kept to have backward compatibility)
    // In the new approach, we will use the "isResubmit" key
    if (applicationStatus && !onFormSubmit) {
      if (
        getUserRolePermission(userRoles, CLIENT) &&
        !CLIENT_EDIT_STATUS.includes(applicationStatus) &&
        !applicationDetail.isResubmit
      ) {
        // Redirect the user to the submission view page if not allowed to edit
        // navigate(`/form/${formId}/submission/${submissionId}`);
      }
    }
  }, [
    applicationStatus,
    userRoles,
    dispatch,
    formId,
    onFormSubmit,
  ]);
  const handleSubmit = async (submission) => {
    //Todo: Store only reference to other resource (formId, submissionId)
    //setUpdatedSubmissionData(submission);
    const submissionPayload = { ...submission, data: cleanupSubmissionData(submission.data) };
    submissionPayload.data.creator = props.user?.name;
    // onSubmit(
    //   submissionPayload,
    //   applicationDetail,
    //   onFormSubmit,
    //   form._id,
    //   redirectUrl
    // );
    // dispatch(setFormSubmissionLoading(true));
    const callBack = (err, submission) => {
      if (!err) {
        if (
          UPDATE_EVENT_STATUS.includes(applicationDetail.applicationStatus) ||
          applicationDetail.isResubmit
        ) {
          const data = getProcessDataReq(applicationDetail, submission.data);
          dispatch(
            updateApplicationEvent(applicationDetail.id, data, () => {
              dispatch(resetSubmissions("submission"));
              dispatch(setFormSubmissionLoading(false));
              if (onFormSubmit) {
                onFormSubmit();
              } else {
                toast.success(
                  <Translation>{(t) => t("Submission Saved")}</Translation>
                );
                navigate(
                  //'../list'
                  `../${submission._id}`
                  // eslint-disable-next-line max-len
                  //`${BASE_CONTEXT}${redirectUrl}form/${formId}/submission/${submission._id}`
                  //`${BASE_CONTEXT}${redirectUrl}form/${formId}/submission`
                );
              }
            })
          );
        } else {
          dispatch(resetSubmissions("submission"));
          //dispatch(setFormSubmissionLoading(false));
          if (onFormSubmit) {
            onFormSubmit();
          } else {
            toast.success(
              <Translation>{(t) => t("Submission Saved")}</Translation>
            );
            navigate(
              '../list'
              // eslint-disable-next-line max-len
              //`${BASE_CONTEXT}${redirectUrl}form/${formId}/submission/${submission._id}/edit`
            );
          }
        }
      } else {
        //dispatch(setFormSubmissionLoading(false));
        const ErrorDetails = {
          modalOpen: true,
          message: (
            <Translation>
              {(t) => t("Submission cannot be done.")}
            </Translation>
          ),
        };
        toast.error(
          <Translation>{(t) => t("Error while Submission.")}</Translation>
        );
        dispatch(setFormSubmissionError(ErrorDetails));
      }
    };
    let components = FormioUtils.flattenComponents(form?.components);
    let extraParams = _get(components['submit'], "properties");
    if (extraParams?.customAction) {
      submissionPayload.extraParams = extraParams;
      saveCustomSubmission(
        submissionPayload,
        formId,
        callBack,
        extraParams.customAction
      );
    } else {
      if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
        saveCustomSubmission(
          submissionPayload,
          formId,
          callBack
        );
      } else {
        dispatch(
          saveSubmission(
            "submission",
            submissionPayload,
            formId,
            callBack
          )
        );
      }
    }
  }
  const onConfirm = () => {
    const ErrorDetails = { modalOpen: false, message: "" };
    dispatch(setFormSubmissionError(ErrorDetails));
  };
  // const updatedSubmission = (() => {
  //   if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
  //     return extend(cloneDeep(customSubmission), initSubmission);
  //   } else {
  //     return extend(cloneDeep(submission), initSubmission);
  //   }
  // }, [customSubmission, submission, initSubmission]);
  const updatedSubmission = (() => {
    return extend(cloneDeep(submission), initSubmission);
  }, [submission, initSubmission]);
  const renderSubGrids = () => {
    let subgrids = [];
    FormioUtils.eachComponent(form.components, (component) => {
      if (component.type == 'html') {
        let form = {};
        subgrids.push(<SubmissionTable form={refForm} />);
      }
    });
    return subgrids;
  };
  // if (isFormActive || (isSubActive && !isFormSubmissionLoading) || !updatedSubmission?.data) {
  //   return <Loading />;
  // }
  // console.log(updatedSubmissionData, updatedSubmission);
  // Không hiển thị action mở ra màn hình hiện tại
  const hideComponents = props.hideComponents
    ? [...props.hideComponents, ...getCustomButtons(form)]
    : getCustomButtons(form);
  const formActions = allActions.filter((action) => _get(action, "data.sourceForm.formId") == formId);
  return (
    <div className="container">
      <h4 className="text-truncate">{form.title}</h4>
      {/* <div className="main-header">
        <SubmissionError
          modalOpen={props.submissionError.modalOpen}
          message={props.submissionError.message}
          onConfirm={props.onConfirm}
        ></SubmissionError>
      </div> */}
      <Errors errors={errors} />
      <div className="ml-2 mr-2">
        <SubmissionActions
          page="create"
          form={form}
          formActions={formActions}
          actionHandler={(action, properties) => actionHandler(action, { ...actionContext(), ...properties })}/>
        <Form
          form={form}
          submission={initSubmission}
          url={url}
          hideComponents={hideComponents}
          onSubmit={handleSubmit}
          options={{
            ...options,
            i18n: formio_resourceBundles,
            language: lang,
          }}
          onInitialized={() => onInitialized(form, dispatch)}
          //onChange={(event) => onFormChange(form, event, dispatch)}
          onCustomEvent={onCustomEvent}
        />
      </div>
      <div>
        {renderSubGrids()}
      </div>
    </div>
  );
});

Create.defaultProps = {
  onCustomEvent: () => { },
  onInitialized,
  onFormChange
};

//export default Create;

const mapStateToProps = (state) => {
  const form = selectRoot("form", state);
  return {
    user: state.user.userDetail,
    form: form ? cloneDeep(form) : {},
    submission: selectRoot("submission", state),
    isAuthenticated: state.user.isAuthenticated,
    errors: [selectError("form", state), selectError("submission", state)],
    options: {
      noAlerts: false,
      i18n: {
        en: {
          error: (
            <Translation>
              {(t) => t("Please fix the errors before submitting again.")}
            </Translation>
          ),
        },
      },
    },
    submissionError: selectRoot("formDelete", state).formSubmissionError,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (
      submission,
      applicationDetail,
      onFormSubmit,
      formId,
      redirectUrl
    ) => {
      dispatch(setFormSubmissionLoading(true));
      const callBack = (err, submission) => {
        if (!err) {
          if (
            UPDATE_EVENT_STATUS.includes(applicationDetail.applicationStatus) ||
            applicationDetail.isResubmit
          ) {
            const data = getProcessDataReq(applicationDetail, submission.data);
            dispatch(
              updateApplicationEvent(applicationDetail.id, data, () => {
                dispatch(resetSubmissions("submission"));
                dispatch(setFormSubmissionLoading(false));
                if (onFormSubmit) {
                  onFormSubmit();
                } else {
                  toast.success(
                    <Translation>{(t) => t("Submission Saved")}</Translation>
                  );
                  navigate(
                    // eslint-disable-next-line max-len
                    //`${redirectUrl}form/${formId}/submission/${submission._id}`
                    `${redirectUrl}form/${formId}/submission`
                  );
                }
              })
            );
          } else {
            dispatch(resetSubmissions("submission"));
            dispatch(setFormSubmissionLoading(false));
            if (onFormSubmit) {
              onFormSubmit();
            } else {
              toast.success(
                <Translation>{(t) => t("Submission Saved")}</Translation>
              );
              navigate(
                // eslint-disable-next-line max-len
                `${redirectUrl}form/${ownProps.match.params.formId}/submission/${submission._id}/edit`
              );
            }
          }
        } else {
          dispatch(setFormSubmissionLoading(false));
          const ErrorDetails = {
            modalOpen: true,
            message: (
              <Translation>
                {(t) => t("Submission cannot be done.")}
              </Translation>
            ),
          };
          toast.error(
            <Translation>{(t) => t("Error while Submission.")}</Translation>
          );
          dispatch(setFormSubmissionError(ErrorDetails));
        }
      };
      if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
        saveCustomSubmission(
          submission,
          //onFormSubmit ? formId : ownProps.match.params.formId,
          formId,
          callBack
        );
      } else {
        dispatch(
          saveSubmission(
            "submission",
            submission,
            //onFormSubmit ? formId : ownProps.match.params.formId,
            formId,
            callBack
          )
        );
      }

    },
    onConfirm: () => {
      const ErrorDetails = { modalOpen: false, message: "" };
      dispatch(setFormSubmissionError(ErrorDetails));
    },
  };
};

export default connect(mapStateToProps)(Create);
