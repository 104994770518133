import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import _get from 'lodash/get';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FORM_ACCESS, FORM_ACTIONS, FORM_MENU, FORM_PAGE } from 'src/config-global';

// ----------------------------------------------------------------------
/*
 * This slice store working data on the form. 
 * This data is use for manipulation related forms or components
 */
const initialState: Record<string, any> = {
  forms: {},
  submissions: {},
  mapSubmissions: {},
  menuTree: [],
  formAccesses: {},
};
const MAP_FORMS = [FORM_MENU, FORM_PAGE, FORM_ACTIONS];
// const processFormAccess = (err: any, data: Record<string, any>[]) => {
//   let mapFormAccess = data?.reduce((accumulator: Record<string, any>, item: Record<string, any>) => {
//     let formId = _get(item, "data.form");
//     let access = _get(item, "data.access");
//     if (formId && access) {
//       try {
//         accumulator[formId] = JSON.parse(access);
//       } catch (e) {
//         console.error(e);
//       }
//     }
//     return accumulator;
//   }, {});
// };
const slice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setForms(state, action: PayloadAction<Record<string, any>>) {
      state.forms = action.payload;
    },
    setSubmissions(state, action: PayloadAction<Record<string, any>>) {
      const { form, submissions } = action.payload;
      if (MAP_FORMS.includes(form)) {
        state.mapSubmissions[form] = Array.isArray(submissions) ? submissions.reduce((map, item) => {
          map[item._id] = item;
          return map;
        }, {}) : {};
      } 
      if (FORM_ACCESS == form) {
        state.formAccesses = Array.isArray(submissions) ? submissions.reduce((accumulator, item) => {
          let formId = _get(item, "data.form");
          let access = _get(item, "data.access");
          if (formId && access) {
            try {
              accumulator[formId] = JSON.parse(access);
            } catch (e) {
              console.error(e);
            }
          }
          return accumulator;
        }, {}) : {};
      }
      state.submissions[form] = submissions;
    },
    setFormAccesses(state, action: PayloadAction<Record<string, String>>) {
      state.formAccesses = action.payload;
    },
    setMenuTree(state, action: PayloadAction<Record<string, any>[]>) {
      state.menuTree = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setForms, setSubmissions,
  setFormAccesses,
  setMenuTree, 
  // setMenus, setExcelMappings, setFormActionSubmissions,
} = slice.actions;
