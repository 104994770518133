export default [
  {
    key: 'multiple',
    ignore: true
  },
  {
    key: 'defaultValue',
    ignore: true
  },
  {
    type: 'select',
    input: true,
    dataSrc: 'url',
    data: {
      url: '/form?type=resource&limit=1000000',
    },
    authenticate: true,
    template: '<span>{{ item.title }}</span>',
    valueProperty: '_id',
    clearOnHide: false,
    label: 'Resource',
    key: 'data.resource',
    lazyLoad: false,
    weight: 10,
    tooltip: 'The resource to be used with this field.',
  },
  {
    type: 'datagrid',
    input: true,
    label: 'Data Source Filters',
    key: 'data.filters',
    tooltip: 'Filters to apply to the data source.',
    weight: 10,
    reorder: true,
    defaultValue: [{ filter: '', expression: '' }],
    components: [
      {
        label: 'Filter',
        key: 'filter',
        input: true,
        type: 'textfield',
      },
      {
        label: 'Expression',
        key: 'expression',
        input: true,
        type: 'textfield',
        allowCalculateOverride: true,
        calculateValue: 'value = _.camelCase(row.filter);',
      },
    ],
  },

];
