import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState: Record<string, any> = {
  
};

const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    getSession(state, action) {
      console.log(action);
    },
    setSession(state, action) {
      const {id, value } = action.payload;
      if (id) {
        state[id] = value;
      }
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getSession,
  setSession
} = slice.actions;
