import React, { useEffect, useReducer, useRef, useState } from "react";

import { Translation, useTranslation } from "react-i18next";
import { 
  getSubmissions
} from 'src/apiManager/services/FormServices';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep";
import _camelCase from "lodash/camelCase";
import { ISubmission } from "src/types/form";
import { InputNumber } from "primereact/inputnumber";

interface IExcelUploadProps {
  excelMappings: Record<string, any>[],
  sheetNames: string[],
  onSubmit: Function,
  refSubmission: ISubmission
}
// ----------------------------------------------------------------------
const ExcelUploadOption = React.memo((props: IExcelUploadProps) => {
  const { t } = useTranslation();
  const { excelMappings, sheetNames, onSubmit, refSubmission  } = props;
  const [sheetName, setSheetName] = useState(_get(sheetNames,"0"));
  const [mapping, setMapping] = useState();
  const [header, setHeader] = useState(1);
  const [firstRow, setFirstRow] = useState(1);
  const [submissions, setSubmissions] = useState();
  const [master, setMaster] = useState(refSubmission);
  const [masterRefField, setMasterRefField] = useState();
  const onConfirm = () => {
    onSubmit(sheetName, { mapping, master, firstRow, header });
  }
  const optionsHeader = [
    {
      value: 1,
      label: "Default"
    },
    {
      value: "A",
      label: "Use column name's (A,B,C...) as field name"
    }
  ];
  const onChangeMapping = async (event: DropdownChangeEvent) => { 
    let mapping = event.value;
    setMapping(mapping);
    let fistMasterMappingFieldValue = _get(mapping, "data.masterMapping[0].masterField.label");
    setMasterRefField(fistMasterMappingFieldValue);
    let masterFormId = _get(mapping, "data.masterForm.formId");
    let masterSubmissions = [];
    if (masterFormId) {
      let params = _get(mapping, "data.params");
      let paramsJson = {};
      if (params) {
        try {
          paramsJson = JSON.parse(params);
        } catch (e) {

        }
      }
      masterSubmissions = await getSubmissions(masterFormId, paramsJson);
    }
    setSubmissions(masterSubmissions);
  }

  return (
    <>
      <div className="container">
        <div className="grid">
          <div className="col-12 md:col-12 p-fluid">
            <div className="field grid">
                <label htmlFor="sheetName" className="col-12 mb-2 md:col-4 md:mb-0 field-required">
                    {t('config.excel_sheet_name')}
                </label>
                <div className="col-12 md:col-8">
                    <Dropdown id="sheetName" 
                      value={sheetName}                           
                      onChange={(event) => setSheetName(event.value)} 
                      options={sheetNames}
                    />
                </div>
            </div>
            <div className="field grid">
              <label htmlFor="mapping" className="col-12 mb-2 md:col-4 md:mb-0">
                  {t('config.excel_mapping')}
              </label>
              <div className="col-12 md:col-8">
                <Dropdown id="mapping" 
                  showClear
                  value={mapping}                           
                  onChange={onChangeMapping} 
                  options={excelMappings} optionLabel="data.configName"
                />
              </div>
            </div>
            {!mapping &&
              (<>
                <div className="field grid">
                  <label htmlFor="firstRow" className="col-12 mb-2 md:col-4 md:mb-0d">
                      {t('config.excel_first_row')}
                  </label>
                  <div className="col-12 md:col-8">
                      <InputNumber id="firstRow" 
                        type="number"
                        value={firstRow}                           
                        onChange={(event) => setFirstRow(event.value || 1)}                       
                      />
                  </div>
              </div>
              <div className="field grid">
                  <label htmlFor="header" className="col-12 mb-2 md:col-4 md:mb-0d">
                      {t('config.excel_header')}
                  </label>
                <div className="col-12 md:col-8">
                    <Dropdown id="header" 
                      value={header}                           
                      onChange={(event) => setHeader(event.value)} 
                      options={optionsHeader} optionLabel="label" optionValue="value"
                    />
                      {/* <InputText id="header" 
                        value={header}                           
                        onChange={(event) => setHeader(event.value)}                       
                      /> */}
                  </div>
                </div>
              </>)
            }
            {mapping &&
              (<div className="field grid">
                <label htmlFor="mapping" className="col-12 mb-2 md:col-4 md:mb-0">
                  {masterRefField}
                </label>
                <div className="col-12 md:col-8">
                  <Dropdown id="master"
                    value={master}
                    onChange={(event) => setMaster(event.value)}
                    options={submissions} optionLabel={_get(mapping, "data.masterOptionLabel")}
                  />
                </div>
              </div>)
            }
          </div>
          <div>
            <Button label={t('button.submit')} onClick={onConfirm} />
          </div>
        </div>
      </div>
    </>
  );
})

export default ExcelUploadOption;