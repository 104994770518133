import React, { useEffect, useRef, useState } from "react";
import _get from "lodash/get";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  CUSTOM_SUBMISSION_URL,
  CUSTOM_SUBMISSION_ENABLE,
  MULTITENANCY_ENABLED,
} from "src/constants";
import { loadFormio, getSubmissions, FormSubmissionParams, fetchSubmission } from "src/apiManager/services/FormServices";
import { IFormio, ISubmission } from "src/types/form";
import { FORM_ACTIONS } from "src/config-global";
import XlsxSubmission from "src/components/smartform/XlsxSubmission";
import "src/assets/styles/print.css";
import * as XLSX from 'xlsx';
import { Button } from "primereact/button";
import { useReactToPrint } from "react-to-print";
import { Toolbar } from "primereact/toolbar";
import { Paper } from "@mui/material";
interface IXlsxBindingProps {
  dispatch?: Function,
  form?: IFormio,
  formId?: string,
  submissions?: Record<string, any>
}
const XlsxBinding = (props: IXlsxBindingProps) => {
  const {
    form
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const printComponentRef = useRef(null);
  const { formId, submissionId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const tenantKey = useSelector((state: Record<string, any>) => state.tenants?.tenantId);
  const tenantUrl = MULTITENANCY_ENABLED ? `/tenant/${tenantKey}` : "";
  const formActionSubmissions = useSelector((state: Record<string, any>) => state.meta.submissions[FORM_ACTIONS]);
  const [submissionForm, setSubmissionForm] = useState<IFormio>();
  const [submisions, setSubmissions] = useState<ISubmission[]>();
  const [xlsxTemplate, setXlsxTemplate] = useState<XLSX.WorkBook>();
  const isFormSubmissionLoading = useSelector(
    (state: Record<string, any>) => state.formDelete.isFormSubmissionLoading
  );

  const customSubmission = useSelector(
    (state : Record<string, any>) => state.customSubmission?.submission || {}
  );
  const actionId = searchParams.get("actionId");
  const templateFormId = searchParams.get("templateFormId");
  const templateSubmissionId = searchParams.get("templateSubmissionId");
  const fieldName = searchParams.get("fieldName");
  const submissionIds = searchParams.get("submissionIds");

  useEffect(() => { 
    if (form) {
      setSubmissionForm(form);
    } else if (formId) {
      loadFormio(formId, (err: any, form: IFormio) => {
        setSubmissionForm(form);
      })
    }
  }, [formId, form]);
  useEffect(() => { 
    if (submissionForm && (submissionIds?.length || 0) > 0) {
      let params = {
        query: {
          _id__in: submissionIds
        }
      };
      getSubmissions(submissionForm._id, params,
        (err: any, submisions: ISubmission[]) => {
          setSubmissions(submisions);
        }
      )
    }
  }, [submissionForm, submissionIds])
  const loadTemplate = async (formId: string, submissionId: string, fieldName: string) => { 
    let params: FormSubmissionParams = {
      formId,
      submissionId
    }
    let res = await fetchSubmission(params);
    let files = _get(res.data, `data.${fieldName}`, []);
    let file = files[0];
    if (file?.originalName && file?.url) {
      let parts = file.url.split(";base64,");
      let parserOptions = {
        cellDates: true,
        cellStyles: true,
        UTC: true
      }
      const data = XLSX.read(parts[1], parserOptions);
      setXlsxTemplate(data);
    }
  }
  //Load xlsx template
  useEffect(() => { 
    if (templateFormId && templateSubmissionId && fieldName) {
      loadTemplate(templateFormId, templateSubmissionId, fieldName);
    }
  }, [templateFormId, templateSubmissionId, fieldName])
  const bindSubmission = (submission: ISubmission) => { 
    return <XlsxSubmission key={submission._id}
      form={submissionForm}
      xlsxTemplate={xlsxTemplate}
      submission={submission}/>
  }
  const handlePrint = useReactToPrint({
        //documentTitle: "",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        content: () => {
            return printComponentRef.current
        },
        removeAfterPrint: true,
  });
  const startButtons = () => ( 
    <>
      <Button size="small"
                label={t('button.back')}
                icon="pi pi-chevron-left" className="mr-1"
                onClick={() => navigate(-1)} />
      <Button size="small"
                label={t('button.print')}
                icon="pi pi-print" className="mr-1"
                onClick={handlePrint} />
    </>
  )
  return (
    <div className="container">
      <Toolbar start={startButtons} />
      <div ref={ printComponentRef }>
        {submissionForm && xlsxTemplate && submisions ?
            submisions.map(bindSubmission) : null}
      </div>
    </div>
  );
};

export default XlsxBinding;

