
const getEnvVar = function(name) {
  return (window._env_ && window._env_[name]) ||
  process.env[name]
}
export const API_SERVER_URL = getEnvVar('VITE_API_SERVER_URL');
export const SMARTFORM_API_URL = getEnvVar('VITE_SMARTFORM_API_URL');
export const WEB_BASE_URL = getEnvVar('VITE_WEB_BASE_URL');

export const DOCUMENT_SERVICE_URL =
  (window._env_ && window._env_.VITE_DOCUMENT_SERVICE_URL) ||
  process.env.VITE_DOCUMENT_SERVICE_URL;

export const WEB_BASE_CUSTOM_URL =
  (window._env_ && window._env_.VITE_WEB_BASE_CUSTOM_URL) ||
  process.env.VITE_WEB_BASE_CUSTOM_URL;


export const CUSTOM_SUBMISSION_URL =
  (window._env_ && window._env_.VITE_CUSTOM_SUBMISSION_URL) ||
  process.env.VITE_CUSTOM_SUBMISSION_URL;

 export const BPM_BASE_URL_EXT = `${
   (window._env_ && window._env_.VITE_BPM_URL) ||
   process.env.VITE_BPM_URL
 }/engine-rest-ext`;

export const BPM_API_URL_WITH_VERSION = `${BPM_BASE_URL_EXT}/v1`;

export const BPM_BASE_URL_SOCKET_IO = `${
  (window._env_ && window._env_.VITE_BPM_URL) ||
  process.env.VITE_BPM_URL
}/forms-flow-bpm-socket`;

export const MT_ADMIN_BASE_URL = `${
  (window._env_ && window._env_.VITE_MT_ADMIN_BASE_URL) ||
  process.env.VITE_MT_ADMIN_BASE_URL
}`;

export const MT_ADMIN_BASE_URL_VERSION = `${
  (window._env_ && window._env_.VITE_MT_ADMIN_BASE_URL_VERSION) ||
  process.env.VITE_MT_ADMIN_BASE_URL_VERSION ||
  "v1"
}`;

export const KEYCLOAK_ENABLE_CLIENT_AUTH =
  (window._env_ && window._env_.VITE_KEYCLOAK_ENABLE_CLIENT_AUTH) ||
  process.env.VITE_KEYCLOAK_ENABLE_CLIENT_AUTH;
