import { Translation } from "react-i18next";
import { getCustomSubmissions, getSubmissions, loadForm } from "src/apiManager/services/FormServices";
import { FORM_ACTIONS } from "src/config-global";
import { CUSTOM_SUBMISSION_ENABLE, CUSTOM_SUBMISSION_URL } from "src/constants";

const replaceUrl = (URL, key, value) => {
  return URL.replace(key, value);
};
const parseRange = (range) => { 
  let result = {
    start: 0,
    end: 0,
    total: 0
  };
  if (range) {
    try {
      let parts = range ? range.split('/') : [];
      if (parts.length >= 2) {
        result.total = parseInt(parts[1]);
      }
      if (parts.length >= 1) {
        let pages = parts[0].split('-');
        if (pages.length == 2) {
          result.start = parseInt(pages[0]);
          result.end = parseInt(pages[1]);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
  return result;
}
const addTenantkey = (value, tenantkey) => {
  const tenantKeyCheck = value.match(`${tenantkey}-`);
  if (
    tenantKeyCheck &&
    tenantKeyCheck[0].toLowerCase() === `${tenantkey.toLowerCase()}-`
  ) {
    return value.toLowerCase();
  } else {
    return `${tenantkey.toLowerCase()}-${value.toLowerCase()}`;
  }
};

const removeTenantKey = (value, tenantkey) => {
  const tenantKeyCheck = value.match(`${tenantkey}-`);
  if (
    tenantKeyCheck &&
    tenantKeyCheck[0].toLowerCase() === `${tenantkey.toLowerCase()}-`
  ) {
    return value.replace(`${tenantkey.toLowerCase()}-`, "");
  } else {
    return false;
  }
};

const textTruncate = (wordLength, targetLength, text) => {
  return text?.length > wordLength
    ? text.substring(0, targetLength) + "..."
    : text;
};

const renderPage = (formStatus, processLoadError) => {
  if (!processLoadError && (formStatus === "inactive")) {
    return (
      <span>
        <div
          className="container"
          style={{
            maxWidth: "900px",
            margin: "auto",
            height: "50vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3>{<Translation>{(t) => t("Form not published")}</Translation>}</h3>
          <p>{<Translation>{(t) => t("You can't submit this form until it is published")}</Translation>}</p>
        </div>
      </span>
    );
  } 
};
const loadFormActions = async (formId, callback = (err, submissions)=>{}) => {
  let formAction = await loadForm(FORM_ACTIONS);
  if (formAction) {
    const params = {
      query : {
        'data.sourceForm': formId,
      }
    };
    if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
      return getCustomSubmissions(formAction.formId, params, callback);
    } else {
      return getSubmissions(formAction.formId, params, callback);
    }
  }
}
export { replaceUrl, addTenantkey, removeTenantKey, textTruncate, renderPage, loadFormActions, parseRange };
