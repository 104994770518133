import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Translation, useTranslation } from 'react-i18next';
import { Utils } from 'formiojs';
import {
  deleteSubmission,
  getComponentDefaultColumn,
  saveSubmission,
  setColumnsWidth,
  stopPropagationWrapper,
} from '@formio/react';
import _ from 'lodash';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isFunction from 'lodash/isFunction';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import { Button } from 'primereact/button';
import { Column, ColumnEditorOptions, ColumnProps } from 'primereact/column';
import { DataTable, DataTableProps, DataTableRowEditCompleteEvent, DataTableSortMeta, DataTableStateEvent } from 'primereact/datatable';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import Confirm from "src/components/containers/Confirm";
import { actionHandler, cleanupSubmissionData } from "src/components/Formio/components/helper";
import * as XLSX from 'xlsx';
import { Field, QueryBuilder, RuleGroupType, RuleType, formatQuery, isRuleGroupType } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';

import {
  CUSTOM_SUBMISSION_URL,
  CUSTOM_SUBMISSION_ENABLE,
  PageSizes as DefaultPageSizes,
  ACCESS_FULL,
  STAFF_DESIGNER,
  STAFF_REVIEWER,
  MULTITENANCY_ENABLED,
  ACTION_CREATE_OWN,
  ACTION_READ_ALL,
  ACTION_READ_OWN,
  ACTION_UPDATE_ALL,
  ACTION_UPDATE_OWN,
  ACTION_DELETE_ALL,
  ACTION_DELETE_OWN,
  ACTION_PREVIEW_FORM,
  ACTION_XLSX_BINDING,
} from "src/constants";

import {
  getCustomSubmissions,
  uploadSubmissions,
  deleteCustomSubmission,
  loadForm,
  loadFormio,
  deleteSubmissions,
  fetchSubmission,
  FormSubmissionParams,
  saveCustomSubmission,
} from "src/apiManager/services/FormServices";
import ExcelUploadOption from './ExcelUploadOptions';
import { BASE_CONTEXT } from 'src/config-global';
import { createButton, handleCustomAction } from './SubmissionActions';
import { useSearchParams } from 'react-router-dom';
import { IButtonOption, IComponent, IFormio, ISubmission } from 'src/types/form';
import { Icon } from '@mui/material';
import { ChangeEvent } from 'preact/compat';
import { IContext } from 'src/types/common';
import { LeakAddTwoTone } from '@mui/icons-material';
import FormImage from './FormImage';
import FormContainer from './FormContainer';
import { createUrlSubmissionPreview, createUrlSubmissionXlsxBinding } from 'src/utils/meta';
import { Panel } from 'primereact/panel';
import SmartQueryBuilder from './SmartQueryBuilder';
import { kMaxLength } from 'buffer';
import { de, ru } from 'date-fns/locale';
import moment from 'moment';

interface IColumn {
  key: string;
  headerStyle?: React.CSSProperties;
  sort: (boolean | string | Function);
  title: string;
  value: Function;
  width: number;
  component?: any;
}
interface ISubmissionProps {
  dispatch?: Function,
  columns?: IColumn[],
  resolveSubmissionOperation?: Function,
  pageSizes: number[],
  form: IFormio,
  formId: string,
  formActions?: ISubmission[],
  refFormAction: ISubmission,
  refSubmission: ISubmission,
  filters: Record<string, any>,
  builderMode: boolean,
  submissions?: Record<string, any>,
  getSubmissions?: Function,
  onPageSizeChanged?: Function,
  onAction?: Function,
  onCreate: Function,
  pageConfig?: ISubmission,
  params?: Record<string, any>
}
interface IOperation {
  action: string,
  buttonType: string,
  icon?: string,
  title?: string,
  onAction?: Function,
  submission?: ISubmission,
  permissionsResolver?: Function
}
const defaultSubmissionOperations: IOperation[] = [
  {
    action: 'view',
    buttonType: 'warning',
    icon: 'list-alt',
    permissionsResolver() {
      return true;
    },
    title: 'View',
  },
  {
    action: 'edit',
    buttonType: 'secondary',
    icon: 'edit',
    permissionsResolver() {
      return true;
    },
    title: 'Edit',
  },
  {
    action: 'delete',
    buttonType: 'danger',
    icon: 'trash',
    permissionsResolver() {
      return true;
    },
  },
];

const SubmissionTable = React.memo((props: ISubmissionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = props?.dispatch || useDispatch();
  const {
    columns: propColumns = [],
    resolveSubmissionOperation = () => { },
    //operations = defaultSubmissionOperations,
    pageSizes = DefaultPageSizes,
    formId,
    formActions,
    refFormAction,
    refSubmission,
    filters: compFilters,
    builderMode = false,
    getSubmissions = () => { },
    onPageSizeChanged = () => { },
    onAction = () => { },
    onCreate,
    pageConfig,
    params = {}
  } = props;

  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [columnFilters, setColumnFilters] = useState({});
  const [total, setTotal] = useState(0);
  const defaultParams = {
    page: 1,
    limit: DefaultPageSizes[0],
    query: { ...compFilters, ...params }
  }
  // const [queryParams, setQueryParams] = useState(defaultParams);
  const defaultSort = "-created";
  const userRoles = useSelector((state: Record<string, any>) => state.user.roles);
  const userDetail = useSelector((state: Record<string, any>) => state.user.userDetail);
  const formAccesses = useSelector((state: Record<string, any>) => state.meta.formAccesses);
  const formActionSubmissions = useSelector((state: Record<string, any>) => state.meta.submissions.formActions);
  const excelMappings = useSelector((state: Record<string, any>) => state.meta.submissions.excelMapping);
  const [formPerms, setFormPerms] = useState<Record<string, boolean>>({});
  const [form, setForm] = useState<IFormio>(props.form);
  const [buttons, setButtons] = useState<Record<string, any>>({});
  const [displayColumns, setDisplayColumns] = useState<IColumn[]>([]);
  const [selectedSubmissions, setSelectedSubmissions] = useState<ISubmission[]>([]);
  const [submissions, setSubmissions] = useState(props.submissions?.submissions || []);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<boolean>(false);
  const [multipleSelection, setMultipleSelection] = useState(false);
  const [workingSubmission, setWorkingSubmission] = useState<ISubmission>();
  const [sortMeta, setSortMeta] = useState<DataTableSortMeta[] | null | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(defaultParams.page - 1);
  const [rows, setRows] = useState(defaultParams.limit);
  const [showOption, setShowOption] = useState(false);
  const [uploadData, setUploadData] = useState<XLSX.WorkBook>();
  const [smartFilter, setSmartFilter] = useState<string>();
  const [resourceFilters, setResourceFilters] = useState({});
  const [resourceLoading, setResourceLoading] = useState({});
  const [resources, setResources] = useState<Record<string, any>>({});
  const [showModal, setShowModal] = useState(false);
  const [formModal, setFormModal] = useState<IFormio>();
  const [formModalActions, setFormModalActions] = useState();
  const [modalSubmission, setModalSubmission] = useState<Record<string, any>>();
  const toast = useRef<Toast>(null);
  const rowPopupMenu = useRef<Menu>(null);
  const [rowMenuItems, setRowMenuItems] = useState<MenuItem[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const refSubmissionId = searchParams.get("refSubmission");
  const [collapsedAdvancedSearch, setCollapsedAdvancedSearch] = useState<boolean>(true);
  useEffect(() => {
    if (props.form) {
      initiate(props.form);
    }
  }, [props.form]);
  useEffect(() => {
    if (!props.form && props.formId) {
      fetchForm(props.formId);
    }
  }, [props.form, props.formId]);
  useEffect(() => {
    if (form && (!refSubmissionId || refSubmission) && !builderMode) {
      fetchSubmissions();
      const access = formAccesses[form._id];
      if (access && userRoles) {
        let formPerms = access?.reduce((accumulator: Record<string, any>, item: Record<string, any>) => {
          for (let ind = 0; ind < userRoles.length; ind++) {
            if (item[userRoles[ind]]) {
              accumulator[item.action] = true;
              break;
            }
          }
          return accumulator;
        }, {});
        setFormPerms(formPerms);
      }
    }
  }, [form, refFormAction, refSubmission, sortMeta, currentPage, rows, columnFilters, builderMode, smartFilter])
  const clearFilter = () => { 
    initiate(form)
  }
  const initiate = (form: IFormio) => { 
    setForm(form);
    let filters: Record<string,any> = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    };
    const columns: IColumn[] = [];
    const btnActions: Record<string, any> = {};
    //First list all hide from list components
    const hiddenComponents = new Set();
    Utils.eachComponent(form?.components, (component: IComponent) => {
      if (component.type == 'button' && component.action == 'custom') {
        btnActions[component.key] = component;  
      }
      if (component.tableView == false || component.hidden == true || component.properties?.mainTableView == 'false') {
        Utils.eachComponent([component], (child: IComponent) => {
          if (child.input && child.properties?.mainTableView != 'true' && child.key) {
            hiddenComponents.add(child.id);
          }
        })
      }
    });
    setButtons(btnActions);
    Utils.eachComponent(form?.components, (component: IComponent) => {
      let mainTableView = component.properties?.mainTableView;
      if (component.input && component.tableView && !hiddenComponents.has(component.id) && component.key) {
        let defaultCol = getComponentDefaultColumn(component);
        columns.push({width: 0, ...defaultCol});
      }
    });
    setColumnsWidth(columns);
    setDisplayColumns(columns);

    columns.forEach((column) => {
      filters[column.key] = initColumnFilter(column);

      let resourceId = _get(column.component, "component.data.resource");
      let linkField = _get(column.component, "component.properties.linkField");
      if (!resourceId && linkField) {
        let comps = Utils.searchComponents(form.components, {
          'key': linkField
        });
        if (comps.length == 1) {
          resourceId = _get(comps[0], "data.resource");
          if (resourceId) {
            _set(column.component, "component.properties.resourceId", resourceId);
          }
        }
      }
    });
    setFilters(filters);
  }
  const initColumnFilter = (column: IColumn) => { 
    let component = _get(column.component, "component");
    let operator = FilterOperator.AND;
    let matchMode = FilterMatchMode.CONTAINS;
    let value = null;
    switch (component.type) {
      case "datetime":
        matchMode = FilterMatchMode.DATE_IS;
        break;
      case "number":
        matchMode = FilterMatchMode.EQUALS;
        break;
      case "select": 
        matchMode = FilterMatchMode.EQUALS;
        break;
    }
    return {
      operator,
      constraints: [{ value, matchMode }]
    };
  }
  const fetchForm = async (formId: string) => {
    loadFormio(formId, (err: any, form: IFormio) => {
        initiate(form);
    })
  }
  const fetchSubmissions = (query = {
    //"data.applicationId__regex": "/^\d+$/" 
  }) => {
    const formId = form?._id;
    const queryParams = createQueryParams();
    const callback = (err: any, submissions: ISubmission[], range?: Record<string, number>) => {
      setSubmissions(submissions);
      setTotal(range?.total || submissions?.length || 0);
    };
    if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
      getCustomSubmissions(formId!, queryParams, callback)
    } else {
      getSubmissions(formId, queryParams, callback)
    }
  };
  const createQueryParams = () => { 
    let sorts = sortMeta?.map(item => item.order == 1 ? item.field : "-" + item.field) || [];
    sorts.push(defaultSort);
    let queryParams: Record<string, any> = {
      limit: rows,
      page: currentPage + 1,
      skip: currentPage * rows,
      query: { ...compFilters, ...params, ...columnFilters, 
        sort: sorts.join(",")
      }
    };
    //Query from advanced smart filter
    if (smartFilter) {
      queryParams.query['smartFilter'] = smartFilter;
    }
    if (refFormAction && refSubmission) {
      let parameters = _get(refFormAction, "data.parameters", []);
      for (let param of parameters) {
        if (param.name == 'refField' && param.value) {
          queryParams.query[`data.${param.value}._id`] = refSubmission._id;
        }
      }
    }
    return queryParams;
    // if (params.limit != queryParams.limit
    //   || params.page != queryParams.page
    //   || params.query?.sort != queryParams.query?.sort) {
    //   setQueryParams(params);
    // }
  }
  // const getSortQuery = (orderEvent) => {
  //   let orders = orderEvent.multiSortMeta;
  //   if (orderEvent.sortField != null && orderEvent.sortOrder != null) {
  //       orders = [{field:orderEvent.sortField, order: orderEvent.sortOrder}]
  //   }
  //   if (Array.isArray(orders)) {
  //       return orders.map(item =>  item.order == 1 ?  item.field : "-" + item.field).join(",");
  //   }
  // };
  //https://stackoverflow.com/questions/76482922/server-side-sort-with-primereact-datatable
  const handleSort = (event: DataTableStateEvent) => {
    setSortMeta(event.multiSortMeta);
    setCurrentPage(0);
  };
  const _createColumnFilters = (filters: Record<string, any>) => { 
    let columnFilters: Record<string, any> = {};
    for (let key in filters) {
      if (key == 'global') continue;
      let colFilter = filters[key];
      if (colFilter.constraints?.length > 0) {
        let value = colFilter.constraints[0].value;
        if (value) {
          if (typeof value === 'string' || value instanceof String) {
            value = value.trim();
          }
          switch (colFilter.constraints[0].matchMode) {
            case FilterMatchMode.CONTAINS:
              columnFilters[`${key}__regex`] = `/${value}/i`;
              break;
            case FilterMatchMode.EQUALS:
              columnFilters[key] = value;
              break;
            case FilterMatchMode.NOT_EQUALS:
              columnFilters[`${key}__ne`] = value;
              break;
            case FilterMatchMode.GREATER_THAN:
              columnFilters[`${key}__gt`] = value;
              break;
            case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO:
              columnFilters[`${key}__gte`] = value;
              break;
            case FilterMatchMode.LESS_THAN:
              columnFilters[`${key}__lt`] = value;
              break;
            case FilterMatchMode.LESS_THAN_OR_EQUAL_TO:
              columnFilters[`${key}__lte`] = value;
              break;
            case FilterMatchMode.IN:
              columnFilters[`${key}__in`] = value;
              break;
            case FilterMatchMode.DATE_IS:
              columnFilters[key] = value;
              break;
            case FilterMatchMode.DATE_BEFORE:
              columnFilters[`${key}__lte`] = value;
              break;
            case FilterMatchMode.DATE_AFTER:
              columnFilters[`${key}__gte`] = value;
              break;
            default:
              columnFilters[key] = value
              break;
          }
        }
      }
    }
    return columnFilters;
  }
  const handleFilter = (event: DataTableStateEvent) => { 
    setFilters(event.filters);
    let columnFilters = _createColumnFilters(event.filters);
    setColumnFilters(columnFilters);
  }

  const handlePaging = (event: DataTableStateEvent) => {
    setCurrentPage(event.page || 0);
    setRows(event.rows);
  }
  const handleColumnFilter = (col: Record<string, any>, options: Record<string, any>, value: any) => { 
    options.filterCallback(value, options.index);
    let colFilter = filters[col.key];
    let constraints = colFilter.constraints.map((item: any, ind: number) => { 
      if (ind == options.index) {
        return { ...item, value };
      } else {
        return item;
      }
    });
    let newFilters = { ...filters, [col.key]: {...colFilter, constraints} }
    setFilters(newFilters);
  }
  const handleColumnResourceFilter = (col: IColumn, options: Record<string, any>, value: any) => { 
    let comp = col.component;
    let resource = _get(comp, "component.data.resource");
    setResourceFilters({ ...resourceFilters, [resource]: value });
    let template = comp.component?.template;
    if (template) {
      const regex = /(\{\{.+\}\})/g;
      let groups = template.match(regex);
      groups = groups.map((group: any) => group.substring(2, group.length - 2).trim());
    }
  }
  const clearColumnFilter = (col: IColumn) => { 
    let newFilters = { ...filters, [col.key]: initColumnFilter(col) }
    let columnFilters = _createColumnFilters(newFilters);
    setColumnFilters(columnFilters);
    setFilters(newFilters);
  }
  const onRowAction = (submission: ISubmission, action: string) => {
    switch (action) {
      case "delete": {
        setWorkingSubmission(submission);
        setOpenDeleteConfirm(true);
        break;
      }
      // case "viewSubmission":
      //   navigate(
      //     `${root}/${submission._id}`
      //   );
      //   break;
      // case "edit":
      //   navigate(
      //     `${root}/${submission._id}/edit`
      //   );
      //   break;
      default:
        onAction(submission, action);
    }
  };
  const onDeleteCancel = () => { 
    setWorkingSubmission(undefined);
    setOpenDeleteConfirm(false);
  }
  const onDeleteConfirm = async () => { 
    const formId = form?._id
    if (multipleSelection && selectedSubmissions?.length > 0) {
      //delete all selections
      const payload: string[] = selectedSubmissions.map((item: ISubmission) => item._id);
      const callback = (err: any, submissions: ISubmission[]) => {
          setOpenDeleteConfirm(false);
          setWorkingSubmission(undefined);
          setSelectedSubmissions([]);
          fetchSubmissions();
      }
      deleteSubmissions(formId!, payload, callback);
    } else {
      const submissionId = workingSubmission?._id
      if (formId && submissionId) {
        const callback = (err: any) => {
          if (!err) {
            setWorkingSubmission(undefined);
            setOpenDeleteConfirm(false);
            fetchSubmissions();
          }
        };
        if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
          dispatch(deleteCustomSubmission(submissionId, formId, callback));
        } else {
          dispatch(
            deleteSubmission("submission", submissionId, formId, callback as () => void)
          );
        }
      }
    }
  }
  const onGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilterValue(event.target?.value || "");
  };
  const doApiFilter = (event: React.FocusEvent<HTMLInputElement>) => { 
    for (let ind = 0; ind < displayColumns.length; ind++) {
      let column = displayColumns[ind]; 
      console.log(column);
    }
    //console.log(searchTextInput);
  }
  const suggestDisplayColumns = (form: IFormio) => {
    const columns: any[] = [];
    //First list all hide from list components
    const hiddenComponents = new Set();
    Utils.eachComponent(form?.components, (component: IComponent) => {
      if (component.tableView == false || component.hidden == true || component.properties?.mainTableView == 'false') {
        Utils.eachComponent([component], (child: IComponent) => {
          if (child.input && child.properties?.mainTableView != 'true' && child.key) {
            hiddenComponents.add(child.id);
          }
        })
      }
    });

    Utils.eachComponent(form?.components, (component: IComponent) => {
      let mainTableView = component.properties?.mainTableView;
      if (component.input && component.tableView && !hiddenComponents.has(component.id) && component.key) {
        columns.push(getComponentDefaultColumn(component));
      }
    });
    setColumnsWidth(columns);
    setDisplayColumns(columns);
    return columns;
  };

  const Icon = (props: Record<string, any>) => (
    <span>
      <i className={`fa fa-${props.icon}`} />&nbsp;
    </span>
  );
  const OperationButton = (props: IOperation) => {
    const onAction = props.onAction || (() => { });
    return (
      <span
        className={`btn btn-${props.buttonType} btn-sm form-btn mr-1`}
        onClick={
          stopPropagationWrapper(() => onAction(props.submission, props.action))
        }
      >
        {
          props.icon
            ? <Icon icon={props.icon}></Icon>
            : null
        }
        {props.title}
      </span>
    );
  }

  const columns = propColumns.length ? propColumns : displayColumns;
  const renderCell = (col: any, submission: ISubmission) => {
    //Check if call is reference resource then display a link here
    let compType = _get(col.component, "component.type"),
      isImage = _get(col.component, "component.image");
    if (compType == "file") {
      let files = _get(submission, col.key, []);
      if (isImage && files.length == 1) {
        let tmpSub, subPath, fieldName;
        const parts = col.key.split('.');
        let length = parts.length;
        while (!tmpSub?.form && length > 0) {
          length--; 
          subPath = parts.slice(0, length);
          tmpSub = _get(submission, subPath);
        }
        if (!tmpSub || !tmpSub.form) {
          tmpSub = submission;
          fieldName = col.key;
        } else {
          fieldName = parts.slice(length, parts.length).join('.');
        }
        return (<FormImage submission={tmpSub} fieldName={fieldName} size='xlarge' shape='square'/>)
      } else {
        //Show o download button for dowload file
        return files.map((file: Record<string, any>, ind : number) => (
          <Button key={ind} label={file.originalName} link onClick={() => downloadFile(submission, col.key, ind)} />));
        }
    }
    let resSubmission: ISubmission,
      resourceId = _get(col.component, "component.data.resource");
    //Field is selected from some resource
    if (resourceId) {
      resSubmission = _get(submission, col.key);
    } else {
      //Field is copy from a field of resource, which is selected in other field.
      //This copy field is used for filtering
      resourceId = _get(col.component, "component.properties.resourceId");
      let refField = _get(col.component, "component.properties.linkField","");
      resSubmission = _get(submission, `data.${refField}`);
    } 
    // let viewValue = col.component.getView(_get(submission, col.key));
    let value: Record<string,any> = _isFunction(col.value)
      ? col.value(submission)
      : _get(submission, col.key, '');
    if ((!value || !value.content) && col.component.component.calculateValue) {
      value = col.component.doValueCalculation(null, submission.data);
      value = col.component.getView(value);
    }
    //Render link with value is a reference
    let formatedValue: Record<string,any> = _isObject(value) 
      ? { ...value, content: col.component.getView(value.content) }
      : col.component.getView(value);
    let cellComp = (_isObject(formatedValue))
        ? formatedValue.isHtml
          ? <div dangerouslySetInnerHTML={{ __html: formatedValue.content || '' }} />
          : String(formatedValue.content || '')
        : String(formatedValue || '');
    if (resourceId && resSubmission?._id) {
      //Render a link to a detail
      return (<Button children={cellComp} link onClick={()=>openReference(resourceId, resSubmission._id)}/>);
    } else {
      return cellComp;
    }
  }
  
  const onExportExcel = () => {
    //Create the sheet
    const headers = columns.map(col => col.key.split('.')[1]);
    headers.unshift("No");
    const options = columns.map(col => ({ wch: col.width * 10 }));
    options.unshift({ wch: 6 });

    const sheetData = submissions.map((submission: ISubmission, ind: number) => {
      const rowData: Record<string, any> = {
        ["No"]: ind + 1,
      };
      columns.forEach((col, ind) => {
        let resSubmission: ISubmission,
          resourceId = _get(col.component, "component.data.resource");
        //Field is selected from some resource
        if (resourceId) {
          resSubmission = _get(submission, col.key);
        } else {
          //Field is copy from a field of resource, which is selected in other field.
          //This copy field is used for filtering
          resourceId = _get(col.component, "component.properties.resourceId");
          let refField = _get(col.component, "component.properties.linkField","");
          resSubmission = _get(submission, `data.${refField}`);
        } 
        // let viewValue = col.component.getView(_get(submission, col.key));
        let value: Record<string,any> = _isFunction(col.value)
          ? col.value(submission)
          : _get(submission, col.key, '');
        if ((!value || !value.content) && col.component.component.calculateValue) {
          value = col.component.doValueCalculation(null, submission.data);
          //value = col.component.getView(value);
        }
        //Render link with value is a reference
        let formatedValue: Record<string,any> = _isObject(value) 
          ? { ...value, content: col.component.getView(value.content) }
          : col.component.getView(value);
        rowData[col.title] = _isObject(formatedValue)
          ? String(formatedValue.content || '')
          : String(formatedValue || '');
      });
      return rowData;
    });
    const sheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(sheet, [headers]);
    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(sheet, sheetData, { origin: 'A2', skipHeader: true });
    // var sheet = XLSX.utils.json_to_sheet(sheetData, {
    //   origin: "A2",skipHeader: true
    // });
    sheet["!cols"] = options;
    // Make first row bold
    for (let i = 0; i < options.length; i++) {
      const cell = sheet[XLSX.utils.encode_cell({ r: 0, c: i })];
      // Create new style if cell doesnt have a style yet
      if (!cell.s) { cell.s = {}; }
      if (!cell.s.font) { cell.s.font = {}; }
      // Set bold
      cell.s.font.bold = true;
    }

    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();

    // // Add bold format
    // var bold = workbook.add_format({'bold': True});
    // add Worksheet to Workbook
    // Workbook contains one or more worksheets
    XLSX.utils.book_append_sheet(wb, sheet, form?.name); // sheetAName is name of Worksheet
    // export Excel file
    const currentDate = new Date();
    const fileName = form?.name + '-' + currentDate.getTime() + '.xlsx';
    XLSX.writeFile(wb, fileName);
  };


  const xlsxUploader = async (event: FileUploadHandlerEvent) => {
    const options = {
      cellDates: true
    };
    const file = event.files ? event.files[0] : null;
    if (file) {
      const buffer = await file.arrayBuffer();
      const data = XLSX.read(buffer, options);
      setUploadData(data);
      const sheetNames = data.SheetNames;
    }
    setShowOption(true);
  };
  const onUpload = async (sheetName: string, options: Record<string, any>) => { 
    setShowOption(false);
    await _doXlsxUpload(uploadData, sheetName, options);
  }
  const _doXlsxUpload = async (data: any, selectedSheet: string, options: Record<string, any>) => { 
    let { mapping, master } = options;
    let firstRow =  _get(mapping, "data.firstRow", options.firstRow || 1);
    const detailMapping = _get(mapping, "data.mapping");
    const masterMapping = _get(mapping, "data.masterMapping");
    const workbook = data.Sheets;
    if (workbook && selectedSheet) {
      const sheet = workbook[selectedSheet];
      let range = XLSX.utils.decode_range(sheet["!ref"]);
      range.s.r = firstRow - 1;
      let header = mapping ? "A" : options.header || "A"; //Default use the column as keys;
      //header: 'A' - use col as key start with column 'A'
      //header: '1' - use index start from first column
      //https://docs.sheetjs.com/docs/csf/features/dates/
      const parserOptions = {
        header,
        range
      };
      //https://github.com/SheetJS/sheetjs/issues/1470
      const sheetData = XLSX.utils.sheet_to_json(sheet, parserOptions);
      const submissions = mapSheetData2Submissions(sheetData, detailMapping, masterMapping, master);
      const callback = (err: any, data: any) => { 
        fetchSubmissions();
      }
      // let promises = [];
      // for (let ind = 0; ind < submissions.data.length; ind++) {
      //   let submission = {
      //     form: form._id,
      //     data: submissions.data[ind]
      //   }
      //   promises.push(postCustomSubmission(submission, submission.form, false));
      // }
      // let res = await Promise.all(promises).catch((err) => {callback(err)});
      // callback(null, res);
      if (form?._id) {
        uploadSubmissions(submissions, form._id, false, callback);
      }
    }
  }
  const mapSheetData2Submissions = (sheetData: any, mapping: Record<string, any>[],
    masterMapping: Record<string, any>, master: object) => {
    if (!Array.isArray(sheetData) || sheetData.length == 0) {
      return [];
    }
    const data = [];
    const col2Field = mapping? mapping.reduce((accumulator: Record<string, any>, item: any) => {
      accumulator[item.column] = _get(item, "fieldName.key");
      return accumulator; 
    }, {})
      : sheetData[0].reduce((accumulator: Record<string, any>, item : any, ind: number) => {
        accumulator[ind] = item;
        return accumulator;
      }, {});

    const master2Field = masterMapping?.reduce((accumulator: Record<string, any>, item: any) => {
      accumulator[_get(item, "masterField.key")] = _get(item, "fieldName.key");
      return accumulator; 
    }, {});
    const masterValues: Record<string, any> = {};
    let firstMasterField = _get(masterMapping, "[0].masterField.key")
    if (master2Field && master) {
      for (let masterFeld in master2Field) {
        let field = master2Field[masterFeld];
        if (masterFeld == firstMasterField) {
          masterValues[field] = master;
        } else {
          let value = _get(master, `data.${masterFeld}`);
          if (value) {
            masterValues[field] = value;
          }
        }
      }
    }
    let firstInd = mapping ? 0 : 1;
    let date = new Date();
    var timeOffsetInMS = date.getTimezoneOffset() * 60000;
    const calculatedFields = [];
    //Todo: Calculated fields
    let components = Utils.flattenComponents(form?.components||[], false);
    for (let fieldName in components) {
      let component = components[fieldName];
      if (component.calculateValue && component.persistent) {
        calculatedFields.push(component);
      }
    }
    for (let ind = firstInd; ind < sheetData.length; ind++) {
      const row = sheetData[ind];
      const submission: Record<string, any> = {...masterValues};
      let hasKey = false;
      for (let col in col2Field) {
        let field = col2Field[col];
        let value = row[col];
        if (value instanceof Date) {
          //mising 30s
          value.setTime(value.getTime() + 30000);
          submission[field] = value;
          hasKey = true;
        } else if (value) {
          submission[field] = value;
          hasKey = true;
        }
      }
      //Todo: Calculated fields
      for (var component of calculatedFields) {
        let context = {
          _,
          form,
          data: submission,
          component
        }
        let value = Utils.evaluate(component.calculateValue, context, "value");
        if (value) {
          submission[component.key] = value;
          hasKey = true;
        }
      }
      if (hasKey) {
        //Clean up reference data (store refFormId & submissionId only)
        data.push(cleanupSubmissionData(submission));
      }
    };
    let submissions = {
      form: form?._id,
      data
    }
    return submissions;
  }
  const sheetData2Submissions = (sheetData: Record<string, any>[]) => {
    const data = [];
    for (let ind = 0; ind < sheetData.length; ind++) {
      const row = sheetData[ind];
      const submission: Record<string, any> = {};
      let hasKey = false;
      columns.forEach(col => {
        const key = col.component.component.key;
        if (row[key]) {
          hasKey = true;
          submission[key] = row[key];
        }
      });
      if (hasKey) {
        data.push(submission);
      }
    };
    let submissions = {
      form: form?._id,
      data
    }
    return submissions;
  };
  const handleOpenDialog = async (formModalId: string) => { 
    let formModal = await loadFormio(formModalId);
    let formActions = formActionSubmissions?.filter((action: ISubmission) => _get(action, "data.sourceForm.formId") == formModalId);
    setFormModal(formModal);
    setFormModalActions(formActions);
    setShowModal(true);
  }
  const handleModalSubmit = (payload: Record<string, any>, formAction: ISubmission, context: IContext) => { 
    let modalSubmission = cleanupSubmissionData(payload);
    setModalSubmission(modalSubmission);
    const mainContext: IContext = {
      form,
      submissionId: _get(selectedSubmissions,"[0]._id"),
      navigate,
      handleOpenDialog
    }; 
    mainContext.properties = context.properties || {};
    if (modalSubmission?.data) {
      mainContext.properties = { ...mainContext.properties, ...modalSubmission.data };
    }
    if (formAction) {
      return actionHandler(formAction, mainContext);
    }
  }
  const onClickButton = async (event: React.MouseEvent<HTMLButtonElement>, action: ISubmission, button: any, context: IContext) => { 
    const uiAction = _get(action, "data.uiAction");
    if (uiAction == ACTION_PREVIEW_FORM) {
      const formTemplate = _get(action, "data.formTemplate");
      if (formTemplate) {
        let submissionIds = selectedSubmissions.map((submission) => submission._id);
        let url = createUrlSubmissionPreview({
          submissionIds,
          formTemplateId: formTemplate.formId,
          formId: form._id
        });
        navigate(url);
      }
    } else if (uiAction == ACTION_XLSX_BINDING) { 
      const xlsxTemplate = _get(action, "data.xlsxTemplate");
      if (xlsxTemplate) {
        let submissionIds = selectedSubmissions.map((submission) => submission._id);
        let url = createUrlSubmissionXlsxBinding({
          submissionIds,
          templateFormId: xlsxTemplate.form,
          templateSubmissionId: xlsxTemplate._id,
          fieldName: "templateFile",
          formId: form._id
        });
        navigate(url);
      }
    } else {
      handleCustomAction(event, action, button, context);
    }
  }
  const renderHeader = () => {
    const listActions = formActions?.filter((action) => _get(action, "data.pages", '').split(",").includes("list"));
    const context: IContext = {
      form,
      navigate, 
      t,
      submissions: selectedSubmissions,
      toast,
      refForm: form,
      refFormId: form._id,
      handleOpenDialog
    };
    if (refSubmission) {
      context.refFormId = refSubmission.form;
      context.refSubmission = refSubmission;
    } else if (selectedSubmissions.length == 1) {
      //Todo: lưu ý trường hợp chọn 1 record  để thực hiện action. Cần xác định lại refSubmission
      context.refSubmission = selectedSubmissions[0];
    }
    
    let buttonComps = [];
    //Advanced search
    buttonComps.push(<Button type="button"
      key="advancedSearch"
      size="small"
      icon={collapsedAdvancedSearch ? "pi pi-search-plus" : "pi pi-search-minus"}
      text raised
      className='mr-2'
      onClick={()=> setCollapsedAdvancedSearch(!collapsedAdvancedSearch)} />
    );
    buttonComps.push(<Button type="button"
      key="clearFilter"
      size="small"
      label={ t("button.clear")}
      icon="pi pi-filter-slash"
      text raised
      className='mr-2'
      onClick={clearFilter} />
    );
    listActions?.forEach((action) => { 
      const actionName = _get(action, "data.actionName");
      let button = buttons[actionName];
      let key = button?.key || actionName
      let label = button?.label || _get(action, "data.label") || t(_get(action, "data.i18nKey"));
      buttonComps.push(createButton({
        key,
        label,
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClickButton(event, action, button, context)
      }));
    });
    const allowAll = userRoles.includes(ACCESS_FULL) || userRoles.includes(STAFF_DESIGNER);
    //buttonComps.push(<Button type="button" icon="pi pi-filter-slash" label={t("button.clear")} outlined onClick={clearFilter} className="mr-2"/>)
    if (allowAll || formPerms[ACTION_CREATE_OWN]) {
      buttonComps.push(createButton({
        key: "create",
        icon: "pi pi-plus",
        label: t("button.create"),
        onClick: () => onCreate()
      }));
    }
    buttonComps.push(createButton({
      key: "refresh", icon: "pi pi-refresh", label: t("button.refresh"),
      onClick: fetchSubmissions
    }));
    if (allowAll || formPerms[ACTION_DELETE_OWN] || formPerms[ACTION_DELETE_ALL]) {
      buttonComps.push(createButton({
        key: "delete",
        icon: "pi pi-trash",
        label: t("button.delete"),
        onClick: () => {
          setOpenDeleteConfirm(true);
          setMultipleSelection(true);
        }}));
    }
    return (
      <div className="flex justify-content-between">
        <div>
          {buttonComps}
        </div>
        {/* <h2>{form?.title}</h2> */}
        {/* <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText value={globalFilterValue} onChange={ onGlobalFilterChange } onBlur={ doApiFilter } placeholder="Keyword Search" />
          </span>
        </div> */}
      </div>
    );
  };
  const renderRowActionsBk = (submission: ISubmission) => {
    let operations: IOperation[] = resolveSubmissionOperation(submission);
    return (<div>
      {
        operations.map(({
          action,
          buttonType = 'primary',
          icon = '',
          permissionsResolver = (form?: IFormio, submission?: ISubmission) => true,
          title = '',
        }) =>
          permissionsResolver(form, submission)
            ? <OperationButton
              key={action}
              action={action}
              buttonType={buttonType}
              icon={icon}
              title={title}
              submission={submission}
              onAction={onRowAction}
            ></OperationButton>
            : null
        )
      }
    </div>);
  };
  const openRowMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, submission: ISubmission) => { 
    let operations: IOperation[] = resolveSubmissionOperation(submission);
    const menuItems: MenuItem[] = [];
    operations.forEach(({ action,
      buttonType = 'primary',
      icon = '',
      permissionsResolver = (form?: IFormio, submission?: ISubmission) => true,
      title = '', }) => {
      if (permissionsResolver(form, submission)) {
        const item = {
          key: action,
          label: title,
          icon,
          command: () => {
            onRowAction(submission, action);
          }
        };
        menuItems.push(item)
      }
    });
    setRowMenuItems(menuItems);  
    if (rowPopupMenu && rowPopupMenu.current && menuItems.length > 0) {
      rowPopupMenu.current.toggle(event);

    }
  }
  const renderRowActions = (submission: ISubmission) => {
      return (<Button
        icon="pi pi-list"
        className="mr-2"
        size="small"
        text
        onClick= {(event) => openRowMenu(event, submission)}
        aria-controls="row_popup_menu"
        aria-haspopup />) 
  }
  const createResourceFilter = (options: Record<string, any>, col: IColumn) => { 
    let comp = col.component;
    let resource = _get(comp, "component.data.resource");
    return (
      <>
        <InputText value={options.value || ""} onChange={(e) => handleColumnFilter(col, options, e.target?.value)} />
        <Dropdown value={options.value || ""} options={resources[resource]} onChange={(e) => handleColumnResourceFilter(col, options, e.target?.value || e.value)}/>
      </>
    )
  }

  const createFilterElement = (options: Record<string, any>, col: Record<string,any>) => {
    let comp = col.component;
    let key = comp.key;
    switch (comp.type) {
      case "datetime":
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
      case "number":
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} />
      case "select":
        let values = _get(comp, "component.data.values");
        let resource = _get(comp, "component.data.resource");
        if (resource) {
          return <Dropdown value={options.value} options={values} onChange={(e) => options.filterCallback(e.value, options.index)} />
        } else if (Array.isArray(values)) {
          return <Dropdown value={options.value} options={values} onChange={(e) => options.filterCallback(e.value, options.index)} />
        }
        break;
      default:
        return <InputText value={options.value || ""} onChange={(e) => handleColumnFilter(col, options, e.target?.value)} />;
    }
  }
  const isEnableFilter = (col: Record<string,any>) => {
    const comp = col.component;
    let filterable = true;
    switch (comp.type) {
      case "select":
        let data = _get(comp, "component.data");
        if (data?.resource) {
          filterable = true;
        } else if(Array.isArray(data?.values)){
          filterable = data.values.length > 0;
        } 
        break;
      case "file":
        filterable = false;
        break;
    }
    return filterable;
  }
  const createColumns = () => {
    //created date
    let colComps = [];
    let showCreatedDate = _get(pageConfig, "data.showCreatedDate");
    if (showCreatedDate) {
      colComps.push(
        <Column key="createdDate" header={t("common.created_date")} align="center" headerStyle={{ width: '8rem' }}
          body={(submission) => (moment(submission.created).format("MM/DD/yyyy")) } />)
    }
    let fieldMapper = _get(pageConfig, "data.fieldMapper");
    if (Array.isArray(fieldMapper) && fieldMapper.length > 0) {
      let mapColumns: Record<string, IColumn> = columns.reduce((total: Record<string, IColumn>, current : IColumn) => {
        total[current.key] = current;
        return total;
      }, {});
      for (let field of fieldMapper) {
        let fieldName = "data." + _get(field, "fieldName.key", "");
        let column = mapColumns[fieldName];
        if (column) {
          let colComp = renderColumn(column, 0);
          colComps.push(colComp);
        }
      }
      return colComps;
    } else {
      return colComps.concat(columns.map(renderColumn));
    }
  }
  const onRowEditComplete = (e: DataTableRowEditCompleteEvent) => {
    //save editing submission
    let submission = e.newData;
    let components = Utils.flattenComponents(form?.components, true);
    let extraParams = _get(components['submit'], "properties");
    const submissionPayload = { ...submission, data: cleanupSubmissionData(submission.data) };
    submissionPayload.data.editor = userDetail?.name;
    let callback = () => {
      fetchSubmissions();
     };
    if (extraParams?.customAction) {
      submission.extraParams = extraParams;
      saveCustomSubmission(
        submission,
        formId,
        callback
      );
    } else {
      if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
        saveCustomSubmission(
          submission,
          formId,
          callback
        );
      } else {
        dispatch(
          saveSubmission(
            "submission",
            submission,
            formId,
            callback
          )
        );
      }
    }
  };
  const cellEditor = (options: ColumnEditorOptions, col: Record<string,any>) => { 
    if (_get(col.component, "component.properties.inlineEdit") == "true") {
      let type = _get(col.component, "component.type")
      if (type == "number") {
        return <InputNumber value={options.value} onChange={(e: InputNumberChangeEvent) => options.editorCallback!(e.value)} />;
      } else {
        return renderCell(col, options.rowData);
      }
      
    }
    return renderCell(col, options.rowData);
  };
  const renderColumn = (col: IColumn, ind: number) => {
    const comp = col.component;
    const alignContent = (component: IComponent) => { 
      if (component.type == 'number') {
        return 'right';
      }
      return null;
    };
    let dataType;
    if (comp.type == 'number') {
      dataType="numeric"
    } else if (comp.type == 'datetime') {
      dataType = 'date';
    }
    return (<Column key={col.key}
      dataType={dataType}
      align={alignContent(col.component.component)}
      filter={isEnableFilter(col)}
      sortable={true}
      editor={(options: ColumnEditorOptions) => cellEditor(options, col)}
      field={col.key}
      header={col.title}
      headerStyle={col.headerStyle}
      filterField={col.key}
      onFilterClear={() => clearColumnFilter(col) }
      filterElement={(options)=> createFilterElement(options, col)}
      body={(submission) => renderCell(col, submission)}
    />);
  }
  const downloadFile = async (submission: ISubmission, key: any, ind: number) => { 
    let params: FormSubmissionParams = {
      formId: submission.form,
      submissionId: submission._id
    }
    let res = await fetchSubmission(params);
    let files = _get(res.data, key, []);
    let file = files[ind];
    if (file.originalName && file.url) {
      var a = document.createElement("a");
      if (a.download != null) {
        /*:: if(document.body == null) throw new Error("unreachable"); */
        a.download = file.originalName; a.href = file.url; document.body.appendChild(a); a.click();
      /*:: if(document.body == null) throw new Error("unreachable"); */ document.body.removeChild(a);
        if (URL.revokeObjectURL && typeof setTimeout !== 'undefined') setTimeout(function () { URL.revokeObjectURL(file.url); }, 60000);
      }
    }
  }
  const openReference = (refFormId: string, refSubmissionId: string) => { 
    let refUrl = `${BASE_CONTEXT}/form/${refFormId}/submission/${refSubmissionId}`;
    navigate(refUrl);
  }

  const paginatorLeft = params.showUpload != 'false' ? (
    <FileUpload mode="basic"
      name="submission[]"
      customUpload
      auto
      chooseLabel={t('button.upload')}
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      maxFileSize={10240000} uploadHandler={xlsxUploader} />
  ) : <></>;
  const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={onExportExcel} />;
  const enableRowAction = _get(pageConfig, "data.enableRowAction");
  const editorMode = _get(pageConfig, "data.editorMode");
  // let datatableProps: DataTableProps<ISubmission[]> = {
  //   header: renderHeader(),
  //   value: submissions,
  //   reorderableColumns: false,
  //   resizableColumns: false,
  //   paginator: true,
  //   showGridlines: true,
  //   lazy: true,
  //   dataKey: "_id",
  //   filters,
  //   filterDisplay:"menu",
  //   size:"small",
  //   sortMode: "multiple",
  //   selectionMode: "multiple",
  //   selection: selectedSubmissions,
  //   onSelectionChange: (e) => setSelectedSubmissions(e.value),
  //   multiSortMeta: sortMeta,
  //   onPage: handlePaging,
  //   onSort: handleSort,
  //   onFilter: handleFilter,
  //   rows,
  //   first: rows * currentPage,
  //   rowsPerPageOptions: DefaultPageSizes,
  //   totalRecords:total,
  //   paginatorPosition: "both",
  //   currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} items",
  //   paginatorLeft,
  //   paginatorRight
  // };
  return (
    <>
      <Confirm
        modalOpen={openDeleteConfirm}
        message={
          <Translation>
            {(t) => selectedSubmissions.length == 0 ? t('common.delete_noselection') : t("common.delete_confirm", { count: selectedSubmissions.length })}
          </Translation>
        }
        onNo={onDeleteCancel}
        onYes={onDeleteConfirm}
      ></Confirm>
      <Toast ref={toast} position="top-center" />
      <Menu model={rowMenuItems} popup ref={rowPopupMenu} id="row_popup_menu" />
      <ConfirmPopup />
      {formModal ?
        <Dialog header={formModal?.title} visible={showModal} maximizable style={{ width: '50vw' }} onHide={() => setShowModal(false)}>
          <FormContainer
            form={formModal}
            formActions={formModalActions}
            //actionHandler={modalActionHandler}
            handleSubmit={handleModalSubmit}
            submission={modalSubmission as ISubmission}
          />
        </Dialog> : null
      }
      <Dialog header={t("config.upload_option") } visible={showOption} style={{ width: '50vw' }} onHide={() => setShowOption(false)}>
        <ExcelUploadOption
          sheetNames={uploadData?.SheetNames || []}
          refSubmission={ refSubmission }
          excelMappings={excelMappings}
          onSubmit={onUpload} />
      </Dialog>
      <SmartQueryBuilder
        header={renderHeader()}
        form={form}
        queryType='mongodb'
        collapsed={collapsedAdvancedSearch}
        setCollapsed={setCollapsedAdvancedSearch}
        handleFilter={ setSmartFilter}
      />
      <DataTable
        value={submissions}
        reorderableColumns={false}
        resizableColumns={false}
        paginator showGridlines lazy dataKey="_id"
        filters={filters}
        filterDisplay="menu"
        size="small"
        sortMode="multiple"
        selectionMode= "multiple"
        selection={selectedSubmissions}
        onSelectionChange={(e) => setSelectedSubmissions(e.value)}
        onRowEditComplete={onRowEditComplete}
        multiSortMeta={sortMeta}
        onPage={handlePaging}
        onSort={handleSort}
        onFilter={handleFilter}
        editMode={editorMode}
        rows={rows}
        first={rows * currentPage}
        rowsPerPageOptions={DefaultPageSizes}
        totalRecords={total}
        paginatorPosition="both"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
        paginatorLeft={paginatorLeft}
        paginatorRight={paginatorRight}>
        <Column selectionMode="multiple" align="center" headerStyle={{ width: '3rem' }} />
        <Column header={t("common.ordinal")} align="center" headerStyle={{ width: '4rem' }}
          body={(submission, options) => (options.rowIndex + 1)} />
        {editorMode == 'row' ?  <Column rowEditor={true} headerStyle={{ width: '3%', minWidth: '5rem' }} bodyStyle={{ textAlign: 'center' }}/> : null}
        {enableRowAction != false ?
          <Column align="center"
            headerStyle={{ width: "4rem" }}
            body={(submission) => renderRowActions(submission)}
          />: null}
        {createColumns()}
      </DataTable>
    </>
  );
});

export default SubmissionTable;

function navigate(refUrl: string) {
  throw new Error('Function not implemented.');
}
