import React, { useEffect , useState, useMemo} from "react";
import { connect, useDispatch, useSelector} from "react-redux";
import {
  selectRoot,
  resetSubmissions,
  saveSubmission,
  getForm,
  Form,
  selectError,
  Errors,
} from "@formio/react";
import { useNavigate, useParams } from "react-router-dom";
import { default as formio_resourceBundles } from "src/locales/formio";
import Loading from "src/components/containers/Loading";

import {
  setFormSubmissionError,
  setFormSubmissionLoading,
} from "src/actions/formActions";
import SubmissionError from "src/components/containers/SubmissionError";
import { getUserRolePermission } from "src/helper/user";
import {
  CLIENT,
  CUSTOM_SUBMISSION_URL,
  CUSTOM_SUBMISSION_ENABLE,
  MULTITENANCY_ENABLED,
} from "src/constants";
import {
  CLIENT_EDIT_STATUS,
  UPDATE_EVENT_STATUS,
  getProcessDataReq,
} from "src/constants/applicationConstants";
import { updateApplicationEvent } from "src/apiManager/services/applicationServices";
import { toast } from "react-toastify";
import { Translation, useTranslation } from "react-i18next";
import { saveCustomSubmission } from "src/apiManager/services/FormServices";
import { BASE_CONTEXT } from 'src/config-global';

const SubmissionCreate = React.memo((props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.user.lang);
  const [form, setForm ] = useState({});
  const [isFormActive, setFormActive] = useState(true);
  const { formId } = useParams();
  const {
    hideComponents,
    onSubmit,
    options,
    errors,
    onFormSubmit,
    onCustomEvent,
    // form: { form, isActive: isFormActive },
    submission: { submission, isActive: isSubActive, url },
  } = props;

  const [updatedSubmissionData, setUpdatedSubmissionData] = useState({});

  const applicationStatus = useSelector(
    (state) => state.applications.applicationDetail?.applicationStatus || ""
  );
  const userRoles = useSelector((state) => {
    return selectRoot("user", state).roles;
  });
  const applicationDetail = useSelector(
    (state) => state.applications.applicationDetail
  );

  const isFormSubmissionLoading = useSelector(
    (state) => state.formDelete.isFormSubmissionLoading
  );
  const tenantKey = useSelector((state) => state.tenants?.tenantId);
  const customSubmission = useSelector(
    (state) => state.customSubmission?.submission || {}
  );
  const redirectUrl = MULTITENANCY_ENABLED ? `/tenant/${tenantKey}/` : "/";
  
  useEffect(() => {
    dispatch(getForm("form", formId, (err, form) => {
      if (form && form.components) {
        setForm(form);
      }
    }));
  }, [formId, setForm])

  useEffect(() => {
    // Check if the application is in "Resubmit" or "Awaiting Acknowledgement" status (old approach and it’s kept to have backward compatibility)
    // In the new approach, we will use the "isResubmit" key
    if (applicationStatus && !onFormSubmit) {
      if (
        getUserRolePermission(userRoles, CLIENT) &&
        !CLIENT_EDIT_STATUS.includes(applicationStatus) &&
        !applicationDetail.isResubmit
      ) {
        // Redirect the user to the submission view page if not allowed to edit
        // navigate(`/form/${formId}/submission/${submissionId}`);
      }
    }
  }, [
    applicationStatus,
    userRoles,
    dispatch,
    formId,
    onFormSubmit,
  ]);
  const handleSubmit = (submission)=>{
    setUpdatedSubmissionData(submission);
    // onSubmit(
    //   submission,
    //   applicationDetail,
    //   onFormSubmit,
    //   form._id,
    //   redirectUrl
    // );
    dispatch(setFormSubmissionLoading(true));
    const callBack = (err, submission) => {
      if (!err) {
        if (
          UPDATE_EVENT_STATUS.includes(applicationDetail.applicationStatus) ||
          applicationDetail.isResubmit
        ) {
          const data = getProcessDataReq(applicationDetail,submission.data);
          dispatch(
            updateApplicationEvent(applicationDetail.id, data, () => {
              dispatch(resetSubmissions("submission"));
              dispatch(setFormSubmissionLoading(false));
              if (onFormSubmit) {
                onFormSubmit();
              } else {
                toast.success(
                  <Translation>{(t) => t("Submission Saved")}</Translation>
                );
                navigate(
                  // eslint-disable-next-line max-len
                  `${BASE_CONTEXT}${redirectUrl}form/${formId}/submission/${submission._id}`
                );
              }
            })
          );
        } else {
          dispatch(resetSubmissions("submission"));
          dispatch(setFormSubmissionLoading(false));
          if (onFormSubmit) {
            onFormSubmit();
          } else {
            toast.success(
              <Translation>{(t) => t("Submission Saved")}</Translation>
            );
            navigate(
              // eslint-disable-next-line max-len
              `${BASE_CONTEXT}${redirectUrl}form/${form.formId}/submission/${submission._id}/edit`
            );
          }
        }
      } else {
        dispatch(setFormSubmissionLoading(false));
        const ErrorDetails = {
          modalOpen: true,
          message: (
            <Translation>
              {(t) => t("Submission cannot be done.")}
            </Translation>
          ),
        };
        toast.error(
          <Translation>{(t) => t("Error while Submission.")}</Translation>
        );
        dispatch(setFormSubmissionError(ErrorDetails));
      }
    };
    if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
      saveCustomSubmission(
        submission,
        formId,
        callBack
      );
    }else{
      dispatch(
        saveSubmission(
          "submission",
          submission,
          formId,
          callBack
        )
      );
    }
  }
  const onConfirm = () => {
    const ErrorDetails = { modalOpen: false, message: "" };
    dispatch(setFormSubmissionError(ErrorDetails));
  };
  const updatedSubmission = useMemo(()=>{
    if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
      return customSubmission;
    } else {
      return submission;
    }
  },[customSubmission,submission]);

  // if (isFormActive || (isSubActive && !isFormSubmissionLoading) || !updatedSubmission?.data) {
  //   return <Loading />;
  // }

  return (
    <div className="container">
      <div className="main-header">
        <SubmissionError
          modalOpen={props.submissionError.modalOpen}
          message={props.submissionError.message}
          onConfirm={props.onConfirm}
        ></SubmissionError>
        <h3 className="task-head text-truncate" style={{ height:"45px" }}>{form.title}</h3>
      </div>
      <Errors errors={errors} />
        <div className="ml-4 mr-4">
          <Form
            form={form}
            submission={isFormSubmissionLoading ? updatedSubmissionData : updatedSubmission}
            url={url}
            hideComponents={hideComponents}
            onSubmit={handleSubmit}
            options={{
              ...options,
              i18n: formio_resourceBundles,
              language: lang,
            }}
            onCustomEvent={onCustomEvent}
          />
        </div>
    </div>
  );
});

SubmissionCreate.defaultProps = {
  onCustomEvent: () => {},
};

//export default SubmissionCreate;

const mapStateToProps = (state) => {
  return {
    user: state.user.userDetail,
    form: selectRoot("form", state),
    submission: selectRoot("submission", state),
    isAuthenticated: state.user.isAuthenticated,
    errors: [selectError("form", state), selectError("submission", state)],
    options: {
      noAlerts: false,
      i18n: {
        en: {
          error: (
            <Translation>
              {(t) => t("Please fix the errors before submitting again.")}
            </Translation>
          ),
        },
      },
    },
    submissionError: selectRoot("formDelete", state).formSubmissionError,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (
      submission,
      applicationDetail,
      onFormSubmit,
      formId,
      redirectUrl
    ) => {
      dispatch(setFormSubmissionLoading(true));
      const callBack = (err, submission) => {
        if (!err) {
          if (
            UPDATE_EVENT_STATUS.includes(applicationDetail.applicationStatus) ||
            applicationDetail.isResubmit
          ) {
            const data = getProcessDataReq(applicationDetail,submission.data);
            dispatch(
              updateApplicationEvent(applicationDetail.id, data, () => {
                dispatch(resetSubmissions("submission"));
                dispatch(setFormSubmissionLoading(false));
                if (onFormSubmit) {
                  onFormSubmit();
                } else {
                  toast.success(
                    <Translation>{(t) => t("Submission Saved")}</Translation>
                  );
                  navigate(
                    // eslint-disable-next-line max-len
                    `${redirectUrl}form/${ownProps.match.params.formId}/submission/${submission._id}`
                  );
                }
              })
            );
          } else {
            dispatch(resetSubmissions("submission"));
            dispatch(setFormSubmissionLoading(false));
            if (onFormSubmit) {
              onFormSubmit();
            } else {
              toast.success(
                <Translation>{(t) => t("Submission Saved")}</Translation>
              );
              navigate(
                // eslint-disable-next-line max-len
                `${redirectUrl}form/${ownProps.match.params.formId}/submission/${submission._id}/edit`
              );
            }
          }
        } else {
          dispatch(setFormSubmissionLoading(false));
          const ErrorDetails = {
            modalOpen: true,
            message: (
              <Translation>
                {(t) => t("Submission cannot be done.")}
              </Translation>
            ),
          };
          toast.error(
            <Translation>{(t) => t("Error while Submission.")}</Translation>
          );
          dispatch(setFormSubmissionError(ErrorDetails));
        }
      };
      if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
        saveCustomSubmission(
          submission,
          //onFormSubmit ? formId : ownProps.match.params.formId,
          formId,
          callBack
        );
      }else{
        dispatch(
          saveSubmission(
            "submission",
            submission,
            //onFormSubmit ? formId : ownProps.match.params.formId,
            formId,
            callBack
          )
        );
      }

    },
    onConfirm: () => {
      const ErrorDetails = { modalOpen: false, message: "" };
      dispatch(setFormSubmissionError(ErrorDetails));
    },
  };
};

export default connect(mapStateToProps)(SubmissionCreate);
