import { HelperServices } from "src/services";
import API from "../endpoints";
import { replaceUrl } from "src/helper/helper";

class ActionServices {
    static callSubmissionApi = async (submissionId, formId, action, data, ...rest) => {
        const done = rest.length ? rest[0] : () => { };
        let actionUrl = replaceUrl(API.CUSTOM_SUBMISSION, "<form_id>", formId);
        actionUrl = `${actionUrl}/${submissionId}/${action}`;
        return HelperServices.postRequest(actionUrl, data)
            .then((res) => {
                if (res.data) {
                    done(null, res.data);
                }
            })
            .catch((err) => {
                done(err, null);
            });
    };

    static callFormApi = async (formId, action, data, ...rest) => {
        const done = rest.length ? rest[0] : () => { };
        let actionUrl = `${API.SMARTFORM_FORMIO}/${formId}/formAction`;
        return HelperServices.postRequest(actionUrl, data)
            .then((res) => {
                if (res.data) {
                    done(null, res.data);
                }
            })
            .catch((err) => {
                done(err, null);
            });
    };
}
export default ActionServices;