import { lazy, useContext, Suspense } from "react";
import { Outlet } from 'react-router-dom';
import { FormsContext } from "src/contexts/forms";
import { Card } from 'primereact/card';
import ApplicationLayout from 'src/layouts/application';
import { SplashScreen } from 'src/components/loading-screen';
import { BASE_CONTEXT } from 'src/config-global';

import FormItem from 'src/components/Form/Item';
import MenuEntry from './MenuEntry';
import SubmissionList from 'src/pages/form/SubmissionList';
import SubmissionCreate from 'src/pages/form/SubmissionCreate';

import Submission from 'src/components/Form/Item/Submission';
import SubmissionEdit from 'src/components/Form/Item/Submission/Item/Edit';
import SubmissionView from 'src/components/Form/Item/Submission/Item/View';

const FormListPage = lazy(() => import("src/pages/form/list"));

export function useFormRoutes() {
  let route = {
        path: `form/:formId`,
        children: [
            { path: "submissions", element: <SubmissionList /> },
            { path: "submissions/create", element: <SubmissionCreate /> },
            { path: "submission/:id", element: <SubmissionEdit /> },
            { path: "submission/:id/edit", element: <SubmissionView /> },
        ]
      };
  let formRoutes = {
    path: `${BASE_CONTEXT}/`,
    element: (
      <ApplicationLayout>
        <Suspense fallback={<SplashScreen />}>  
          <Outlet />
        </Suspense>
      </ApplicationLayout>
    ),
    children: [
      {
        path: `form/:formId/*`,
        element: <FormItem />
      },
      {
        path: `menu/:menuId`,
        element: <MenuEntry />
      }
    ],
  };
  return [formRoutes];
}

  