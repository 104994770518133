import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getForm,
  getSubmissions,
  selectRoot,
  selectError,
  SubmissionGrid,
  Errors,
  deleteSubmission,
  Utils,
} from "@formio/react";
import cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import Loading from "src/components/containers/Loading";
import {
  OPERATIONS,
  CLIENT,
  CUSTOM_SUBMISSION_URL,
  CUSTOM_SUBMISSION_ENABLE,
  STAFF_DESIGNER,
  STAFF_REVIEWER,
  MULTITENANCY_ENABLED,
  ACTION_READ_ALL,
  ACTION_READ_OWN,
  ACTION_UPDATE_ALL,
  ACTION_UPDATE_OWN,
  ACTION_DELETE_ALL,
  ACTION_DELETE_OWN,
  ACCESS_FULL,
} from "src/constants";
import Confirm from "src/components/containers/Confirm";
import { receiveSubmissions, failSubmissions } from 'src/actions/submission';
import { setFormSubmissionDeleteStatus } from "src/actions/formActions";
import { deleteCustomSubmission, getCustomSubmissions } from "src/apiManager/services/FormServices";
import { getAllApplicationsByFormId } from "src/apiManager/services/applicationServices";
import {
  addApplicationDetailsToFormComponent,
  getRelevantApplications,
} from "src/apiManager/services/formatterService";
import { defaultSubmissionData } from "src/constants/submissionConstants";
import { setApplicationListLoader } from "src/actions/applicationActions";
import { Translation, useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { BASE_CONTEXT, FORM_ACTIONS, FORM_PAGE } from 'src/config-global';
import SubmissionTable from "src/components/smartform/SubmissionTable";
import { accumulateAccesses } from "src/components/Formio/utils";
import { createUrlSubmissionCreate } from "src/utils/meta";
const getOperations = (user, accesses, submission) => {
  let operations = [];
  if (user.roles.includes(STAFF_DESIGNER) || user.roles.includes(ACCESS_FULL)) {
    operations.push(
      { ...OPERATIONS.viewSubmission},
      { ...OPERATIONS.editSubmission},
      { ...OPERATIONS.deleteSubmission} 
    );
  } else if (accesses && Object.keys(accesses).length > 0) {
    let owner = _get(submission, "owner");
    if (accesses[ACTION_READ_ALL] || (accesses[ACTION_READ_OWN] && owner == user.id)) {
      operations.push(OPERATIONS.viewSubmission);
    }
    if (accesses[ACTION_UPDATE_ALL] || (accesses[ACTION_UPDATE_OWN] && owner == user.id)) {
      operations.push(OPERATIONS.editSubmission);
    }
    if (accesses[ACTION_DELETE_ALL] || (accesses[ACTION_DELETE_OWN] && owner == user.id)) {
      operations.push(OPERATIONS.deleteSubmission);
    }
  }
  // else if (userRoles.includes(STAFF_REVIEWER)) {
  //   operations.push(OPERATIONS.viewSubmission, OPERATIONS.deleteSubmission);
  // } else if (userRoles.includes(CLIENT)) {
  //   operations.push(OPERATIONS.viewSubmission);
  // }
  return operations;
};

const List = React.memo((props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formAccess = useSelector((state) => state.meta.formAccesses);
  const mapPages = useSelector((state) => state.meta.mapSubmissions[FORM_PAGE]);
  //Get path params from router
  const { formId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageId = searchParams.get("pageId");
  const {
    submissions,
    isLoading,
    errors,
    userRoles,
    userId,
    submissionFormId,
    submissionId,
    refSubmission,
    refFormAction,
  } = props;
  const submissionAccess = accumulateAccesses(formId, userRoles, formAccess);
  //const operations = getOperations(userRoles, submissionAccess);
  const resolveSubmissionOperation = (submission) => { 
    return getOperations({roles: userRoles, id: userId}, submissionAccess, submission);
  }
  const formApplicationsList = useSelector(
    (state) => state.applications.formApplicationsList
  );
  const isApplicationsListLoading = useSelector(
    (state) => state.applications.isApplicationListLoading
  );
  const form = useSelector((state) => state.form?.form);
  const [formData, setFormData] = useState();
  const [submissionListData, setSubmissionListData] = useState(
    defaultSubmissionData
  );
  const formActionSubmissions = useSelector((state) => state.meta.submissions.formActions);
  const [formActions, setFormActions] = useState();
  /*
   * Eachrecord store only reference (formId, submissionId)
   * When need filter by some reference's property, first query all referenceId
   * The query with all referenceId
   * Todo: first move this logic to the backend then Support join in single query
   */
  const [referenceFilters, setReferenceFilters] = useState({ ready: false });
  /*
   * store page parameters when current list is open form a Page
   * Parameters is put into scope's group: view, filter, api
   */
  const [pageParameters, setPageParameters] = useState({});
  const [page, setPage] = useState();
  const tenantKey = useSelector((state) => state.tenants?.tenantId);
  const redirectUrl = MULTITENANCY_ENABLED ? `/tenant/${tenantKey}/` : "/";
  const root = `${BASE_CONTEXT}${redirectUrl}form/${formId}/submission`;
  const onAction = (submission, action) => {
    switch (action) {
      case "viewSubmission":
        navigate(
          `${root}/${submission._id}`
        );
        break;
      case "edit":
        navigate(
          `${root}/${submission._id}/edit`
        );
        break;
      case "delete":{
        const submissionDetails = {
          modalOpen: true,
          formId: formId,
          submissionId: submission._id,
        };
        dispatch(setFormSubmissionDeleteStatus(submissionDetails));
        break;
      }
      default:
    }
  };
  const fetchSubmissions = (page, query = { 
    //"data.applicationId__regex": "/^\d+$/" 
  }) => {
    if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) { 
      const params = {page};
      const callback = (err, submissions) => {
        if (err) {
          dispatch(failSubmissions("submissions", []));
        } else {
          dispatch(receiveSubmissions("submissions", submissions));
        }
      };
      getCustomSubmissions(formId, params, callback);
    } else {
      const callback = (err, submissions) => {
      };
      dispatch(
        getSubmissions("submissions", page, query, formId, callback)
      );
    }
  };
  const onYes = (formId, submissionId, submissions) => {
    const callback = (err) => {
      if (!err) {
        const submissionDetails = {
          modalOpen: false,
          submissionId: "",
          formId: "",
        };
        dispatch(setFormSubmissionDeleteStatus(submissionDetails));
        fetchSubmissions(1, submissions.query);
      }
    };
    if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) { 
      dispatch(deleteCustomSubmission(submissionId, formId, callback));
    } else {
      dispatch(
        deleteSubmission("submission", submissionId, formId, callback)
      );
    }
  };
  const onNo = () => {
    const submissionDetails = {
      modalOpen: false,
      submissionId: "",
      formId: "",
    };
    dispatch(setFormSubmissionDeleteStatus(submissionDetails));
  };
  const loadReferenceFilters = async () => { 
    let promises = [], resource, mapIndexes = {};
    for (const [key, value] of searchParams) {
      const parts = key.split('.');
      const component = Utils.getComponent(form.components, parts[0]);
      if (resource = component?.data?.resource) {
        let query = {
          [`data.${parts[1]}`]: value
        };
        mapIndexes[parts[0]] = promises.length;
        promises.push(getCustomSubmissions(resource, {query}));
      }
    }
    if (promises.length == 0) {
      setReferenceFilters({
        ready: true,
        query: {}
      });
    }
    let results = await Promise.all(promises);
    let query = {};
    for (let field in mapIndexes) {
      let refValues = results[mapIndexes[field]];
      query[`data.${field}._id__in`] = refValues.map(item => item._id).join(',');
    }
    setReferenceFilters({ ready: true, query });
  }
  // useEffect(() => {
  //   //dispatch(setApplicationListLoader(true));
  //   //getAllApplicationsByFormId(formId);
  //   fetchSubmissions(1, {});
  // }, [dispatch, formId]);

  useEffect(() => {
    if (form && form.components) {
      setFormData(addApplicationDetailsToFormComponent(cloneDeep(form)));
      loadReferenceFilters(form);
    }
  }, [form, setFormData]);
  //Load form action
  useEffect(() => {
    if (form._id && Array.isArray(formActionSubmissions)) {
      let actions = formActionSubmissions?.filter((action) => _get(action, "data.sourceForm.formId") == form._id);
      setFormActions(actions);
    }
  }, [form, formActionSubmissions]);

  useEffect(() => {
    setSubmissionListData(submissions);
  }, [submissions, setSubmissionListData]);
  useEffect(() => { 
    if (pageId) {
      const parameters = _get(mapPages[pageId], "data.parameters", []).reduce((total, param) => {
        let scope = param.scope || 'view';
        total[scope] = total[scope] || {};
        total[scope][param.name] = param.value;
        return total;
      }, {});
      setPage(mapPages[pageId]);
    }
  }, [pageId])
  // useEffect(() => {
  //   if (formApplicationsList.length && submissions) {
  //     let updatedSubmissionList = getRelevantApplications(
  //       formApplicationsList,
  //       cloneDeep(submissions)
  //     );
  //     setSubmissionListData(updatedSubmissionList);
  //   }
  // }, [formApplicationsList, submissions, setSubmissionListData]);
  const handleCreate = () => {
    //const submissionCreateUrl = `${BASE_CONTEXT}${redirectUrl}form/${formId}/submission/create`;
    let options = { tenantKey, formId: form._id};
    for (const [key, value] of searchParams) {
      if (key == 'refForm') {
        options.refFormId = value;
      } else if (key == 'refSubmission') {
        options.refSubmissionId = value;
      }
    }
    const submissionCreateUrl = createUrlSubmissionCreate(options);
    navigate(submissionCreateUrl);
  }
  //TODO add formApplicationLoader
  // if (isLoading || isApplicationsListLoading) {
  //   return <Loading />;
  // }
  const header = 
        (<div className="ml-3">
            <i className="fa fa-wpforms" aria-hidden="true" /> &nbsp;{" "}
            {formData?.title}
        </div>);
  const actions = (
      <React.Fragment>
          <Button icon="pi pi-plus" 
            onClick={handleCreate}
            className="mr-2" />
      </React.Fragment>
  );
  let title = form.title || pageParameters.title;
  if (refFormAction && refSubmission) {
    let parameters = _get(refFormAction, "data.parameters", []);
    let submissionLabel, submissionRef
    for (let param of parameters) {
      if (param.name == 'labelField') {
        submissionLabel = _get(refSubmission, `data.${param.value}`);
      } else if (param.name == 'refField') {
        submissionRef = _get(refSubmission, `data.${param.value}`);
      }
    }
    if (submissionLabel || submissionRef) {
        title = `${title} - ${submissionLabel || submissionRef}`;
    }
  }  
  
  return (
    <>
      <Confirm
        modalOpen={props.modalOpen}
        message={
          <Translation>
            {(t) => t("Are you sure you wish to delete this submission?")}
          </Translation>
        }
        onNo={() => onNo()}
        onYes={() => onYes(submissionFormId, submissionId, submissions)}
      ></Confirm>
      <div>
        <Errors errors={errors} />
        <h4 className="text-truncate">{title}</h4>
      </div>
      {/* Reference form */}
      {formData && referenceFilters.ready && (
        <SubmissionTable
          submissions={submissionListData}
          form={formData}
          formActions={formActions}
          refFormAction={refFormAction}
          refSubmission={refSubmission}
          onAction={onAction}
          getSubmissions={fetchSubmissions}
          resolveSubmissionOperation={ resolveSubmissionOperation }
          //operations={operations}
          onCreate={handleCreate}
          navigate={navigate}
          pageConfig={page}
          params={{ ...pageParameters.filter, ...referenceFilters.query}}
        />)}
      {/* <Panel header={header} icons={actions}>
        {formData && (
            <SubmissionGrid 
              submissions={submissionListData}
              form={formData}
              onAction={onAction}
              getSubmissions={fetchSubmissions}
              operations={operations}
            />
          )}
      </Panel> */}
      {/* <div className="main-header">
        <Link className="back-icon" to={`${redirectUrl}form`}>
          <i className="fa fa-chevron-left fa-lg" />
        </Link>
        <span className="ml-3">
          <img src="/form.svg" width="30" height="30" alt="form" />
        </span>
        <h3 className="ml-3">
          <span className="task-head-details d-flex align-items-center">
            {" "}
            <i className="fa fa-wpforms" aria-hidden="true" />
            <span className="forms-text">
              <Translation>{(t) => t("Forms")}</Translation> /
            </span>
            {formData?.title}
          </span>
        </h3>
        {userRoles.includes(CLIENT) ? 
        <Link className="btn btn-primary form-btn btn-right" to={`/form/${formId}`}>
        <img src="/webfonts/fa_plus.svg" alt="back"/> New Submisssion
        </Link> : null} 
      </div> */}
    </>
  );
});

const mapStateToProps = (state) => {
  const form = selectRoot("form", state);
  // const applicationId = useSelector(
  //   (state) =>
  //     state[CUSTOM_SUBMISSION_ENABLE ? "customSubmission" : "submission"]?.submission?.data?.applicationId ||
  //     null
  // );
  const submissions = selectRoot("submissions", state);
  return {
    submissions: cloneDeep(submissions),
    isLoading: form.isActive || submissions.isActive,
    errors: [selectError("submissions", state), selectError("form", state)],
    userRoles: selectRoot("user", state).roles || [],
    userId: selectRoot("user", state).userDetail?.sub,
    modalOpen: selectRoot("formDelete", state).formSubMissionDelete.modalOpen,
    submissionFormId: selectRoot("formDelete", state).formSubMissionDelete
      .formId,
    submissionId: selectRoot("formDelete", state).formSubMissionDelete
      .submissionId,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchSubmissions: (page, formId, query = { 
      //"data.applicationId__regex": "/^\d+$/" 
    }) =>
      dispatch(
        getSubmissions("submissions", page, query, formId)
      ),
    onAction: (submission, action, redirectUrl) => {
      switch (action) {
        case "viewSubmission":
          dispatch(
            navigate(
              `${redirectUrl}form/${ownProps.formId}/submission/${submission._id}`
            )
          );
          break;
        case "edit":
          dispatch(
            navigate(
              `${redirectUrl}form/${ownProps.formId}/submission/${submission._id}/edit`
            )
          );
          break;
        case "delete":{
          const submissionDetails = {
            modalOpen: true,
            formId: ownProps.formId,
            submissionId: submission._id,
          };
          dispatch(setFormSubmissionDeleteStatus(submissionDetails));
          break;
        }
        default:
      }
    },
    onYes: (formId, submissionId, submissions) => {
      dispatch(
        deleteSubmission("submission", submissionId, formId, (err) => {
          if (!err) {
            const submissionDetails = {
              modalOpen: false,
              submissionId: "",
              formId: "",
            };
            dispatch(setFormSubmissionDeleteStatus(submissionDetails));
            dispatch(
              getSubmissions(
                "submissions",
                1,
                submissions.query,
                formId
              )
            );
          }
        })
      );
    },
    onNo: () => {
      const submissionDetails = {
        modalOpen: false,
        submissionId: "",
        formId: "",
      };
      dispatch(setFormSubmissionDeleteStatus(submissionDetails));
    },
  };
};

export default connect(mapStateToProps)(List);
