/* eslint-disable max-len */
// import bg from './bg.json';
// import cn from './cn.json';
// import de from './de.json';
import en from './en.json';
// import fr from './fr.json';
// import pt from './pt.json';
export default {
  en
};
