import { Route, Routes, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CalendarView from './calendar-view';
import List from './List'
import Create from './Create'
import Preview from './Preview'
import XlsxBinding from './XlsxBinding'
import Item from "./Item/index";
import { BASE_CONTEXT, FORM_ACTIONS, FORM_PAGE } from "src/config-global";
import { fetchSubmission, loadFormio } from "src/apiManager/services/FormServices";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import SpreadSheet from "./SpreadSheet";

const Form = React.memo(() => {
  const { formId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let [form, setForm] = useState();
  let [refForm, setRefForm] = useState();
  let [refFormAction, setRefFormAction] = useState();
  let [refFormActions, setRefFormActions] = useState();
  let [refSubmission, setRefSubmission] = useState();
  const allFormActions = useSelector((state) => state.meta.submissions[FORM_ACTIONS]);
  const mapFormActions = useSelector((state) => state.meta.mapSubmissions[FORM_ACTIONS]);
  const mapPages = useSelector((state) => state.meta.mapSubmissions[FORM_PAGE]);
  const actionId = searchParams.get("actionId");
  const pageId = searchParams.get("pageId");
  //const refFormId = searchParams.get("refForm");
  const refSubmissionId = searchParams.get("refSubmission");
  const [pageParameters, setPageParameters] = useState({});
  const [fieldMappers, setFieldMappers] = useState({});
  useEffect(() => { 
    if (formId) {
      loadFormio(formId, (err, form) => setForm(form));
    }
  }, [formId]);
  useEffect(() => {
    if (actionId) {
      const refAction = mapFormActions[actionId];
      const refFormId = _get(refAction, "data.sourceForm.formId");
      setRefFormAction(refAction);
      let refFormActions = allFormActions?.filter((action) => _get(action, "data.sourceForm.formId") == refFormId);
      setRefFormActions(refFormActions);
      loadFormio(refFormId, (err, form) => setRefForm(form));
      if (refSubmissionId) {
        fetchSubmission({
          formId: refFormId,
          submissionId: refSubmissionId,
          callback: (err, submission) => {
            setRefSubmission(submission);
          }
        });
      }
    }
  }, [refSubmissionId, actionId]);
   useEffect(() => {
      if (pageId) {
          const parameters = _get(mapPages[pageId], "data.parameters", [])
              .reduce((total, param) => {
              let scope = param.scope || 'view';
              total[scope] = total[scope] || {};
              total[scope][param.name] = param.value;
              return total;
          }, {});
        setPageParameters(parameters);
         const fieldMappers = _get(mapPages[pageId], "data.fieldMapper", [])
                .reduce((total, mapper) => {
                total[mapper.fieldName] = mapper;
                return total;
                }, {});
            setFieldMappers(fieldMappers);
      }
  }, [pageId])
  return (
    <div>
      <Routes>
        <Route path={`calendar`}
          element={<CalendarView refForm={refForm} refSubmission={refSubmission} refFormAction={refFormAction}
            parameters={pageParameters} mapper={ fieldMappers } />}>
        </Route>
        <Route path={`matrix`}
          element= {<List refForm={refForm} refSubmission={refSubmission} refFormAction={refFormAction}/>}>
        </Route>
        <Route path={`list`}
          element= {<List refForm={refForm} refSubmission={refSubmission} refFormAction={refFormAction}/>}>
        </Route>
        <Route path={`preview`}
          element={<Preview refForm={refForm} refSubmission={refSubmission} refFormAction={refFormAction}/>}>
        </Route>
        <Route path={`xlsxbinding`}
          element={<XlsxBinding form={form}/>}>
        </Route>
        <Route path={`sheet`}
          element= {<SpreadSheet refForm={refForm} refSubmission={refSubmission} refFormAction={refFormAction}/>}>
        </Route>
        <Route path={`timeline`}
          element= {<List refForm={refForm} refSubmission={refSubmission} refFormAction={refFormAction}/>}>
        </Route>
        <Route path={`create`}
          element={<Create refForm={refForm} refSubmission={refSubmission} refFormActions={refFormActions} refFormAction={refFormAction}/>}>
        </Route>
        <Route
          path={`:submissionId/*`}
          element={<Item refForm={refForm} refSubmission={refSubmission} refFormActions={refFormActions} refFormAction={refFormAction}/>}>
        </Route>
      </Routes>
    </div>
  );
});

export default Form;
