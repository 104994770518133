import { Navigate, useRoutes, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
// layouts
import MainLayout from 'src/layouts/main';
import ApplicationLayout from 'src/layouts/application';
// config
import { PATH_AFTER_LOGIN, BASE_CONTEXT } from 'src/config-global';
//
import { designerRoutes } from './designer';

import { mainRoutes, LaunchpadPage } from './main';
import { adminRoutes } from './admin';
import { calendarRoute } from './calendars';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';
import { useFormRoutes } from './forms';
import { formioRoutes } from './formio';
import { BASE_ROUTE, STAFF_DESIGNER } from 'src/constants';
// ----------------------------------------------------------------------

const getDefaultPage = function(userRoles: string[]): string {
  if (userRoles.includes(STAFF_DESIGNER)) {
    return `${BASE_CONTEXT}${BASE_ROUTE}designer/form`;
  }
  return `${BASE_CONTEXT}${BASE_ROUTE}launchpad`;
}
export default function Router() {
  const formRoutes = useFormRoutes();
  //let baseContext = BASE_CONTEXT || "";
  // const userRoles = useSelector((state: Record<string, any>) => state.user.roles);
  // const defaultPage = getDefaultPage(userRoles);
  // console.log(defaultPage);
  const routeObject = [
    // ----------------------------------------------------------------------
    {
      path: `${BASE_CONTEXT}${BASE_ROUTE}launchpad`,
      element: (
        <ApplicationLayout>
          <LaunchpadPage />
        </ApplicationLayout>
      ),
    },
    ...adminRoutes,
    ...designerRoutes,
    ...formRoutes,
    // Auth routes
    //...authRoutes,
    //...authDemoRoutes,

    // Dashboard routes
    //...dashboardRoutes,

    // Main routes
    ...mainRoutes,
    calendarRoute,
    // Components routes
    // ...componentsRoutes,
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={defaultPage} replace />,
    // },
    // To default page
    { path: '*', element: <Navigate to="/" replace /> }
  ];
  return useRoutes(routeObject);
}
