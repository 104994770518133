export const GROUPS = {
  applicationsAccess: [
    "/formsflow/formsflow-reviewer/access-allow-applications",
    "/formsflow/formsflow-client/access-allow-applications",
  ],
  viewSubmissionsAccess: [
    "/formsflow/formsflow-reviewer/access-allow-submissions",
  ],
};
export const USER_GROUPS = [
  {label: "Accountant", value: "formsflow/formsflow-client/staff_accountant"},
  {label: "Admin", value: "formsflow/formsflow-admin"},
  {label: "Client", value: "formsflow/formsflow-client"},
  {label: "Designer", value: "formsflow/formsflow-designer"},
  {label: "Design reviewer", value: "formsflow/formsflow-reviewer"},
  {label: "Staff", value: "formsflow/formsflow-admin/staff"},
];
export const DEFAULT_USER_GROUP = "formsflow/formsflow-reviewer";