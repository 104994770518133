import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { KeycloakService, StorageService } from 'src/services';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { AuthStateType } from '../../types';
import { STORATE_AUTH_TOKEN } from './utils';
// import { STORAGE_ACCESS_TOKEN } from './utils';
import {
  setUserAuth,
  setUserRole,
  setUserToken,
  setUserDetails,
} from "src/actions/bpmActions";

import { setTenantID } from "src/actions/tenantActions";
import {
  BASE_ROUTE,
  DRAFT_ENABLED,
  MULTITENANCY_ENABLED,
  KEYCLOAK_AUTH_URL,
  Keycloak_Client,
  KEYCLOAK_REALM,
  STAFF_DESIGNER,
} from "src/constants";
import {
  BPM_API_URL_WITH_VERSION,
  CUSTOM_SUBMISSION_URL,
  WEB_BASE_URL,
  WEB_BASE_CUSTOM_URL
} from "src/apiManager/endpoints/config";

import { BASE_CONTEXT, AppConfig } from 'src/config-global';

// import { reducer, 
//   setTenantFromId, 
//   setKcInstance, 
//   initUserAction, loginAction, logoutAction, registerAction } from "./auth-reducer"
import { getFormioRoleIds } from 'src/apiManager/services/userservices';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
let kcInstance : KeycloakService | undefined;
export const kcServiceInstance = (tenantId: string | null | undefined = null) => {
  return KeycloakService.getInstance(
    KEYCLOAK_AUTH_URL,
    KEYCLOAK_REALM,
    tenantId ? `${tenantId}-${Keycloak_Client}` : Keycloak_Client
  );
};

const setApiBaseUrlToLocalStorage = () => {
  localStorage.setItem("bpmApiUrl", BPM_API_URL_WITH_VERSION);
  localStorage.setItem("formioApiUrl", AppConfig.projectUrl);
  localStorage.setItem("formsflow.ai.url", window.location.origin);
  localStorage.setItem("formsflow.ai.api.url", WEB_BASE_URL);
  localStorage.setItem("customApiUrl", WEB_BASE_CUSTOM_URL);
  localStorage.setItem("customSubmissionUrl", CUSTOM_SUBMISSION_URL);
};

export function AuthProvider({ children }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: Record<string, any>) => state.user.isAuthenticated
  );
  //const apiCallError = useSelector((state: Record<string, any>)=> state.apiCallError);
  //const [state, authDispatch] = useReducer(reducer, initialState);
  const { tenantId } = useParams();
  const initialize = useCallback(async () => {
    try {
      const accessToken = StorageService.get(StorageService.User.AUTH_TOKEN);
      if (!kcInstance) {
        kcInstance = tenantId ? kcServiceInstance(tenantId) : kcServiceInstance();
      } 
      //Callback when authenticated
      kcInstance.initKeycloak(() => {
        let accessToken = kcInstance?.getToken();
        setSession(accessToken);
        setUserToken(accessToken)(dispatch);
        let userData = kcInstance?.getUserData();
        setUserDetails(userData)(dispatch);
        setUserRole(userData.role)(dispatch);
        //Set Cammunda/Formio Base URL
        setApiBaseUrlToLocalStorage();
        // get formio roles and X-Jwt-Token for formio access
        getFormioRoleIds((err: any) => {
          setUserAuth(kcInstance?.isAuthenticated())(dispatch);
          if (err) {
            console.log(err);
            //logout();
          }
        })(dispatch);
        // let defaultUrl = getDefaultPage(userData);
        // if (defaultUrl) {
        //   navigate(defaultUrl);
        // }
        // authDispatch(initUserAction(userData));
      });
    
      
      // if (accessToken && isValidToken(accessToken)) {
      //   setSession(accessToken);

      //   const res = await axios.get(endpoints.auth.me);

      //   const { user } = res.data;

      //   authDispatch(initUserAction(user));
      // } else {
      //   authDispatch(initUserAction(null));
      // }
    } catch (error) {
      console.error(error);
      setUserAuth(false)(dispatch); 
      setUserDetails(null)(dispatch); 
      //authDispatch(initUserAction(null));
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (tenantId) {
      let currentTenant = sessionStorage.getItem("tenantKey");
      if (currentTenant && currentTenant !== tenantId) {
        sessionStorage.clear();
        localStorage.clear();
      }
      sessionStorage.setItem("tenantKey", tenantId);
      setTenantID(tenantId)(dispatch);
    }
  }, [tenantId, dispatch]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const data = {
      email,
      password,
    };

    const res = await axios.post(endpoints.auth.login, data);

    const { accessToken, user } = res.data;

    setSession(accessToken);

    //authDispatch(loginAction(user));
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const res = await axios.post(endpoints.auth.register, data);

      const { accessToken, user } = res.data;

      sessionStorage.setItem(STORATE_AUTH_TOKEN, accessToken);

      //authDispatch(registerAction(user));
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(undefined);
    if (kcInstance) {
      kcInstance.userLogout();
    }
    // authDispatch(logoutAction());
    // dispatch({
    //   type: Types.LOGOUT,
    // });
  }, []);

  // ----------------------------------------------------------------------

  const userDetail = useSelector((state: Record<string, any>) => state.userDetail)
  const memoizedValue = useMemo(
    () => ({
      user: userDetail,
      method: 'jwt',
      loading: false,
      authenticated: isAuthenticated,
      unauthenticated: !isAuthenticated,
      login,
      register,
      logout,
    }),
    [userDetail, isAuthenticated]
  );
  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
