import Handsontable from "handsontable";
import Core from "handsontable/core";
import { CellProperties } from "handsontable/settings";

export const xlsxStylesRenderer = (hotInstance: Core,
    TD: HTMLTableCellElement,
    row: number,
    column: number,
    prop: string | number,
    value: any,
    cellProperties: CellProperties) => {
  Handsontable.renderers.TextRenderer(hotInstance, TD, row, column, prop, value, cellProperties);
  let font: any = cellProperties.font || {};
  if (font.bold) {
    TD.style.fontWeight = "bold";
  }
  if (font.italic) {
    TD.style.fontStyle = "italic";
  }
  if (font.height) {
    TD.style.fontSize = `${font.height/16}px`;
  }
};

export const imageRenderer = (hotInstance: Core,
  TD: HTMLTableCellElement,
  row: number,
  column: number,
  prop: string | number,
  value: any,
  cellProperties: CellProperties) => { 
  Handsontable.renderers.BaseRenderer.apply(this, [hotInstance, TD, row, column, prop, value, cellProperties]);
    const img = document.createElement('img');
    img.src = `data:image/png;base64,${cellProperties.base64}`;
    // if (Array.isArray(cellProperties.size)) {
    //   img.width =  cellProperties.size[0];
    //   img.height = cellProperties.size[1];
    // };
    // img.addEventListener('mousedown', event => {
    //   event.preventDefault();
    // });
  TD.innerHTML = "";
  TD.appendChild(img);
  return TD;
};

export const qrcodeRenderer = (hotInstance: Core,
  TD: HTMLTableCellElement,
  row: number,
  column: number,
  prop: string | number,
  value: any,
  cellProperties: CellProperties) => { 
  Handsontable.renderers.BaseRenderer.apply(this, [hotInstance, TD, row, column, prop, value, cellProperties]);
  const cellValue = cellProperties.value;
  TD.innerHTML = "";
  if (cellValue) {
    const qrcode = document.createElement('div');
    const qr = new (window as any).QRCode(qrcode, {
      width: 64,
      height: 64,
    });
    qr.makeCode(cellValue.trim());
    TD.appendChild(qrcode);
  }
  return TD;
};

  export const handsontablePlugin = {
    registerRenderers: () => { 
      Handsontable.renderers.registerRenderer('xlsxStylesRenderer', xlsxStylesRenderer);
      Handsontable.renderers.registerRenderer('imageRenderer', imageRenderer);
      Handsontable.renderers.registerRenderer('qrcodeRenderer', qrcodeRenderer)
    }
}