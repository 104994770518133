
import {
  deleteSubmission as deleteFormioSubmission,
} from "@formio/react";
import { RequestService, StorageService, UserService } from "src/services";
import API from "../endpoints";
import { setCustomSubmission } from "src/actions/checkListActions";
import { replaceUrl, parseRange } from "src/helper/helper";
import {Formio as Formiojs} from 'formiojs';
import { createAuthHeader } from "src/services/helperServices";

import { CUSTOM_SUBMISSION_ENABLE, CUSTOM_SUBMISSION_URL, STAFF_DESIGNER } from "src/constants";
import { ISubmission } from "src/types/form";

export type FormSubmissionParams = { 
  formId: string;
  submissionId: string | null;
  callback?: Function;
  params?: Record<string, any>;
} 

export const formCreate = (formData: object) => {
  return RequestService.httpPOSTRequest(API.FORM_DESIGN, formData, null);
};

export const formUpdate = (formId: string,formData: object) => {
  return RequestService.httpPUTRequest(`${API.FORM_DESIGN}/${formId}`, formData, null);
};

export const loadFormio = async (formId: string, done: Function = () => { }) => {
  const path = `${Formiojs.getProjectUrl()}/form/${formId}`;
  const formio = new Formiojs(path);
  let result = await formio.loadForm().catch((err: any) => {
    done(err);
  });
  done(null, result);
  return result;   
}

export const getFormHistory = (formId: string) => {
  return RequestService.httpGETRequest(`${API.FORM_HISTORY}/${formId}`);
};

export const queryFormio = async (options : Record<string, any>,
  callback: Function
) => {
  let params = [];
  const { pageNo,
    limit,
    sortBy,
    sortOrder,
    formType = 'form,resource',
    formName } = options;
  let url = API.SMARTFORM_FORMIO;
  let authHeaders = createAuthHeader();
  let hasFormType = false;
  for (let key in options) {
    if (key == 'formType') {
      hasFormType = true;
    }
    params.push(`${key}=${options[key]}`)
  }
  if (!hasFormType) {
    params.push('formType=form,resource');
  }
  if (params.length > 0) {
    url = `${url}?${params.join('&')}`;
  };
  let response = await RequestService.httpGETRequest(url, {}, "", false, authHeaders);
  if (response.data) {
    callback(null, response.data);
  }
  return response.data?.forms;
}

export const fetchFormList = async (options: Record<string, any>,
  callback: Function = (err?: any, data?: any) => { }
) => {
  let params = [];
  const {
    pageNo = 1,
    limit = 100,
    sortBy,
    sortOrder,
    formType = 'form,resource',
    formName } = options;
  let url = API.SMARTFORM_FORMSFLOW;
  let authHeaders = createAuthHeader();
  if (pageNo != null && pageNo != undefined) {
    params.push(`pageNo=${pageNo}`)
  }
  if (limit != null && limit != undefined) {
    params.push(`limit=${limit}`)
  }
  if (sortBy) {
    params.push(`sortBy=${sortBy}`)
  }
  if (sortOrder) {
    params.push(`sortOrder=${sortOrder}`)
  }
  if (formType) {
    params.push(`formType=${formType}`)
  }
  if (formName) {
    params.push(`formName=${formName}`)
  }
  if(params.length > 0) {
    url = `${url}?${params.join('&')}`;
  };
  let response = await RequestService.httpGETRequest(url, {}, "", false, authHeaders);
  if (response.data) {
    callback(null, response.data);
  }
  return response.data?.forms;
  // let userDetail = StorageService.get(StorageService.User.USER_DETAILS);
  // userDetail = userDetail ? JSON.parse(userDetail) : { role: [] };
  // if (userDetail.role.includes(STAFF_DESIGNER)) {
  //   for (let key in options) {
  //     if (key == 'formType') continue;
  //     params.push(`${key}=${options[key]}`);
  //   }
  //   let promises = ['form', 'resource'].map((type) => {
  //     let typeUrl = `${url}?formType=${type}`;
  //     if(params.length > 0) {
  //       typeUrl = `${typeUrl}&${params.join('&')}`;
  //     };
  //     return RequestService.httpGETRequest(typeUrl, {}, "", false, authHeaders);
  //   })
  //   let [formRes, resourceRes] = await Promise.all(promises).catch((err) => callback(err)); 
  //   let forms = formRes.data?.forms || [];
  //   forms = forms.concat(resourceRes.data?.forms);
  //   console.log(formRes, resourceRes);
  //   callback(null, forms);
  // } else {
  //   for (let key in options) {
  //     params.push(`${key}=${options[key]}`)
  //   }
  //   if(params.length > 0) {
  //     url = `${url}?${params.join('&')}`;
  //   };
  //   let response = await RequestService.httpGETRequest(url, {}, "", false, authHeaders);
  //   if (response.data) {
  //     callback(null, response.data);
  //   }
  //   return response.data?.forms;
  // }
};


export const loadForm = async (formName: string) => {
  let url = `${API.SMARTFORM_FORMSFLOW}?formType=resource&formName=${encodeURIComponent(formName)}`;
  let response = await RequestService.httpGETRequest(url, {}, StorageService.get(StorageService.User.AUTH_TOKEN));
  return Array.isArray(response.data?.forms) && response.data?.forms.length > 0 ? response.data?.forms[0] : null
};

export const postCustomSubmission = async (data: any, formId: string, isPublic: boolean, ...rest: any[]) => {
  const done = rest.length ? rest[0] : () => {};
  const url = isPublic ? API.PUBLIC_CUSTOM_SUBMISSION : API.CUSTOM_SUBMISSION;
  const submissionUrl = replaceUrl(url, "<form_id>", formId);
  let authHeaders = createAuthHeader();
  let res = await RequestService.httpPOSTRequest(`${submissionUrl}`, data, "", false, authHeaders).catch((err: any) => {
    done(err, null);
  });
  done(res.data ? null : "Error Posting data", res.data);
  return res;
};

export const uploadSubmissions = (data: any, formId: string, isPublic: boolean, ...rest: any[]) => {
  const done = rest.length ? rest[0] : () => {};
  const url = isPublic ? API.PUBLIC_CUSTOM_SUBMISSION : API.CUSTOM_SUBMISSION;
  const submissionUrl = replaceUrl(url, "<form_id>", formId);
  let authHeaders = createAuthHeader();
  RequestService.httpPOSTRequest(`${submissionUrl}/upload`, data, "", false, authHeaders)
    .then((res: any) => {
      if (res.data) {
        done(null, res.data);
      } else {
        done("Error Posting data", null);
      }
    })
    .catch((err: any) => {
      done(err, null);
    });
};

export const saveCustomSubmission = (data: any, formId: string, ...rest: any[]) => {
  const done = rest.length ? rest[0] : () => { };
  const customAction = rest.length > 1 ? rest[1] : null;
  const submissionUrl = replaceUrl(API.CUSTOM_SUBMISSION, "<form_id>", formId);
  let authHeaders = createAuthHeader();
  const id = data._id
  if (id) {
    const url = customAction ? `${submissionUrl}/${id}/${customAction}` : `${submissionUrl}/${id}`;
    RequestService.httpPUTRequest(url, data, "", false, authHeaders)
      .then((res: any) => {
        if (res.data) {
          done(null, res.data);
        } else {
          done("Error updating data", null);
        }
      })
      .catch((err: any) => {
        done(err, null);
      });
  } else {
    const url = customAction ? `${submissionUrl}/${customAction}` : submissionUrl;
    RequestService.httpPOSTRequest(url, data, "", false, authHeaders)
      .then((res: any) => {
        if (res.data) {
          done(null, res.data);
        } else {
          done("Error updating data", null);
        }
      })
      .catch((err: any) => {
        done(err, null);
      });
  }
};

export const getSubmissions = async (formId: string, params: Record<string, any> = {},
  callback: Function = () => { }) => {
  // prepare params
  let requestParams: Record<string, any> = {};
  // Ten is the default so if set to 10, don't send.
  requestParams.limit = params?.limit || 10;
  let page = params?.page || 1;
  if (page != 1) {
    requestParams.skip = (page - 1) * requestParams.limit;
  }
  if (params.select) {
    requestParams.select = params.select;
  }
  if (params.sort) {
    requestParams.sort = params.sort;
  }
  if (params.query) {
    requestParams = {...requestParams, ...params.query};
  }
  if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
    const arrParams = [];
    let submissionUrl = replaceUrl(API.CUSTOM_SUBMISSION, "<form_id>", formId);
    //Todo: handle smartFilter
    if (params.query?.smartFilter) {
      submissionUrl += "/filter";
    }
    let authHeaders = createAuthHeader();
    for(var param in requestParams) {
      const value = encodeURIComponent(requestParams[param]);
      arrParams.push(`${param}=${value}`)
    }
    if (arrParams.length > 0) {
      let query = arrParams.join('&');
      submissionUrl = `${submissionUrl}?${query}`;
    }
    let res = await RequestService.httpGETRequest(submissionUrl, {}, "", false, authHeaders)
      .catch((err) => callback(err, null));
    let range = parseRange(res?.headers['content-range']);
    let data = res?.data || [];
    callback(null, data, range);
    return data;
  } else {
    const formio = new Formiojs(`${Formiojs.getProjectUrl()}/form/${formId}/submission`);
    let res = await formio.loadSubmissions({ params: requestParams })
      .catch((error: any) => {
        callback(error);
      });
    callback(null, res);
    return res;
  }
};
export const getCustomSubmissions = getSubmissions;

export const fetchSubmission = async (params: FormSubmissionParams) => {
  if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
    const submissionUrl = replaceUrl(API.CUSTOM_SUBMISSION, "<form_id>", params.formId);
    let authHeaders = createAuthHeader();
    const done = params.callback || (() => { });
    let res = await RequestService.httpGETRequest(`${submissionUrl}/${params.submissionId}`, {}, "", false, authHeaders).catch((err) => done(err));
    done(null, res.data);
    return res;
  } else {
    const done = params.callback || (() => { });
    return getSubmission(params.submissionId!, params.formId, {}, done);
  }
}
export const getSubmission = async (submissionId: string, formId: string, requestParams: Record<string, any>, done: Function) => {
  const formio = new Formiojs(`${Formiojs.getProjectUrl()}/form/${formId}/submission/${submissionId}`);
  return formio.loadSubmission({params: requestParams})
    .then((result: any) => {
      done(null, result);
      return result;
    })
    .catch((error: any) => {
      done(error);
    });
};

export const getCustomSubmission = (submissionId: string, formId: string, ...rest: any[]) => {
  const done = rest.length ? rest[0] : () => {};
  const submissionUrl = replaceUrl(API.CUSTOM_SUBMISSION, "<form_id>", formId);
  let authHeaders = createAuthHeader();
  return (dispatch: Function) => {
    RequestService.httpGETRequest(`${submissionUrl}/${submissionId}`, {}, "", false, authHeaders)
      .then((res) => {
        if (res.data) {
          dispatch(setCustomSubmission(res.data));
        } else {
          dispatch(setCustomSubmission({}));
        }
      })
      .catch((err) => {
        done(err, null);
      });
  };
};

export const fetchCustomSubmission = async (submissionId: string, formId: string, ...rest: any[]) => {
  const done = rest.length ? rest[0] : () => {};
  const submissionUrl = replaceUrl(API.CUSTOM_SUBMISSION, "<form_id>", formId);
  let authHeaders = createAuthHeader();
  return  RequestService.httpGETRequest(`${submissionUrl}/${submissionId}`, {}, "", false, authHeaders)
    .then((res) => {
      if (res.data) {
        done(null, res.data);
      }
    })
    .catch((err) => {
      done(err, null);
    });
};
export const deleteSubmission = (formId: string, submissionId: string, ...rest: any[]) => { 
  const callback = rest.length ? rest[0] : () => {};
  if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
    const submissionUrl = replaceUrl(API.CUSTOM_SUBMISSION, "<form_id>", formId);
    let authHeaders = createAuthHeader();
    return (dispatch: Function) => {
      RequestService.httpDELETERequest(`${submissionUrl}/${submissionId}`, {}, "", false, authHeaders)
        .then((res: any) => {
          callback(null, res.data);
          dispatch(setCustomSubmission({}));
        })
        .catch((err: any) => {
          callback(err, null);
        });
    };
  } else {
    return (dispatch: Function) => {
      dispatch(deleteFormioSubmission("submission", submissionId, formId, callback))
    }
  }
}
export const deleteCustomSubmission = (submissionId: string, formId: string, ...rest: any[]) => {
  const done = rest.length ? rest[0] : () => {};
  const submissionUrl = replaceUrl(API.CUSTOM_SUBMISSION, "<form_id>", formId);
  let authHeaders = createAuthHeader();
  return (dispatch: Function) => {
    RequestService.httpDELETERequest(`${submissionUrl}/${submissionId}`, {}, "", false, authHeaders)
      .then((res: any) => {
        done(null, res.data);
        dispatch(setCustomSubmission({}));
      })
      .catch((err: any) => {
        done(err, null);
      });
  };
};

export const deleteSubmissions = async (formId: string, submissions: string[], ...rest: any[]) => {
  const done = rest.length ? rest[0] : () => {};
  const submissionUrl = replaceUrl(API.CUSTOM_SUBMISSION, "<form_id>", formId);
  let authHeaders = createAuthHeader();
  let res = await RequestService.httpDELETERequest(`${submissionUrl}/delete`, submissions, "", false, authHeaders).catch((err: any) => {
    done(err, null);
  });
  done(null, res.data);
  return res;
};

export const fetchExcelTemplate = async (params: FormSubmissionParams) => {
  if (CUSTOM_SUBMISSION_URL && CUSTOM_SUBMISSION_ENABLE) {
    const submissionUrl = replaceUrl(API.CUSTOM_SUBMISSION, "<form_id>", params.formId);
    let authHeaders = createAuthHeader();
    const done = params.callback || (() => { });
    let res = await RequestService.httpGETRequest(`${submissionUrl}/${params.submissionId}/xlsxTemplate`, {}, "", false, authHeaders).catch((err) => done(err));
    done(null, res.data);
    return res;
  } else {
    const done = params.callback || (() => { });
    return getSubmission(params.submissionId!, params.formId, {}, done);
  }
}